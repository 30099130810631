
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function ResetPssword() {
    const [container, setContainer] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [samePwd, setSamePwd] = useState(true);
    const [validated, setValidated] = useState(false);
    const token = new URLSearchParams(useLocation().search).get('token');
    const reset = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false || password !== password2) {
            e.stopPropagation();
            setSamePwd(password === password2 && password2.length > 0);
        } else {
            await axios.post(`${process.env.REACT_APP_SERVER}/users/resetPassword/${token}`, { password: password }).then((res) => {
                setContainer(1)

            }).catch((err) => {
                setContainer(2)

            });
            // Handle password reset logic here
            console.log('Password reset successfully');
        }

        setValidated(true);
    };

    const { t } = useTranslation();
    return (
        <div>
            {!container && (
                <form noValidate className={`form-needs-validation ${validated ? 'was-validated' : ''}`} onSubmit={reset}>
                    <div className='row mt-4'>
                        <div className='col-12 col-md-4 mx-auto align-self-center'>
                            <h2 className='text-center mb-4'>{t("resetPassword")}</h2>
                            <label htmlFor="newPassword" className="form-label">{t('newPassword')}</label>

                            <div className='mb-4 input-group'>
                                <div className="input-group-text">
                                    {!visible1 && <svg onClick={(e) => {
                                        e.preventDefault()
                                        setVisible1(true)
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="black" fill-opacity="0.88" />
                                    </svg>}
                                    {visible1 && <svg onClick={(e) => {
                                        e.preventDefault()
                                        setVisible1(false)
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.47001 15.2799C9.28001 15.2799 9.09001 15.2099 8.94001 15.0599C8.12001 14.2399 7.67001 13.1499 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C13.15 7.66992 14.24 8.11992 15.06 8.93992C15.2 9.07992 15.28 9.26992 15.28 9.46992C15.28 9.66992 15.2 9.85992 15.06 9.99992L10 15.0599C9.85001 15.2099 9.66001 15.2799 9.47001 15.2799ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 12.4999 9.30001 12.9799 9.54001 13.3999L13.4 9.53992C12.98 9.29992 12.5 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                        <path d="M5.6 18.51C5.43 18.51 5.25 18.45 5.11 18.33C4.04 17.42 3.08 16.3 2.26 15C1.2 13.35 1.2 10.66 2.26 8.99998C4.7 5.17998 8.25 2.97998 12 2.97998C14.2 2.97998 16.37 3.73998 18.27 5.16998C18.6 5.41998 18.67 5.88998 18.42 6.21998C18.17 6.54998 17.7 6.61998 17.37 6.36998C15.73 5.12998 13.87 4.47998 12 4.47998C8.77 4.47998 5.68 6.41998 3.52 9.80998C2.77 10.98 2.77 13.02 3.52 14.19C4.27 15.36 5.13 16.37 6.08 17.19C6.39 17.46 6.43 17.93 6.16 18.25C6.02 18.42 5.81 18.51 5.6 18.51Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12 21.02C10.67 21.02 9.37 20.75 8.12 20.22C7.74 20.06 7.56 19.62 7.72 19.24C7.88 18.86 8.32 18.68 8.7 18.84C9.76 19.29 10.87 19.52 11.99 19.52C15.22 19.52 18.31 17.58 20.47 14.19C21.22 13.02 21.22 10.98 20.47 9.81C20.16 9.32 19.82 8.85 19.46 8.41C19.2 8.09 19.25 7.62 19.57 7.35C19.89 7.09 20.36 7.13 20.63 7.46C21.02 7.94 21.4 8.46 21.74 9C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12.69 16.2699C12.34 16.2699 12.02 16.0199 11.95 15.6599C11.87 15.2499 12.14 14.8599 12.55 14.7899C13.65 14.5899 14.57 13.6699 14.77 12.5699C14.85 12.1599 15.24 11.8999 15.65 11.9699C16.06 12.0499 16.33 12.4399 16.25 12.8499C15.93 14.5799 14.55 15.9499 12.83 16.2699C12.78 16.2599 12.74 16.2699 12.69 16.2699Z" fill="black" fill-opacity="0.88" />
                                        <path d="M2 22.7502C1.81 22.7502 1.62 22.6802 1.47 22.5302C1.18 22.2402 1.18 21.7602 1.47 21.4702L8.94 14.0002C9.23 13.7102 9.71 13.7102 10 14.0002C10.29 14.2902 10.29 14.7702 10 15.0602L2.53 22.5302C2.38 22.6802 2.19 22.7502 2 22.7502Z" fill="black" fill-opacity="0.88" />
                                        <path d="M14.53 10.2199C14.34 10.2199 14.15 10.1499 14 9.99994C13.71 9.70994 13.71 9.22994 14 8.93994L21.47 1.46994C21.76 1.17994 22.24 1.17994 22.53 1.46994C22.82 1.75994 22.82 2.23994 22.53 2.52994L15.06 9.99994C14.91 10.1499 14.72 10.2199 14.53 10.2199Z" fill="black" fill-opacity="0.88" />
                                    </svg>}

                                </div>
                                <input
                                    type={visible1 ? "text" : "password"} className="form-control rounded-end"
                                    id="newPassword"
                                    required
                                    onChange={(e) => {
                                        setSamePwd(e.target.value === password2 && password2.length > 0);
                                        setPassword(e.target.value)
                                    }}
                                />
                                <div className='invalid-feedback'>
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <label htmlFor="confirmPassword" className="form-label">{t('confirmPasswordInputPlaceholder')}</label>

                            <div className='input-group'>
                                <div className='input-group-text'>
                                    {!visible2 && <svg onClick={(e) => {
                                        e.preventDefault()
                                        setVisible2(true)
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="black" fill-opacity="0.88" />
                                    </svg>}
                                    {visible2 && <svg onClick={(e) => {
                                        e.preventDefault()
                                        setVisible2(false)
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.47001 15.2799C9.28001 15.2799 9.09001 15.2099 8.94001 15.0599C8.12001 14.2399 7.67001 13.1499 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C13.15 7.66992 14.24 8.11992 15.06 8.93992C15.2 9.07992 15.28 9.26992 15.28 9.46992C15.28 9.66992 15.2 9.85992 15.06 9.99992L10 15.0599C9.85001 15.2099 9.66001 15.2799 9.47001 15.2799ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 12.4999 9.30001 12.9799 9.54001 13.3999L13.4 9.53992C12.98 9.29992 12.5 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                        <path d="M5.6 18.51C5.43 18.51 5.25 18.45 5.11 18.33C4.04 17.42 3.08 16.3 2.26 15C1.2 13.35 1.2 10.66 2.26 8.99998C4.7 5.17998 8.25 2.97998 12 2.97998C14.2 2.97998 16.37 3.73998 18.27 5.16998C18.6 5.41998 18.67 5.88998 18.42 6.21998C18.17 6.54998 17.7 6.61998 17.37 6.36998C15.73 5.12998 13.87 4.47998 12 4.47998C8.77 4.47998 5.68 6.41998 3.52 9.80998C2.77 10.98 2.77 13.02 3.52 14.19C4.27 15.36 5.13 16.37 6.08 17.19C6.39 17.46 6.43 17.93 6.16 18.25C6.02 18.42 5.81 18.51 5.6 18.51Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12 21.02C10.67 21.02 9.37 20.75 8.12 20.22C7.74 20.06 7.56 19.62 7.72 19.24C7.88 18.86 8.32 18.68 8.7 18.84C9.76 19.29 10.87 19.52 11.99 19.52C15.22 19.52 18.31 17.58 20.47 14.19C21.22 13.02 21.22 10.98 20.47 9.81C20.16 9.32 19.82 8.85 19.46 8.41C19.2 8.09 19.25 7.62 19.57 7.35C19.89 7.09 20.36 7.13 20.63 7.46C21.02 7.94 21.4 8.46 21.74 9C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02Z" fill="black" fill-opacity="0.88" />
                                        <path d="M12.69 16.2699C12.34 16.2699 12.02 16.0199 11.95 15.6599C11.87 15.2499 12.14 14.8599 12.55 14.7899C13.65 14.5899 14.57 13.6699 14.77 12.5699C14.85 12.1599 15.24 11.8999 15.65 11.9699C16.06 12.0499 16.33 12.4399 16.25 12.8499C15.93 14.5799 14.55 15.9499 12.83 16.2699C12.78 16.2599 12.74 16.2699 12.69 16.2699Z" fill="black" fill-opacity="0.88" />
                                        <path d="M2 22.7502C1.81 22.7502 1.62 22.6802 1.47 22.5302C1.18 22.2402 1.18 21.7602 1.47 21.4702L8.94 14.0002C9.23 13.7102 9.71 13.7102 10 14.0002C10.29 14.2902 10.29 14.7702 10 15.0602L2.53 22.5302C2.38 22.6802 2.19 22.7502 2 22.7502Z" fill="black" fill-opacity="0.88" />
                                        <path d="M14.53 10.2199C14.34 10.2199 14.15 10.1499 14 9.99994C13.71 9.70994 13.71 9.22994 14 8.93994L21.47 1.46994C21.76 1.17994 22.24 1.17994 22.53 1.46994C22.82 1.75994 22.82 2.23994 22.53 2.52994L15.06 9.99994C14.91 10.1499 14.72 10.2199 14.53 10.2199Z" fill="black" fill-opacity="0.88" />
                                    </svg>}

                                </div>
                                <input
                                    type={visible2 ? "text" : "password"} className={`form-control ${!samePwd ? 'is-invalid' : ''} rounded-end`}
                                    id="confirmPassword"
                                    required
                                    onChange={(e) => {
                                        setSamePwd(password === e.target.value && e.target.value.length > 0);
                                        setPassword2(e.target.value)
                                    }}
                                />
                                <div className='invalid-feedback'>
                                    {!password2 ? 'Ce champ est obligatoire' : 'Les mots de passe ne correspondent pas'}
                                </div>
                            </div>
                            <div>
                                <button className="btn btn-primary w-auto mt-4" type='submit'>{t("reset")}</button>
                            </div>
                        </div>
                    </div>
                </form>)
            }
            {
                container === 1 &&
                (<div className="container" >
                    <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                        <div className="row">
                            <div className="col-6 mx-auto my-4">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className="path circle" fill="none" stroke="#198754" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline className="path check" fill="none" stroke="#198754" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                </svg>
                            </div>
                        </div>

                        <div className="card-body text-center">
                            <h4 className="text-success mt-3">{t('resetPasswordSucess')}</h4>
                            <p className="mt-3">
                                {t('resetPasswordSucessText')}
                            </p>
                        </div>
                    </div>
                </div>)
            }
            {
                container === 2 && (
                    <div className="container" >
                        <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#db3646" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                                    </svg>
                                </div>
                            </div>

                            <div className="card-body text-center">
                                <h4 className="text-danger mt-3">{t('resetPasswordError')}</h4>
                                <p className="mt-3">
                                    {t('resetPasswordErrorText')}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default ResetPssword;