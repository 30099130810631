
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useToast } from './toastContext';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const { addToast } = useToast();
    const [notifications, setNotifications] = useState([]);
    const notificationIds = useRef(new Set());

    const updateNotification = async (notification) => {
        try {
            if (!notification.read) {
                await axios.put(`${process.env.REACT_APP_SERVER}/notifications/${notification._id}`)
                    .then((res) => {
                        console.log(res);
                        notification = res.data;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                const updatedNotifications = notifications.map(notif => notif._id === notification._id ? notification : notif);
                setNotifications(updatedNotifications);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchNotifications = () => {
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/notifications?token=${window.localStorage.getItem('_auth')}`);
        eventSource.onmessage = (event) => {
            const notification = JSON.parse(event.data);
            if (!notificationIds.current.has(notification._id)) {
                setNotifications(prevNotifications => [...prevNotifications, notification]);
                notificationIds.current.add(notification._id);
                if (!notification.read) {
                    addToast(notification.title, notification.message, "primary");
                }
            }
        };
        eventSource.onerror = (error) => {
            console.error(error);
        };
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <NotificationContext.Provider value={{ updateNotification, fetchNotifications, setNotifications, notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationContext);
};