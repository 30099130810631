import React, { useEffect, useRef, useState } from 'react';
import classes from "./navbar.module.css";
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Offcanvas } from 'react-bootstrap';
import Image from '../../assets/logo.png'
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../notificationContext';
function MyNavbar({ handleLanguageChange, isAuthenticated, handleSignOut, role }) {
    const { notifications, updateNotification } = useNotifications()
    const navbarRef = useRef()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showNotificationState, setShowNotificationState] = useState(false);
    const handleNotificationsClick = async (notification) => {
        await updateNotification(notification);
        if (notification.title === 'Offre Created') {
            navigate('/dashboard/OffresRecus');
        } else if (notification.title === 'Offre Updated') {
            navigate('/dashboard/mesLivraisons');
        }
    };
    useEffect(() => {
        if (navbarRef.current) {
            document.documentElement.style.setProperty('--navbar-height', `${navbarRef.current.offsetHeight}px`);
        }

        const handleResize = () => {
            if (navbarRef.current) {
                document.documentElement.style.setProperty('--navbar-height', `${navbarRef.current.offsetHeight}px`);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        const hasUnreadNotification = notifications.some(notification => !notification.read);
        setShowNotificationState(hasUnreadNotification);
    }, [notifications]);
    return (
        <Navbar expand="lg" ref={navbarRef} className={`m-0 p-0 py-2 px-lg-4 w-100  ${classes.nav}`} >
            <Navbar.Brand onClick={() => navigate('/')} className='px-lg-4'>
                <img src={`${Image}`} style={{ width: "150px" }} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Offcanvas id="responsive-navbar-nav" className={`${classes.offcanvas} justify-content-start`} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title >
                        {t("menu")}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Nav className="fw-semibold h-100 align-items-start" >
                    {(role === "particulier" || role === "transporteur") &&
                        <Nav.Link className=' py-2 py-lg-0 mx-4 my-2 m-lg-0 ms-lg-auto my-lg-auto' onClick={() => { navigate("/voirColis") }} >
                            <div className="d-flex align-items-center" >
                                <svg className='mr-2'
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                        stroke="#1f4063"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22 22L20 20"
                                        stroke="#1f4063"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p className='my-auto ps-2'>{t("seePackages")}</p>
                            </div>
                        </Nav.Link>}
                    {role === "particulier" &&
                        <Nav.Link className='py-2 py-lg-0 mx-4 my-2 m-lg-0 my-lg-auto' onClick={() => { navigate("/ajouterColis") }}>
                            <div className="d-flex align-items-center" >
                                <svg
                                    className='mr-2'
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.9998 13.2999C11.8698 13.2999 11.7398 13.2699 11.6198 13.1999L2.78985 8.0899C2.42985 7.8799 2.30983 7.41987 2.51983 7.05987C2.72983 6.69987 3.17983 6.57985 3.54983 6.78985L11.9998 11.6799L20.3998 6.81988C20.7598 6.60988 21.2198 6.7399 21.4298 7.0899C21.6398 7.4499 21.5098 7.90987 21.1598 8.11987L12.3899 13.1999C12.2599 13.2599 12.1298 13.2999 11.9998 13.2999Z"
                                        fill="#1f4063"
                                    />
                                    <path
                                        d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z"
                                        fill="#1f4063"
                                    />
                                    <path
                                        d="M12.0001 22.75C11.1201 22.75 10.2501 22.56 9.56012 22.18L4.22012 19.21C2.77012 18.41 1.64014 16.48 1.64014 14.82V9.16998C1.64014 7.50998 2.77012 5.59002 4.22012 4.78002L9.56012 1.82C10.9301 1.06 13.0701 1.06 14.4401 1.82L19.7801 4.78997C21.2301 5.58997 22.3601 7.51999 22.3601 9.17999V14.83C22.3601 16.49 21.2301 18.41 19.7801 19.22L14.4401 22.18C13.7501 22.56 12.8801 22.75 12.0001 22.75ZM12.0001 2.74999C11.3701 2.74999 10.7501 2.88 10.2901 3.13L4.95013 6.09997C3.99013 6.63997 3.14014 8.06999 3.14014 9.17999V14.83C3.14014 15.93 3.99013 17.37 4.95013 17.91L10.2901 20.88C11.2001 21.39 12.8001 21.39 13.7101 20.88L19.0501 17.91C20.0101 17.37 20.8601 15.94 20.8601 14.83V9.17999C20.8601 8.07999 20.0101 6.63997 19.0501 6.09997L13.7101 3.13C13.2501 2.88 12.6301 2.74999 12.0001 2.74999Z"
                                        fill="#1f4063"
                                    />
                                    <path
                                        d="M17.0002 13.99C16.5902 13.99 16.2502 13.65 16.2502 13.24V10.0201L7.13018 4.76007C6.77018 4.55007 6.65016 4.09005 6.86016 3.74005C7.07016 3.38005 7.52018 3.26003 7.88018 3.47003L17.3702 8.95007C17.6002 9.08007 17.7502 9.33003 17.7502 9.60003V13.2601C17.7502 13.6501 17.4102 13.99 17.0002 13.99Z"
                                        fill="#1f4063"
                                    />
                                </svg>
                                <p className="my-auto ps-2">{t("shipPackage")}</p>
                            </div>
                        </Nav.Link>}
                    {(role === "particulier" || role === "transporteur") &&
                        <>
                            <Nav.Link className=' py-2 py-lg-0 mx-4 my-2 m-lg-0 my-lg-auto' onClick={() => { navigate("/voirTrajets") }} >
                                <div className="d-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#1f4063" />
                                        <path d="M9.00023 21.75H8.00023C7.59023 21.75 7.25023 21.41 7.25023 21C7.25023 20.59 7.57023 20.26 7.98023 20.25C6.41023 14.89 6.41023 9.11 7.98023 3.75C7.57023 3.74 7.25023 3.41 7.25023 3C7.25023 2.59 7.59023 2.25 8.00023 2.25H9.00023C9.24023 2.25 9.47023 2.37 9.61023 2.56C9.75023 2.76 9.79023 3.01 9.71023 3.24C7.83023 8.89 7.83023 15.11 9.71023 20.77C9.79023 21 9.75023 21.25 9.61023 21.45C9.47023 21.63 9.24023 21.75 9.00023 21.75Z" fill="#1f4063" />
                                        <path d="M15.0004 21.7499C14.9204 21.7499 14.8404 21.7399 14.7604 21.7099C14.3704 21.5799 14.1504 21.1499 14.2904 20.7599C16.1704 15.1099 16.1704 8.88994 14.2904 3.22994C14.1604 2.83994 14.3704 2.40994 14.7604 2.27994C15.1604 2.14994 15.5804 2.35994 15.7104 2.74994C17.7004 8.70994 17.7004 15.2699 15.7104 21.2199C15.6104 21.5499 15.3104 21.7499 15.0004 21.7499Z" fill="#1f4063" />
                                        <path d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z" fill="#1f4063" />
                                        <path d="M20.9998 9.75011C20.9198 9.75011 20.8398 9.74011 20.7598 9.71011C15.1098 7.83011 8.87978 7.83011 3.22978 9.71011C2.82978 9.84011 2.40978 9.63011 2.27978 9.24011C2.15978 8.84011 2.36978 8.42011 2.75978 8.29011C8.71978 6.30011 15.2798 6.30011 21.2298 8.29011C21.6198 8.42011 21.8398 8.85011 21.6998 9.24011C21.6098 9.55011 21.3098 9.75011 20.9998 9.75011Z" fill="#1f4063" />
                                    </svg>
                                    <p className="my-auto ps-2">{t("seeTrips")}</p>
                                </div>
                            </Nav.Link>

                            <Nav.Link className=' py-2 py-lg-0 mx-4 my-2 m-lg-0 my-lg-auto' onClick={() => { navigate("/proposerTrajet") }}>
                                <div className="d-flex align-items-center">
                                    <svg
                                        className='mr-2'
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="#1f4063" />
                                        <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="#1f4063" />
                                    </svg>

                                    <p className="my-auto ps-2">{t('addTrip')}</p>
                                </div>
                            </Nav.Link>
                            <Nav.Link className=' py-2 py-lg-0 mx-4 my-2 m-lg-0 my-lg-auto' onClick={() => { navigate("/blogs") }} >
                                <div className="d-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#1f4063" fillOpacity="0.88" />
                                        <path d="M22 10.7505H18C14.58 10.7505 13.25 9.42048 13.25 6.00048V2.00048C13.25 1.70048 13.43 1.42048 13.71 1.31048C13.99 1.19048 14.31 1.26048 14.53 1.47048L22.53 9.47048C22.74 9.68048 22.81 10.0105 22.69 10.2905C22.57 10.5705 22.3 10.7505 22 10.7505ZM14.75 3.81048V6.00048C14.75 8.58048 15.42 9.25048 18 9.25048H20.19L14.75 3.81048Z" fill="#1f4063" fillOpacity="0.88" />
                                        <path d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z" fill="white" fillOpacity="0.88" />
                                        <path d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z" fill="#1f4063" fillOpacity="0.88" />
                                    </svg>

                                    <p className="my-auto ps-2">{t('blogs')}</p>
                                </div>

                            </Nav.Link>
                        </>}
                    {isAuthenticated && (
                        <Nav.Link className=' py-2 py-lg-0 ms-lg-auto mt-4 mt-lg-0 d-flex align-items-center align-self-center'>
                            <select className='form-control' defaultValue={i18n.language} onChange={(e) => { handleLanguageChange(e.target.value) }}>
                                <option value="fr"  >Fr</option>
                                <option value="en"  >En</option>
                                <option value="ar"  >ع</option>
                            </select>
                            <div className={`d-flex align-items-center  me-3`}>
                                <span className="position-relative align-self-center h-auto" role="button" data-bs-toggle="dropdown">
                                    <svg aria-expanded="false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.02 20.5299C9.69 20.5299 7.36 20.1599 5.15 19.4199C4.31 19.1299 3.67 18.5399 3.39 17.7699C3.1 16.9999 3.2 16.1499 3.66 15.3899L4.81 13.4799C5.05 13.0799 5.27 12.2799 5.27 11.8099V8.91992C5.27 5.19992 8.3 2.16992 12.02 2.16992C15.74 2.16992 18.77 5.19992 18.77 8.91992V11.8099C18.77 12.2699 18.99 13.0799 19.23 13.4899L20.37 15.3899C20.8 16.1099 20.88 16.9799 20.59 17.7699C20.3 18.5599 19.67 19.1599 18.88 19.4199C16.68 20.1599 14.35 20.5299 12.02 20.5299ZM12.02 3.66992C9.13 3.66992 6.77 6.01992 6.77 8.91992V11.8099C6.77 12.5399 6.47 13.6199 6.1 14.2499L4.95 16.1599C4.73 16.5299 4.67 16.9199 4.8 17.2499C4.92 17.5899 5.22 17.8499 5.63 17.9899C9.81 19.3899 14.24 19.3899 18.42 17.9899C18.78 17.8699 19.06 17.5999 19.19 17.2399C19.32 16.8799 19.29 16.4899 19.09 16.1599L17.94 14.2499C17.56 13.5999 17.27 12.5299 17.27 11.7999V8.91992C17.27 6.01992 14.92 3.66992 12.02 3.66992Z" fill="#1f4063" />
                                        <path d="M13.88 3.93969C13.81 3.93969 13.74 3.92969 13.67 3.90969C13.38 3.82969 13.1 3.76969 12.83 3.72969C11.98 3.61969 11.16 3.67969 10.39 3.90969C10.11 3.99969 9.80999 3.90969 9.61999 3.69969C9.42999 3.48969 9.36999 3.18969 9.47999 2.91969C9.88999 1.86969 10.89 1.17969 12.03 1.17969C13.17 1.17969 14.17 1.85969 14.58 2.91969C14.68 3.18969 14.63 3.48969 14.44 3.69969C14.29 3.85969 14.08 3.93969 13.88 3.93969Z" fill="#1f4063" />
                                        <path d="M12.02 22.8096C11.03 22.8096 10.07 22.4096 9.37 21.7096C8.67 21.0096 8.27 20.0496 8.27 19.0596H9.77C9.77 19.6496 10.01 20.2296 10.43 20.6496C10.85 21.0696 11.43 21.3096 12.02 21.3096C13.26 21.3096 14.27 20.2996 14.27 19.0596H15.77C15.77 21.1296 14.09 22.8096 12.02 22.8096Z" fill="#1f4063" />
                                    </svg>
                                    {showNotificationState && <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1"><span className="visually-hidden">unread messages</span></span>}
                                </span>
                                <ul className="dropdown-menu dropdown-menu-up dropdown-menu-lg-end overflow-auto col-12 col-sm-12 col-lg-4" style={{ maxWidth: '90vw', height: "70vh" }}>
                                    {notifications.map(notification => (
                                        <li key={notification._id} className={`dropdown-item ${notification.read ? 'bg-light' : 'bg-white'}`}>
                                            <div onClick={() => handleNotificationsClick(notification)} >
                                                <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{notification.message}</p>
                                                <small style={{ color: "#1f406d" }}>{new Date(notification.date).toLocaleString()}</small>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <NavDropdown
                                drop='down'
                                className={classes.dropdown}
                                title={
                                    <div className="d-inline-flex align-items-center">
                                        <svg
                                            width={30}
                                            height={30}
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.1501 16.9126C15.1251 16.9126 15.0876 16.9126 15.0626 16.9126C15.0251 16.9126 14.9751 16.9126 14.9376 16.9126C12.1001 16.8251 9.9751 14.6126 9.9751 11.8876C9.9751 9.1126 12.2376 6.8501 15.0126 6.8501C17.7876 6.8501 20.0501 9.1126 20.0501 11.8876C20.0376 14.6251 17.9001 16.8251 15.1876 16.9126C15.1626 16.9126 15.1626 16.9126 15.1501 16.9126ZM15.0001 8.7126C13.2501 8.7126 11.8376 10.1376 11.8376 11.8751C11.8376 13.5876 13.1751 14.9751 14.8751 15.0376C14.9126 15.0251 15.0376 15.0251 15.1626 15.0376C16.8376 14.9501 18.1501 13.5751 18.1626 11.8751C18.1626 10.1376 16.7501 8.7126 15.0001 8.7126Z"
                                                fill="#1f4063"
                                            />
                                            <path
                                                d="M15 28.4374C11.6375 28.4374 8.42504 27.1874 5.93754 24.9124C5.71254 24.7124 5.61254 24.4124 5.63754 24.1249C5.80004 22.6374 6.72504 21.2499 8.26254 20.2249C11.9875 17.7499 18.025 17.7499 21.7375 20.2249C23.275 21.2624 24.2 22.6374 24.3625 24.1249C24.4 24.4249 24.2875 24.7124 24.0625 24.9124C21.575 27.1874 18.3625 28.4374 15 28.4374ZM7.60004 23.8749C9.67504 25.6124 12.2875 26.5624 15 26.5624C17.7125 26.5624 20.325 25.6124 22.4 23.8749C22.175 23.1124 21.575 22.3749 20.6875 21.7749C17.6125 19.7249 12.4 19.7249 9.30004 21.7749C8.41254 22.3749 7.82504 23.1124 7.60004 23.8749Z"
                                                fill="#1f4063"
                                            />
                                            <path
                                                d="M15 28.4375C7.5875 28.4375 1.5625 22.4125 1.5625 15C1.5625 7.5875 7.5875 1.5625 15 1.5625C22.4125 1.5625 28.4375 7.5875 28.4375 15C28.4375 22.4125 22.4125 28.4375 15 28.4375ZM15 3.4375C8.625 3.4375 3.4375 8.625 3.4375 15C3.4375 21.375 8.625 26.5625 15 26.5625C21.375 26.5625 26.5625 21.375 26.5625 15C26.5625 8.625 21.375 3.4375 15 3.4375Z"
                                                fill="#1f4063"
                                            />
                                        </svg>
                                        <span className='ps-2' >{t('myAccount')}</span>
                                    </div>
                                } id="collasible-nav-dropdown">
                                <NavDropdown.Item onClick={() => navigate("/gererCompte")}>
                                    <div className={`d-flex align-items-center ${classes.dropdown}`}>

                                        {t('manageAccount')}

                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/Dashboard')}>
                                    {t("dashboard")}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => {
                                    navigate("/")
                                    handleSignOut()
                                }}>
                                    <div className="d-flex align-items-center">
                                        <p>{t("logout")}</p>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Link>
                    )}
                    {!isAuthenticated &&
                        (

                            <div className='mx-auto mx-lg-0 ms-lg-auto mt-4 mt-lg-0 d-flex align-items-center'>
                                <select className='form-control' onChange={(e) => { handleLanguageChange(e.target.value) }}>
                                    <option value="fr" selected={i18n.language === "fr"} >Fr</option>
                                    <option value="en" selected={i18n.language === "en"} >En</option>
                                    <option value="ar" selected={i18n.language === "ar"} >ع</option>
                                </select>
                                <a className="btn border-0 fw-bold" onClick={() => { navigate("/inscription") }}>{t('register')}</a>
                                <div className=' border border-1 h-100  border-dark-subtle rounded' style={{
                                    minHeight: "20px"
                                }}></div>
                                <a className="btn border-0 fw-bold  rounded-0" onClick={() => { navigate("/connection") }} style={{ color: "#d8a942 " }}>{t("login")}</a>
                            </div>
                        )}
                </Nav>
            </Navbar.Offcanvas>
        </Navbar>

    );
};

export default MyNavbar;
