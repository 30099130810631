import React from 'react';
import { useTranslation } from 'react-i18next';

function Unauthorized() {
    const { t } = useTranslation();
    return (
        <div className="container" >
            <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                <div className="row">
                    <div className="col-6 mx-auto my-4">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="#db3646" className="bi bi-x-octagon" viewBox="0 0 16 16">
                            <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>
                </div>

                <div className="card-body text-center">
                    <h4 className="text-danger mt-3">{t('unothorized')}</h4>
                    <p className="mt-3">{t('unothorizedText')}</p>
                </div>
            </div>
        </div>
    );
}

export default Unauthorized;