import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AjouterColisForm from "./ajouer-colis-form.js";
import AjouterTrajetForm from "./ajouter-trajet-form.js";
import AjouterOffreForm from "./ajouter-offre-form.js";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function AjouterColis() {
    const [disabled, setDisabled] = useState(false)
    const { t } = useTranslation()
    const initialColis = [{ id: Date.now(), title: undefined, qte: undefined, width: undefined, height: undefined, length: undefined, weight: undefined, photos: [] }];
    const [errors, setErrors] = useState({})
    const [prixErrors, setPrixErrors] = useState({ error1: false, error2: false })
    const initialUploadedFiles = [];
    const initialUploadedColis = [];
    const initialOffre = {};
    const modalRef = useRef(null);
    const modalRef2 = useRef(null);
    const modalRef3 = useRef(null);
    const modalRef4 = useRef(null);
    const form1 = useRef(null);
    const form2 = useRef(null);
    const form3 = useRef(null);
    const [goDateValid, setGoDateValid] = useState()
    const [arriveDateValid, setArriveDateValid] = useState()
    useEffect(() => {
        console.log("Bootstrap loaded:", window.bootstrap);
    }, []);

    const [colis, setColis] = useState(initialColis);
    const [uploadedFiles, setUploadedFiles] = useState(initialUploadedFiles);
    const [uploadedColis, setUploadedColis] = useState(initialUploadedColis);
    const [offre, setOffre] = useState(initialOffre);

    const [step, setStep] = useState(1);
    const [trajet, setTrajet] = useState({})
    const location = useLocation();
    const hasRun = useRef(false); // Ensures effect runs only once

    useEffect(() => {
        if (hasRun.current) return; // Prevent re-execution
        hasRun.current = true;
        if (location.state && location.state.trajet) {
            setTrajet(location.state.trajet);
            setStep(2);
        }
        const newColis = [...colis];
        console.log(location.state?.colis);
        newColis[0] = { ...location.state?.colis, photos: [] } || newColis[0];
        setColis(newColis);
        navigate(location.pathname, { replace: true });

    }, [location]);
    const navigate = useNavigate();
    const addColi = (e) => {
        e.preventDefault()
        let colisId = Date.now();
        setErrors(pervErrors => ({ ...pervErrors, [colisId]: { errors: {} } }));
        setColis(prevColis => [...prevColis, { id: colisId, title: null, qte: null, width: null, height: null, length: null, weight: null, photos: [] }]);
        console.log(colis)
    }
    const handleRemoveColi = async (coliIndex) => {
        setColis(prevColis => prevColis.filter((_, index) => index !== coliIndex));
    };
    const verifyColis = () => {
        let valid = true
        if (colis.length === 0) {
            addColi()
            return false
        }
        if (colis.some(coli => !coli.title || !coli.qte || !coli.width || !coli.height || !coli.length || !coli.weight || coli.photos.length === 0 || coli.qte < 1 || coli.width < 0.1 || coli.height < 0.1 || coli.length < 0.1 || coli.weight < 0)) {
            valid = false;
        }
        return valid
    }
    const isSameDate = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };
    const isSameAddress = (address1, address2) => {
        if (!address1 || !address2) return false;

        const isSameName = address1.name === address2.name;
        const isSameLocation =
            address1.location?.lat === address2.location?.lat &&
            address1.location?.lng === address2.location?.lng;

        return isSameName && isSameLocation;
    };
    const verifyTrajet = () => {
        let v = true
        const goTime = new Date(trajet.go_time)
        const arriveTime = new Date(trajet.arrive_time)
        console.log("checking time")
        if (goTime > arriveTime) {
            console.log("goTime > arriveTime")
            setGoDateValid(false)
            setArriveDateValid(false)
            v = false
        }
        if (!trajet.transporteur && goTime < new Date() && !isSameDate(goTime, new Date())) {
            console.log("goTime < Date.now()")
            setGoDateValid(false)
            v = false
        }
        if (isSameAddress(trajet.from_address, trajet.to_address)) {
            v = false
            openModal(modalRef4)
        }
        setGoDateValid(true)
        setArriveDateValid(true)
        return v
    }
    const verifyPrix = () => {
        if (trajet.prix === undefined || trajet.prix <= 1) {
            setPrixErrors({ error1: true, error2: false });
            return false;
        } else if (isNaN(trajet.prix)) {
            setPrixErrors({ error1: false, error2: true });
            return false;
        } else {
            setPrixErrors({ error1: false, error2: false });
            return true;
        }
    };

    const nextStep = (e) => {
        e.preventDefault();

        const validateForm = (formRef) => {
            if (!formRef?.current) {
                console.error(`Form reference is undefined!`);
                return false;
            }
            if (!formRef.current.checkValidity()) {
                e.stopPropagation();
                formRef.current.classList.add('was-validated');
                console.log(`Validation failed for step`);
                return false;
            }
            return true;
        };

        let isValid = true;

        if (step >= 1) {
            isValid = validateForm(form1) && verifyColis() && isValid;
        }
        if (step >= 2) {
            isValid = validateForm(form2) && verifyTrajet() && isValid;
        }
        if (step === 3) {
            isValid = validateForm(form3) && verifyPrix() && isValid;
        }

        if (isValid) {
            if (step < 3) {
                setStep(prevStep => prevStep + 1);
            } else {
                submit();

            }
        }
    };

    const submitTrajet = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER}/trajets`, trajet);
            console.log(res.data);
            return res.data;
        } catch (err) {
            console.error("Erreur lors de l'ajout du trajet", err);
            throw err;
        }
    };
    const submitColis = async (id) => {
        for (let i of colis) {
            console.log(i);

            // Initialize the coli object
            let coli = {
                title: i.title,
                qte: i.qte,
                width: i.width,
                height: i.height,
                length: i.length,
                weight: i.weight,
                photos: [] // Ensure photos array exists
            };

            console.log(trajet);
            if (!trajet.transporteur) {
                console.log("set trajet id");
                coli.trajet = id;
            }

            // Upload photos and get the paths
            let photos = [];
            console.log(i.photos);
            for (let j of i.photos) {
                console.log(j);
                let formData = new FormData();
                formData.append('file', j.file);

                try {
                    const res1 = await axios.post(`${process.env.REACT_APP_SERVER}/files`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    console.log(res1.data);

                    if (res1.data.fileName) { // Ensure the file name/path exists in the response
                        const uploadedFilePath = res1.data.fileName; // Get the correct field from the response
                        photos.push(uploadedFilePath); // Push the uploaded file path to the array
                        uploadedFiles.push(uploadedFilePath); // Track uploaded files
                    } else {
                        throw new Error("File upload response does not contain the file path.");
                    }
                } catch (err) {
                    console.error("ERROR", err.message, j);
                    throw err; // If an error occurs, throw it to be caught in the outer catch block
                }
            }

            // Check if photos were uploaded successfully
            if (photos.length > 0) {
                coli.photos = photos; // Set the photos array in the coli object
                console.log(coli);

                try {
                    // Send the coli data including photos to the server
                    await axios.post(`${process.env.REACT_APP_SERVER}/colis`, coli)
                        .then((res) => {
                            console.log(res.data);
                            uploadedColis.push(res.data); // Track uploaded colis data
                        });

                } catch (err) {
                    throw err; // If an error occurs, throw it to be caught in the outer catch block
                }
            } else {
                throw new Error("No images uploaded for this colis."); // If no image is uploaded, throw an error
            }
        }

    };
    const submitOffre = async () => {
        try {
            const colisIds = uploadedColis.map(colis => colis._id);
            console.log(colisIds);
            let finalOffre = {};
            finalOffre.offre = offre;
            finalOffre.colis = colisIds;
            finalOffre.offre.prix = trajet.prix;
            finalOffre.ttrajet = trajet._id;
            console.log(finalOffre);
            await axios.post(`${process.env.REACT_APP_SERVER}/TtrajetOffres`, finalOffre).then((res) => {
                openModal(modalRef2);
            }).catch((err) => {
                if (err.response.status === 400 && err.response.data.error === 1) {
                    console.log("open modal ");
                    openModal(modalRef);
                }
            });
        } catch (err) {
            console.error("Erreur lors de l'ajout de l'offre", err.message);
            throw err;
        }
    };
    const deleteTrajet = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${trajet._id}`);
        } catch (err) {
            console.error("Erreur lors de la suppression du trajet", err.message);
        }
    }
    const deleteColis = async () => {
        for (let coli of uploadedColis) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER}/colis/${coli._id}`);
            } catch (deleteErr) {
                console.error("Failed to delete coli:", deleteErr);
            }
        }
    }
    const deleteOffre = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${offre._id}`);
        } catch (err) {
            console.error("Erreur lors de la suppression de l'offre", err.message);
        }
    }
    const deleteFiles = async () => {
        for (let filePath of uploadedFiles) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER}/files/${filePath}`);
            } catch (deleteErr) {
                console.error(`Failed to delete file ${filePath}:`, deleteErr);
            }
        }
    }
    const submit = async () => {
        try {
            console.log("onSubmit1");
            console.log("trajet:", trajet);
            setDisabled(true)
            if (!trajet.transporteur || trajet.go_time < Date.now() || trajet.go_time > trajet.arrive_time) {
                console.log("1")
                let newTrajet = await submitTrajet();
                await submitColis(newTrajet._id);
                openModal(modalRef3);
            } else {
                console.log(trajet)
                console.log(offre)
                console.log("2")
                await submitColis();
                await submitOffre();
            }
            setDisabled(false)
        } catch (err) {
            deleteColis();
            deleteFiles();
            setUploadedColis(initialUploadedColis)
            setUploadedFiles(initialUploadedFiles)
            if (trajet.transporteur)
                deleteOffre();
            else
                deleteTrajet();
            setDisabled(false)

        }
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    return (
        <>
            <Helmet>
                <title>Ajouter colis</title>
                <link rel="canonical" href="https://www.colispo.com/ajouterColis" />
                <meta name="description" content="Ajouter votre colis pour recevoir les offres." />
            </Helmet>

            <div className="row m-0">
                {/* Step 1: Colis Form */}
                {step >= 1 && (
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-6 p-3 ">
                        <form ref={form1} onSubmit={nextStep} className="needs-validation" noValidate>


                            {colis.map((coli, index) => (
                                <div className='position-relative mb-3' key={coli.id}>
                                    <AjouterColisForm coli={coli} index={index} setColis={setColis} errors={errors[coli.id]} />
                                    <span onClick={() => { handleRemoveColi(index) }} className="position-absolute top-0 start-100 translate-middle mn-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black" fillOpacity="0.88" />
                                            <path d="M13.0604 12.0004L15.3604 9.70035C15.6504 9.41035 15.6504 8.93035 15.3604 8.64035C15.0704 8.35035 14.5904 8.35035 14.3004 8.64035L12.0004 10.9404L9.70035 8.64035C9.41035 8.35035 8.93035 8.35035 8.64035 8.64035C8.35035 8.93035 8.35035 9.41035 8.64035 9.70035L10.9404 12.0004L8.64035 14.3004C8.35035 14.5904 8.35035 15.0704 8.64035 15.3604C8.79035 15.5104 8.98035 15.5804 9.17035 15.5804C9.36035 15.5804 9.55035 15.5104 9.70035 15.3604L12.0004 13.0604L14.3004 15.3604C14.4504 15.5104 14.6404 15.5804 14.8304 15.5804C15.0204 15.5804 15.2104 15.5104 15.3604 15.3604C15.6504 15.0704 15.6504 14.5904 15.3604 14.3004L13.0604 12.0004Z" fill="black" fillOpacity="0.88" />
                                        </svg>
                                    </span>
                                </div>
                            ))}
                            <button className="btn btn-outline-primary col-12 fs-5 " onClick={addColi}>+</button>
                            {step === 1 && <button className="btn btn-primary col-12  mt-3" type="submit">{t('next')}</button>}
                        </form>
                    </div>
                )}

                <div className="col-12 col-md-8 col-lg-6 col-xxl-6 p-3">
                    {/* Step 2: Trajet Form */}
                    {step >= 2 && (
                        <form ref={form2} onSubmit={nextStep} className="needs-validation" noValidate>

                            <AjouterTrajetForm trajet={trajet} setTrajet={setTrajet} offre={offre} setOffre={setOffre} goDateValid={goDateValid} arriveDateValid={arriveDateValid} />
                            {step === 2 && < button className="btn btn-primary col-12 mt-3" type="submit">{t('next')}</button>}

                        </form>

                    )}

                    {/* Step 3: Offre Form */}
                    {step === 3 && (
                        <div className="col-12  py-3 ">
                            <form ref={form3} onSubmit={nextStep} className="needs-validation" noValidate>

                                <AjouterOffreForm trajet={trajet} setTrajet={setTrajet} errors={prixErrors} />
                                <button className="btn btn-primary col-12 mt-3 d-flex justify-content-center align-items-center" disabled={disabled} type="submit">
                                    {disabled &&
                                        <div class="spinner-border spinner-border-sm text-light" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>}
                                    <p className={`mb-0 ${disabled ? 'ms-2' : ''}`}>{t('next')}</p>

                                </button>

                            </form>
                        </div>
                    )}
                </div>
            </div >

            <div className="modal fade" ref={modalRef} tabIndex="-1" aria-labelledby="modal1Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal1Label">{t('occ')}</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert">
                                {t('occText')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => closeModal(modalRef)}>{t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal 2 */}
            <div className="modal fade" ref={modalRef2} tabIndex="-1" data-bs-backdrop="static" aria-labelledby="modal2Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal2Label">{t('ocs')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-success" role="alert">
                                {t('ocsText')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                closeModal(modalRef2)
                                navigate('/dashboard/mesColis')
                            }}>{t('seeDashboard')}</button>
                            <button type="button" className="btn btn-link w-auto" onClick={() => {
                                closeModal(modalRef2)
                                navigate('/')
                            }}>{t('returnHome')}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal 3 */}
            <div className="modal fade" ref={modalRef3} tabIndex="-1" aria-labelledby="modal3Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal3Label">{t('pas')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-success" role="alert">
                                {t('pasText')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                closeModal(modalRef3)
                                navigate('/dashboard/mesColis')
                            }}>{t('seeDashboard')}</button>
                            <button type="button" className="btn btn-link w-auto" onClick={() => {
                                closeModal(modalRef3)
                                navigate('/')
                            }}>{t('returnHome')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-sm fade" ref={modalRef4} data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Vérifier les adresses</h1>
                            <button type="button" className="btn-close" onClick={() => { closeModal(modalRef4) }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div
                                className="alert alert-danger"
                                role="alert"
                            >
                                Veuillez entrer deux adresses différentes
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={() => { closeModal(modalRef4) }}>{t("close")}</button>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default AjouterColis;
