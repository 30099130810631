
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function VerifyEmailToken() {
    const [container, setContainer] = useState('');
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const token = params.get('token');

                // Send a request to your server to verify the email
                await axios.post(`${process.env.REACT_APP_SERVER}/users/verify-email?ver=${token}`).then((res) => {
                    setContainer(1)

                }).catch((err) => {
                    setContainer(2)

                });

            } catch (error) {
                console.log(error);
            }
        };

        verifyEmail();
    }, [location]);

    return (
        <div>
            {container === 1 ? (<div className="container" >
                <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                    <div className="row">
                        <div className="col-6 mx-auto my-4">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none" stroke="#198754" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className="path check" fill="none" stroke="#198754" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </div>
                    </div>

                    <div className="card-body text-center">
                        <h4 className="text-success mt-3">{t('emailVerifySuccess')}</h4>
                        <p className="mt-3">{t('emailVerifySuccessText')}</p>
                    </div>
                </div>
            </div>) : (
                <div className="container" >
                    <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                        <div className="row">
                            <div className="col-6 mx-auto my-4">
                                <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className="path circle" fill="none" stroke="#db3646" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <line className="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                    <line className="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                                </svg>
                            </div>
                        </div>

                        <div className="card-body text-center">
                            <h4 className="text-danger mt-3">{t('emailVerifyError')}</h4>
                            <p className="mt-3">{t('emailVerifyErrorText')}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VerifyEmailToken;