import { useEffect, useState } from "react";
import classes from "./ajouter-colis.module.css"
import axios from "axios";
import { useTranslation } from "react-i18next";
function AjouterOffreForm({ trajet, setTrajet, errors }) {
    const { t } = useTranslation()
    const [percentage, setPercentage] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_SERVER}/tarif`).then((res) => {
                    setPercentage(res.data.value / 100);
                }).catch((err) => { console.log(err) })
            } catch (err) {
                console.log(err)
            }
        }

        fetchData();
    }, []);
    const [prix, setPrix] = useState(0)
    return (
        <div className={`${classes.FromBox}`}>
            <div className="row">
                <div className="col">

                    <div className="input-group">
                        <label className="input-group-text">{t('dp')}</label>
                        <input className={`form-control ${errors.error1 || errors.error2 ? "is-invalid" : ""} `}
                            required type="number" min={1} onChange={(e) => {
                                setPrix(e.target.value);
                                setTrajet({ ...trajet, prix: Number(e.target.value) })
                            }}
                        />
                        <div className="input-group-text rounded-end">€</div>
                        <div className="invalid-feedback">
                            {errors.error2 === true ? "Veuillez saisir un prix valide" : "Veuillez entrer le prix désiré"}
                        </div>
                    </div>
                </div>
                <div className="col align-self-center">
                    <p className="mb-0">{t("fp")} : <b> {((prix ?? 0) * (1 + percentage)).toFixed(2)} € </b> </p>
                </div>
            </div>
        </div>
    )
}
export default AjouterOffreForm