import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import PaginationComponent from '../../../components/paginations/pagination';
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import style from "../style.module.css"
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MailEditor() {
    const { t } = useTranslation()
    const columns = t("mailCols", { returnObjects: true });
    const emailEditorRef = useRef(null);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [type, setType] = useState('')
    const [mails, setMails] = useState([])
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [mail, setMail] = useState({})
    const navigate = useNavigate()
    const selectMail = async (id) => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/mails/${id}`).then((response) => {
                const data = response.data;
                console.log(data)
                setMail(data)
                openModal(ref)
            }
            )
        } catch (error) {

        }
    }
    const deleteMail = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/mails/${id}`).then((response) => {
                console.log(response)
                closeModal(ref2)
                fetchData()
            }
            )
        } catch (error) {

        }
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    const isDefaultContent = (design) => {
        // Example: Compare the design to a known default structure
        const defaultStructure = {
            body: {
                rows: [
                    {
                        columns: [
                            {
                                contents: [
                                    {
                                        type: 'text',
                                        values: {
                                            text: '<p>Your default content goes here</p>',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        };

        // Compare current design to default structure
        return JSON.stringify(design.body) === JSON.stringify(defaultStructure.body);
    };
    const validate = async () => {
        if (emailEditorRef.current) {
            return new Promise((resolve) => {
                emailEditorRef.current.editor.exportHtml((data) => {
                    const { design } = data;

                    if (design?.body?.rows?.length === 0) {
                        console.log('The email editor is empty.');
                        resolve(false);
                    } else if (isDefaultContent(design)) {
                        console.log('The email editor still has the default template.');
                        resolve(false);
                    } else if (!type) {
                        console.log('Type de mail est vide');
                        resolve(false);
                    } else {
                        console.log('Validation passed');
                        resolve(true);
                    }
                });
            });
        }
        return false;
    };
    const exportHtml = async () => {
        try {
            const isValid = await validate();
            console.log('Validation Result:', isValid);

            if (isValid) {
                emailEditorRef.current.editor.exportHtml(async (data) => {
                    const { html } = data;
                    console.log('Exported HTML:', data);
                    console.log('Exported HTML:', html);

                    // Send the HTML content and type to the backend
                    await axios.post(`${process.env.REACT_APP_SERVER}/mails`, {
                        content: data,
                        type: type,
                    });

                    console.log('Content successfully sent to the server.');
                    fetchData(page)
                });
            } else {
                console.log('Validation failed. Cannot export HTML.');
            }
        } catch (error) {
            console.error('Error during exportHtml:', error);
        }
    };
    const onLoad = () => {
        console.log('Editor loaded');
    };
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/mails/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async (page) => {
        try {
            page = page || 1;
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/mails?page=${page}`;
            const response = await axios.get(url);
            const data = response.data;
            console.log(data)
            const filteredusersData = data.filter(item => item !== null);
            console.log(filteredusersData)
            setMails(filteredusersData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {

        fetchCount()
        fetchData();
    }, []);

    return (<Row style={{ maxWidth: "100%" }} >
        <div className="mb-3 mt-1 w-100 p-0">
            <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                {t('addMail')}
            </button>
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <div className="col">
                        <div className="p-5">
                            <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
                            <div className='col-12 col-md-8'>
                                <div className=" input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Type de mail" onChange={(e) => {
                                        setType(e.target.value)
                                    }} />
                                    <button className="btn btn-outline-secondary w-auto" type="button" id="button-addon2" onClick={exportHtml}>{t('add')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Card className="Card">
            <CardHeader className="CardHeader">
                <CardTitle tag="h4">{t('mailList')}</CardTitle>
            </CardHeader>
            <CardBody className="pt-0">
                <div className="accordion" id="accordionExample">
                    <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {mails.map((row, index) => (
                                <React.Fragment key={index}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent`} aria-expanded="false" aria-controls={`accordionContent`}>
                                        <td className=" align-middle">{new Date(row.date_creation).toLocaleDateString('fr-FR')}</td>
                                        <td className=" align-middle">{row.type}</td>
                                        <td className=" align-middle">
                                            <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                <button className="btn btn-primary w-auto" onClick={() => { selectMail(row._id) }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="currentColor" fill-opacity="0.88" />
                                                    </svg>

                                                </button>
                                                <button className="btn btn-success w-auto" onClick={() => {
                                                    navigate("/dashboard/modifierMail", { state: { mail: row } })
                                                }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.97 22.75H8.97C3.54 22.75 1.22 20.43 1.22 15V9C1.22 3.57 3.54 1.25 8.97 1.25H10.97C11.38 1.25 11.72 1.59 11.72 2C11.72 2.41 11.38 2.75 10.97 2.75H8.97C4.36 2.75 2.72 4.39 2.72 9V15C2.72 19.61 4.36 21.25 8.97 21.25H14.97C19.58 21.25 21.22 19.61 21.22 15V13C21.22 12.59 21.56 12.25 21.97 12.25C22.38 12.25 22.72 12.59 22.72 13V15C22.72 20.43 20.41 22.75 14.97 22.75Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M12.78 15.2399C12.63 15.2399 12.49 15.1999 12.36 15.1099C12.15 14.9699 12.03 14.7399 12.03 14.4899C12.03 14.3799 12.02 14.2599 12 14.1399C11.93 13.6099 11.69 13.1399 11.29 12.7299C10.88 12.3199 10.38 12.0599 9.82999 11.9899C9.74999 11.9799 9.61999 11.9699 9.49999 11.9799C9.24999 11.9999 8.99999 11.8899 8.84999 11.6899C8.69999 11.4899 8.65999 11.2199 8.73999 10.9799C8.85999 10.6399 9.04999 10.3199 9.26999 10.0699L10.5 8.52989C12.64 5.85989 16.95 2.65989 20.13 1.38989C20.86 1.10989 21.63 1.26989 22.16 1.79989C22.71 2.34989 22.87 3.12989 22.58 3.83989C21.31 7.00989 18.11 11.3299 15.45 13.4599L13.88 14.7199C13.57 14.9399 13.32 15.0899 13.06 15.1899C12.97 15.2199 12.87 15.2399 12.78 15.2399ZM10.72 10.6699C11.33 10.8699 11.88 11.2099 12.35 11.6699C12.81 12.1299 13.14 12.6599 13.33 13.2299L14.51 12.2799C17 10.2799 20 6.23989 21.19 3.26989C21.27 3.07989 21.18 2.93989 21.1 2.84989C21.05 2.79989 20.91 2.68989 20.68 2.77989C17.72 3.96989 13.68 6.96989 11.68 9.45989L10.72 10.6699Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M7.70001 18.75C7.06001 18.75 6.45001 18.5 5.99001 18.04C5.46001 17.51 5.20001 16.78 5.29001 16.03L5.50001 14.07C5.72001 12.04 7.37001 10.54 9.44001 10.5C9.60001 10.49 9.80001 10.5 9.99001 10.51C10.9 10.63 11.71 11.03 12.35 11.68C12.99 12.32 13.38 13.08 13.49 13.94C13.52 14.13 13.53 14.32 13.53 14.5C13.53 15.59 13.11 16.62 12.34 17.39C11.71 18.02 10.89 18.41 9.96001 18.53L7.98001 18.74C7.88001 18.75 7.79001 18.75 7.70001 18.75ZM9.60001 11.99C9.57001 11.99 9.53001 11.99 9.50001 11.99C8.35001 12.02 7.14001 12.79 6.99001 14.23L6.78001 16.2C6.75001 16.49 6.85001 16.78 7.05001 16.98C7.25001 17.18 7.53001 17.28 7.81001 17.25L9.78001 17.04C10.36 16.97 10.88 16.72 11.27 16.33C11.75 15.84 12.02 15.2 12.02 14.5C12.02 14.39 12.01 14.27 11.99 14.15C11.92 13.62 11.68 13.15 11.28 12.74C10.87 12.33 10.37 12.07 9.82001 12C9.77001 11.99 9.69001 11.99 9.60001 11.99Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M15.82 12.7299C15.41 12.7299 15.07 12.3899 15.07 11.9799C15.07 10.3099 13.71 8.93994 12.03 8.93994C11.62 8.93994 11.28 8.59994 11.28 8.18994C11.28 7.77994 11.62 7.43994 12.03 7.43994C14.53 7.43994 16.57 9.46994 16.57 11.9799C16.57 12.3899 16.24 12.7299 15.82 12.7299Z" fill="currentColor" fill-opacity="0.88" />
                                                    </svg>
                                                </button>
                                                <button className="btn btn-danger w-auto" onClick={() => {
                                                    setMail(row)
                                                    openModal(ref2)
                                                }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="currentColor" fill-opacity="0.88" />
                                                        <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="currentColor" fill-opacity="0.88" />
                                                    </svg>

                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

            </CardBody>
        </Card>
        <div className="modal fade" ref={ref} tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div
                className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-capitalize">{mail.type}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => { closeModal(ref) }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body" dangerouslySetInnerHTML={{ __html: mail.content?.html }}></div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => { closeModal(ref) }}
                        >
                            {t("close")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" ref={ref2} tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div
                className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-capitalize">{t("deleteMail")}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => { closeModal(ref2) }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div
                            className="alert alert-danger"
                            role="alert"
                        >
                            {t('deleteMailText')}
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => { closeModal(ref2) }}
                        >
                            {t("close")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => {
                                console.log(mail._id)
                                deleteMail(mail._id)
                            }}
                        >
                            {t('delete')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Row>

    );
};

export default MailEditor;