import React, { useEffect, useState } from 'react';
import classes from "./home.module.css";
import Map from "../../components/map/map"
import svg1 from "../../assets/svg1.png";
import svg2 from "../../assets/svg2.png";
import svg3 from "../../assets/svg3.png";
import pays1 from "../../assets/pays1.jpg";
import pays2 from "../../assets/pays2.jpg";
import pays3 from "../../assets/pays3.jpg";
import pays4 from "../../assets/pays4.jpg";
import pays5 from "../../assets/pays5.jpg";
import pays6 from "../../assets/pays6.jpg";
import Mapinput from '../../components/map/input';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import fetchImage from '../../components/fetchImg';
import { useTranslation } from 'react-i18next';
import ccmc from "../../assets/ccmc.jpg"
function Home() {
    const { t } = useTranslation();
    const weightList = t("weightList", { returnObjects: true })

    const [colis, setColis] = useState({});
    const [showFirstDiv, setShowFirstDiv] = useState({ display: "block" });
    const [showSecondDiv, setShowSecondDiv] = useState({ display: "none" });
    const [firstColor, setFirstColor] = useState('#1f406d');
    const [secondColor, setSecondColor] = useState('#white');
    const [selectedPlace1, setSelectedPlace1] = useState({ "name": "", "position": null });
    const [selectedPlace2, setSelectedPlace2] = useState({ "name": "", "position": null });
    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);
    const [bgPosition, setBGPosition] = useState(0);
    const [sliders, setSliders] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchTrajets = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/trajets/samples`;
                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);

                // Now filteredTrajetsData contains only successfully fetched data
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        const fetchTtrajets = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/t_trajets/samples`;
                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const userResponse = await axios.get(`${process.env.REACT_APP_SERVER}/users/${TrajetItem.transporteur}`);
                        TrajetItem.transporteur = userResponse.data ?? {};
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });
                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTtrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);

            }
        }
        const fetchSliders = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/sliders/active`;
                const sliderResponse = await axios.get(url);
                const sliderData = sliderResponse.data;
                console.log(sliderData)
                setSliders(sliderData);
            }
            catch (error) {
                console.error("Error fetching data:", error);

            }
        }
        fetchTrajets();
        fetchTtrajets();
        fetchSliders();
    }, []);

    const handlePlaceSelection1 = (place) => {
        setSelectedPlace1(place);
        console.log(place)
    };
    const handlePlaceSelection2 = (place) => {
        setSelectedPlace2(place);
        console.log(place)
    };
    const showFirst = () => {
        setShowFirstDiv({ display: "block" });
        setShowSecondDiv({ display: "none" });

        setSecondColor('white');
        setFirstColor('#1f406d');
        setBGPosition(0);
    };
    //'white'  '#1f406d'
    const showSecond = () => {
        setShowSecondDiv({ display: "block" });
        setShowFirstDiv({ display: "none    " });

        setFirstColor('white');
        setSecondColor('#1f406d');
        setBGPosition(50);
    };
    const handleTrajetSubmit = (e) => {
        e.preventDefault();
        console.log(selectedPlace1, selectedPlace2)
        navigate('/voirColis', { state: { place1: selectedPlace1, place2: selectedPlace2 } })
    }

    return (
        <>
            <Helmet>
                <title>{t('home')}</title>
                <meta name="description" content="Colispo est la première plateforme au monde dédiée au covoiturage de colis entre particuliers. Notre service met en relation des personnes souhaitant expédier des colis à moindre coût et des conducteurs prêts à transporter ces envois lors de leurs trajets." />
                <meta name="keywords" content={`${process.env.REACT_APP_KEYWORDS}`} />
            </Helmet>
            <div className={`${classes.body}`}>
                <div className={`${classes.firstContainer} mt-0`}>
                    <div id="carouselExampleAutoplaying" className={`carousel slide carousel-fade ${classes.carouselHeight} `} data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {sliders.map((slider, index) => (
                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                    <img src={fetchImage(slider.path)} className="d-block w-100" alt="..." />
                                </div>
                            ))}

                        </div>

                    </div>
                    <div className={`position-absolute start-50 translate-middle z-1 rounded  p-3 w-100 ${classes.containerTop}`}>
                        <p className={`${classes.t1} fs-1 fw-bold `}>{t("carrierNeeded")}</p>
                        <p className={`${classes.t2} fs-1 fw-bold `}>{t('becomeCarrier')}</p>
                    </div>

                    <div className={`${classes.container}  position-absolute start-50 translate-middle z-2  `}>
                        <div className='container'>
                            <div className={`${classes.button_container} py-3 bg-white `} >
                                <button onClick={showFirst} style={{ color: secondColor }} className='bg-transparent '>
                                    <p className='d-md-none p-0 m-0'>{t('searchTripsSM')}</p>
                                    <p className='d-none d-md-block p-0 m-0'>{t('searchTrips')}</p>
                                </button>
                                <button onClick={showSecond} style={{ color: firstColor }} className='bg-transparent '>
                                    <p className='d-md-none p-0 m-0'>{t('shipYourPackageSM')}</p>
                                    <p className='d-none d-md-block p-0 m-0'>{t('shipYourPackage')}</p>
                                </button>
                                <div
                                    className="position-absolute z-n1 top-0 start-0 p-2 "
                                    style={{
                                        transition: 'transform 0.5s ease',
                                        transform: bgPosition === 0 ? 'translateX(0%)' : 'translateX(100%)',
                                        width: '50%',
                                        height: '100%'
                                    }}
                                >
                                    <div style={{ backgroundColor: '#1f406d', height: "100%" }} className='rounded-pill'></div>
                                </div>
                            </div>
                            <form style={showSecondDiv} className={`position-relative ${classes.form_container} p-2 px-md-3 pb-md-3 pt-0`} onSubmit={(e) => { e.preventDefault() }} noValidate onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();  // Prevent form submission when Enter is pressed
                                }
                            }}>
                                <div className='row w-100 mx-0'>
                                    <div className="col-4 col-md-2 p-0"/*{`${classes.input_container}`}*/>
                                        <label htmlFor="">{t('qte')}</label>
                                        <input onChange={(e) => {
                                            setColis({ ...colis, [e.target.name]: e.target.value })
                                        }} placeholder="Qte" className='form-control' type="number" name='qte' />
                                    </div>
                                    <div className="col-8 col-md-3 pe-0 pe-md-2"/*{`${classes.input_container}`}*/>
                                        <label htmlFor="">{t('objectName')}</label>
                                        <input onChange={(e) => {
                                            setColis({ ...colis, [e.target.name]: e.target.value })
                                        }} placeholder="Smartphone" className='form-control' type="text" name="title" />
                                    </div>
                                    <div className="col-12 col-md-5 p-0"/*{`${classes.input placeholder="Saisir"s_container}`}*/>
                                        <div className='row m-0 p-0'>
                                            <div className="col-4 ps-0"/*{`${classes.input placeholder="Saisir"_container}`}*/>
                                                <label htmlFor="" className='d-block d-sm-none'>{t('lengthSM')} </label>
                                                <label htmlFor="" className='d-none d-sm-block'>{t('length')} </label>
                                                <input onChange={(e) => {
                                                    setColis({ ...colis, [e.target.name]: e.target.value })
                                                }}
                                                    placeholder="cm" className='form-control' type="number" name='length' />
                                            </div>
                                            <div className="col-4 ps-0"/*{`${classes.input placeholder="Saisir"_container}`}*/>
                                                <label htmlFor="" className='d-block d-sm-none'>{t('widthSM')}</label>
                                                <label htmlFor="" className='d-none d-sm-block'>{t('width')}</label>
                                                <input onChange={(e) => {
                                                    setColis({ ...colis, [e.target.name]: e.target.value })

                                                }} placeholder="cm" className='form-control' type="number" name='width' />
                                            </div>
                                            <div className="col-4 px-0 pe-md-2"/*{`${classes.input placeholder="Saisir"_container}`}*/>

                                                <label htmlFor="" className='d-block d-sm-none'>{t('heightSM')}</label>
                                                <label htmlFor="" className='d-none d-sm-block'>{t('height')}</label>
                                                <input placeholder="cm" className='form-control' type="number" name="height"
                                                    onChange={(e) => {
                                                        setColis({ ...colis, [e.target.name]: e.target.value })
                                                    }} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-12 mt-2 mt-md-0 col-md-2 align-self-end p-0'>
                                        <button className={`btn  ${classes.form_button} col-12`} onClick={() => { navigate('/ajouterColis', { state: { colis: colis } }) }} >{t('next')}</button>
                                    </div>
                                </div>
                            </form>
                            <form style={showFirstDiv} className={`position-relative  ${classes.form_container} p-2 px-md-3 pb-md-3 pt-0`} action='/voirColis' onSubmit={handleTrajetSubmit} noValidate onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();  // Prevent form submission when Enter is pressed
                                }
                            }}>
                                <div className='row align-items-top'>
                                    <div className={`col-6 col-md-3`}>
                                        <label htmlFor="">{t('departure')}</label>
                                        <Mapinput onChange={handlePlaceSelection1} placeholder={t('departure')} />
                                    </div>
                                    <div className={`col-6 col-md-3`}>
                                        <label htmlFor="">{t('arrival')}</label>
                                        <Mapinput onChange={handlePlaceSelection2} placeholder={t('arrival')} />
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <label htmlFor="" className='text-nowrap'>{t('departureDate')}</label>
                                        <input className='form-control' type="datetime-local" name='date' />
                                    </div>
                                    <div className='col-12 mt-2 mt-md-0 col-md-3 justify-content-top align-self-start'>
                                        <button className={`btn  ${classes.form_button} col-12 mt-md-4`} value={"submit2"}>{t('search')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={classes.carouselContainer}>
                    <div className={classes.carousel}>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative " + classes.down}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Tunisie</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Paris</p>
                                </div>

                                <img src={pays1} alt="..." />
                            </div>
                        </div>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative "}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Tunisie</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Allemagne</p>
                                </div>
                                <img src={pays2} alt="..." />

                            </div>
                        </div>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative " + classes.down}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Russia</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Barcelona</p>
                                </div>
                                <img src={pays3} alt="..." />
                            </div>
                        </div>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative "}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Tunisie</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Barcelona</p>
                                </div>
                                <img src={pays4} alt="..." />
                            </div>
                        </div>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative " + classes.down}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Egypte</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Angleterre</p>
                                </div>
                                <img src={pays5} alt="..." />
                            </div>
                        </div>
                        <div className={classes.carouselItem}>
                            <div className={classes.carouselImg + " position-relative "}>
                                <div className='position-absolute z-1 top-50 start-50 translate-middle  text-white text-center mx-auto '>
                                    <p className='fs-3'>Brésil</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
                                        </svg>
                                    </div>
                                    <p className='fs-3'>Allemagne</p>
                                </div>
                                <img src={pays6} alt="..." />

                            </div>
                        </div>

                    </div>
                </div>

                <div className='position-relative'>
                    <div className={`position-aboslute bottom-0 start-0 ${classes.leftBox}`}></div>
                    <div className={`${classes.secondContainer} my-4 rounded-5 position-relative my-5`}>{/*************************** */}
                        <div className='container position-relative z-1'>
                            <div className='row p-0  '>
                                <div className='col-12 col-lg-5 p-0 p-3'>
                                    <div className='position-relative'>
                                        <div >
                                            <Map style={"small"} />
                                            <div className={`position-absolute top-100 start-50 translate-middle ${classes.mapBtn}`}>
                                                <a className="btn text-light btn-lg w-100 rounded-pill " style={{ backgroundColor: "#d8a942" }} onClick={() => { navigate("/voirColis") }} >
                                                    {t('sap')}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className=' col-12 offset-lg-1 col-lg-6 p-0 mt-3 px-3 ps-lg-5'>
                                    <div className="p-0">
                                        <div className='row '>
                                            <p className={`fw-bold ${classes.ccmcText1}`} style={{ color: "#D8A942" }}>
                                                Envoyer un colis avec Colispo, comment ça marche ?
                                            </p>
                                        </div>
                                        <div className={`${classes.ccmc1} p-2 d-flex flex-nowrap mt-0 mt-lg-2 col-9 col-lg-12`}>
                                            <div  >
                                                <svg className={classes.svg} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M91.6668 40.625C89.9585 40.625 88.5418 39.2084 88.5418 37.5V29.1667C88.5418 18.4167 81.5835 11.4584 70.8335 11.4584H29.1668C18.4168 11.4584 11.4585 18.4167 11.4585 29.1667V37.5C11.4585 39.2084 10.0418 40.625 8.3335 40.625C6.62516 40.625 5.2085 39.2084 5.2085 37.5V29.1667C5.2085 14.8334 14.8335 5.20837 29.1668 5.20837H70.8335C85.1668 5.20837 94.7918 14.8334 94.7918 29.1667V37.5C94.7918 39.2084 93.3752 40.625 91.6668 40.625Z" fill="#1f406d" />
                                                    <path d="M70.8335 94.7917H29.1668C14.8335 94.7917 5.2085 85.1667 5.2085 70.8333V62.5C5.2085 60.7917 6.62516 59.375 8.3335 59.375C10.0418 59.375 11.4585 60.7917 11.4585 62.5V70.8333C11.4585 81.5833 18.4168 88.5417 29.1668 88.5417H70.8335C81.5835 88.5417 88.5418 81.5833 88.5418 70.8333V62.5C88.5418 60.7917 89.9585 59.375 91.6668 59.375C93.3752 59.375 94.7918 60.7917 94.7918 62.5V70.8333C94.7918 85.1667 85.1668 94.7917 70.8335 94.7917Z" fill="#1f406d" />
                                                    <path d="M50.0005 54.4998C49.4589 54.4998 48.9172 54.3746 48.4172 54.083L26.3339 41.333C24.8339 40.458 24.3339 38.5415 25.2089 37.0415C26.0839 35.5415 28.0006 35.0414 29.4589 35.9164L49.9589 47.7913L70.3339 35.9998C71.8339 35.1248 73.7505 35.6663 74.5838 37.1246C75.4588 38.6246 74.9172 40.5414 73.4589 41.4164L51.5422 54.083C51.0838 54.333 50.5422 54.4998 50.0005 54.4998Z" fill="#1f406d" />
                                                    <path d="M50 77.1664C48.2917 77.1664 46.875 75.7498 46.875 74.0414V51.3748C46.875 49.6664 48.2917 48.2498 50 48.2498C51.7083 48.2498 53.125 49.6664 53.125 51.3748V74.0414C53.125 75.7498 51.7083 77.1664 50 77.1664Z" fill="#1f406d" />
                                                    <path d="M50.001 78.1242C47.5843 78.1242 45.2093 77.5826 43.2926 76.5409L29.9593 69.1244C25.9593 66.916 22.876 61.6243 22.876 57.0409V42.9159C22.876 38.3742 26.001 33.0827 29.9593 30.8327L43.2926 23.4159C47.126 21.2909 52.876 21.2909 56.7093 23.4159L70.0426 30.8327C74.0426 33.041 77.1259 38.3325 77.1259 42.9159V57.0409C77.1259 61.5826 74.0009 66.8744 70.0426 69.1244L56.7093 76.5409C54.7926 77.5826 52.4176 78.1242 50.001 78.1242ZM46.3343 28.9159L33.0009 36.3325C30.9593 37.4575 29.126 40.6242 29.126 42.9159V57.0409C29.126 59.3743 30.9593 62.4993 33.0009 63.6243L46.3343 71.0409C48.2509 72.0825 51.751 72.0825 53.6676 71.0409L67.001 63.6243C69.0426 62.4993 70.8759 59.3326 70.8759 57.0409V42.9159C70.8759 40.5825 69.0426 37.4575 67.001 36.3325L53.6676 28.9159C51.751 27.8743 48.2509 27.8743 46.3343 28.9159Z" fill="#1f406d" />
                                                </svg>
                                            </div>
                                            <div className={`ms-4 ${classes.ccmcText}`} >
                                                <p className='fw-bold mb-0' style={{ color: "#1f406d" }}>
                                                    {t('ve')}
                                                </p>
                                                <p className='fw-medium' style={{ color: "#1f406d" }}>
                                                    {t('vep')}
                                                </p>

                                            </div>
                                        </div>
                                        <div className={`${classes.ccmc1} p-2 d-flex flex-nowrap mt-5 col-9 offset-3 offset-lg-0 col-lg-12`}>
                                            <div  >
                                                <svg className={classes.svg} viewBox="0 0 100 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M83.3335 53.4931V60.9474C83.3335 74.7591 75.8335 80.6785 64.5835 80.6785H27.0835C15.8335 80.6785 8.3335 74.7591 8.3335 60.9474V37.2699C8.3335 23.4581 15.8335 17.5388 27.0835 17.5388H38.3335C37.7918 19.205 37.5002 21.0466 37.5002 23.0197V40.1201C37.5002 44.3732 38.8335 47.9686 41.2085 50.4679C43.5835 52.9672 47.0002 54.3703 51.0418 54.3703V60.4651C51.0418 62.7013 53.4585 64.0602 55.2502 62.8325L67.2918 54.3703H78.1252C80.0002 54.3703 81.7502 54.0631 83.3335 53.4931Z" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M91.6667 23.0197V40.12C91.6667 46.6532 88.5 51.5638 83.3333 53.493C81.75 54.063 80 54.3703 78.125 54.3703H67.2917L55.25 62.8324C53.4583 64.0602 51.0417 62.7013 51.0417 60.4651V54.3703C47 54.3703 43.5833 52.9671 41.2083 50.4679C38.8333 47.9686 37.5 44.3732 37.5 40.12V23.0197C37.5 21.0465 37.7917 19.205 38.3333 17.5388C40.1667 12.1018 44.8333 8.76941 51.0417 8.76941H78.125C86.25 8.76941 91.6667 14.4695 91.6667 23.0197Z" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M30.8335 96.4631H60.8335" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M45.8335 80.6785V96.4632" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M77.0646 31.789H77.1021" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M65.3986 31.789H65.4361" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M53.7306 31.789H53.7681" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </div>
                                            <div className={`ms-4 ${classes.ccmcText}`} >
                                                <p className='fw-bold mb-0' style={{ color: "#1f406d" }}> {t('irt')}</p>
                                                <p className='fw-medium' style={{ color: "#1f406d" }}>
                                                    {t('irtp')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`position-absolute z-0 ${classes.secondCube} `}>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D8A842" }} className={`text-light mb-0 ${classes.ccmccc} d-lg-none m-5y`}>{/************************************** */}
                        <div className={`d-flex flex-column flex-md-row align-items-start ${classes.ccmcc} `}>
                            <div className={classes.imgContainer} data-bs-toggle="collapse" data-bs-target="#ccmcCollapse" aria-expanded="false" aria-controls="collapseWidthExample">
                                <h2 className={`text-light text-center p-4 position-absolute z-2 mb-0 position-absolute top-50 end-0 translate-middle-y ${classes.collapseHeading}`}>
                                    {t('hiw')}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="57" viewBox="0 0 49 57" className={`ms-2 ${classes.arrowIcon} `}>
                                        <g id="Polygone_2" data-name="Polygone 2" transform="translate(49) rotate(90)" fill="none">
                                            <path d="M28.5,0,57,49H0Z" stroke="none" />
                                            <path d="M 28.5 5.966888427734375 L 5.215435028076172 46 L 51.78456497192383 46 L 28.5 5.966888427734375 M 28.5 0 L 57 49 L 0 49 L 28.5 0 Z" stroke="none" fill="#fff" />
                                        </g>
                                    </svg>
                                </h2>
                                <img src={ccmc} className={classes.ccmcImg} alt="..." />
                                <div className={classes.filter}></div>
                            </div>
                            <div className={`collapse ${classes.collapseContainer}`} id="ccmcCollapse" style={{ backgroundColor: "white" }} >
                                <div className={`d-flex flex-column  flex-lg-row align-items-center container-md h-100 p-0  ${classes.ccmccCollapse}`} >
                                    <div className="col-12  nav me-3 align-self-stretch d-flex flex-column justify-content-center align-items-stretch" id="v-pills-tab" aria-orientation="vertical">
                                        <div className={`${classes.ccbutton1} ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" aria-controls="v-pills-home" aria-selected="true" style={{ color: "white" }}>
                                                {t('hiw1')}
                                            </a>
                                        </div>
                                        <div className={`${classes.ccbutton2} ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" style={{ color: "white" }}>
                                                {t('hiw2')}
                                            </a>
                                        </div>
                                        <div className={`${classes.ccbutton3}  ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false" style={{ color: "white" }}>
                                                {t('hiw3')}
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`tab-content p-0 col-12  h-100`} id="v-pills-tabContent" >
                                        <div className="tab-pane show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                            <div className='row'>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.3346 151.667C40.8013 151.667 8.66797 119.533 8.66797 80C8.66797 40.4667 40.8013 8.33337 80.3346 8.33337C119.868 8.33337 152.001 40.4667 152.001 80C152.001 82.7334 149.735 85 147.001 85C144.268 85 142.001 82.7334 142.001 80C142.001 46 114.335 18.3334 80.3346 18.3334C46.3346 18.3334 18.668 46 18.668 80C18.668 114 46.3346 141.667 80.3346 141.667C83.068 141.667 85.3346 143.933 85.3346 146.667C85.3346 149.4 83.068 151.667 80.3346 151.667Z" fill="#D8A942" />
                                                        <path d="M60.3366 145H53.6699C50.9366 145 48.6699 142.733 48.6699 140C48.6699 137.267 50.8032 135.067 53.5365 135C43.0699 99.2667 43.0699 60.7333 53.5365 25C50.8699 24.9333 48.6699 22.7333 48.6699 20C48.6699 17.2667 50.9366 15 53.6699 15H60.3366C61.9366 15 63.4699 15.8 64.4032 17.0667C65.3366 18.4 65.6033 20.0666 65.0699 21.5999C52.5366 59.2666 52.5366 100.8 65.0699 138.467C65.6033 140 65.3366 141.667 64.4032 143C63.4699 144.333 61.9366 145 60.3366 145Z" fill="#D8A942" />
                                                        <path d="M110.068 85.0001C107.335 85.0001 105.068 82.7335 105.068 80.0001C105.068 60.1335 101.868 40.4667 95.6013 21.6001C94.7346 19.0001 96.1346 16.1333 98.7346 15.2666C101.335 14.4 104.201 15.8002 105.068 18.4002C111.668 38.2669 115.068 59.0001 115.068 80.0001C115.068 82.7335 112.801 85.0001 110.068 85.0001Z" fill="#D8A942" />
                                                        <path d="M80.335 114.733C61.6683 114.733 43.2016 112.067 25.335 106.8C25.2683 109.467 23.0683 111.667 20.335 111.667C17.6016 111.667 15.335 109.4 15.335 106.667V100C15.335 98.4 16.1349 96.8668 17.4016 95.9334C18.7349 95.0001 20.4017 94.7332 21.935 95.2665C40.8017 101.533 60.4683 104.733 80.335 104.733C83.0683 104.733 85.335 107 85.335 109.733C85.335 112.467 83.0683 114.733 80.335 114.733Z" fill="#D8A942" />
                                                        <path d="M140.336 65C139.803 65 139.269 64.9335 138.736 64.7335C101.069 52.2002 59.5358 52.2002 21.8691 64.7335C19.2691 65.6002 16.4025 64.1999 15.5358 61.5999C14.6692 58.9999 16.0692 56.1332 18.6692 55.2665C58.4025 41.9999 102.136 41.9999 141.803 55.2665C144.403 56.1332 145.803 58.9999 144.936 61.5999C144.403 63.6666 142.403 65 140.336 65Z" fill="#D8A942" />
                                                        <path d="M121.668 147.667C107.135 147.667 95.335 135.867 95.335 121.333C95.335 106.8 107.135 95 121.668 95C136.202 95 148.002 106.8 148.002 121.333C148.002 135.867 136.202 147.667 121.668 147.667ZM121.668 105C112.668 105 105.335 112.333 105.335 121.333C105.335 130.333 112.668 137.667 121.668 137.667C130.668 137.667 138.002 130.333 138.002 121.333C138.002 112.333 130.668 105 121.668 105Z" fill="#D8A942" />
                                                        <path d="M147.002 151.666C145.735 151.666 144.468 151.2 143.468 150.2L136.802 143.533C134.868 141.6 134.868 138.4 136.802 136.466C138.735 134.533 141.935 134.533 143.868 136.466L150.535 143.133C152.468 145.066 152.468 148.267 150.535 150.2C149.535 151.2 148.268 151.666 147.002 151.666Z" fill="#D8A942" />
                                                    </svg>
                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Cherchez un trajet</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Cherchez un trajet avec les adresses de départ et de destination désirées</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 160 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.0003 58.8454C77.2669 58.8454 75.0003 56.5588 75.0003 53.8015V25.6229L70.2003 30.4651C68.2669 32.4154 65.0669 32.4154 63.1336 30.4651C61.2003 28.5148 61.2003 25.2867 63.1336 23.3364L76.4669 9.886C77.8669 8.47371 80.0669 8.00294 81.9336 8.80997C83.8003 9.54974 85.0003 11.4328 85.0003 13.4503V53.8015C85.0003 56.5588 82.7336 58.8454 80.0003 58.8454Z" fill="#D8A942" />
                                                        <path d="M93.3333 31.9447C92.0666 31.9447 90.7999 31.4739 89.7999 30.4651L76.4666 17.0148C74.5333 15.0645 74.5333 11.8364 76.4666 9.88607C78.3999 7.93576 81.5999 7.93576 83.5333 9.88607L96.8666 23.3364C98.7999 25.2868 98.7999 28.5148 96.8666 30.4651C95.8666 31.4739 94.5999 31.9447 93.3333 31.9447Z" fill="#D8A942" />
                                                        <path d="M106.667 152.998H53.3333C15 152.998 15 132.486 15 114.328V107.603C15 92.6058 15 75.6583 46.6667 75.6583C54.6 75.6583 57.5333 77.6086 61.6667 80.7022C61.8667 80.904 62.1333 81.0385 62.3333 81.3075L69.1333 88.5707C74.8667 94.6906 85.2667 94.6906 91 88.5707L97.8 81.3075C98 81.1057 98.2 80.904 98.4667 80.7022C102.6 77.5414 105.533 75.6583 113.467 75.6583C145.133 75.6583 145.133 92.6058 145.133 107.603V114.328C145 140.018 132.133 152.998 106.667 152.998ZM46.6667 85.7461C25 85.7461 25 92.6058 25 107.603V114.328C25 132.755 25 142.91 53.3333 142.91H106.667C126.533 142.91 135 134.369 135 114.328V107.603C135 92.6058 135 85.7461 113.333 85.7461C108.533 85.7461 107.533 86.3514 104.667 88.5034L98.2 95.4304C93.4 100.542 86.9333 103.366 80 103.366C73.0667 103.366 66.6 100.542 61.8 95.4304L55.3333 88.5034C52.4667 86.3514 51.4667 85.7461 46.6667 85.7461Z" fill="#D8A942" />
                                                        <path d="M33.333 85.7462C30.5997 85.7462 28.333 83.4596 28.333 80.7023V67.2519C28.333 54.205 28.333 37.9973 52.8663 35.6435C55.533 35.3073 58.0663 37.3921 58.333 40.2166C58.5997 42.974 56.5997 45.4623 53.7997 45.7313C38.333 47.1436 38.333 53.4653 38.333 67.2519V80.7023C38.333 83.4596 36.0663 85.7462 33.333 85.7462Z" fill="#D8A942" />
                                                        <path d="M126.667 85.7462C123.934 85.7462 121.667 83.4596 121.667 80.7023V67.2519C121.667 53.4653 121.667 47.1436 106.2 45.6641C103.467 45.3951 101.467 42.9067 101.734 40.1494C102 37.3921 104.4 35.3073 107.2 35.6435C131.734 37.9974 131.734 54.2051 131.734 67.2519V80.7023C131.667 83.4596 129.4 85.7462 126.667 85.7462Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Envoyez une demande</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Envoyez une demande au particulier avec les prix et les adresses désirées</p>
                                                </div> <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                            <div className='row'>
                                                <div className={`col-12  ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M109.733 85.0002C107 85.0002 104.733 82.7335 104.733 80.0002C104.733 60.1335 101.533 40.4668 95.2663 21.6001C94.3996 19.0001 95.7996 16.1334 98.3996 15.2667C101 14.4 103.866 15.8003 104.733 18.4003C111.333 38.2669 114.733 59.0002 114.733 80.0002C114.733 82.7335 112.466 85.0002 109.733 85.0002Z" fill="#D8A942" />
                                                        <path d="M80 114.733C61.3333 114.733 42.8667 112.067 25 106.8C24.9333 109.467 22.7333 111.667 20 111.667C17.2667 111.667 15 109.4 15 106.667V99.9999C15 98.3999 15.8 96.8667 17.0667 95.9334C18.4 95 20.0667 94.7331 21.6 95.2664C40.4667 101.533 60.1333 104.733 80 104.733C82.7333 104.733 85 107 85 109.733C85 112.467 82.7333 114.733 80 114.733Z" fill="#D8A942" />
                                                        <path d="M121.333 147.667C106.8 147.667 95 135.867 95 121.333C95 106.8 106.8 95 121.333 95C135.867 95 147.667 106.8 147.667 121.333C147.667 135.867 135.867 147.667 121.333 147.667ZM121.333 105C112.333 105 105 112.333 105 121.333C105 130.333 112.333 137.667 121.333 137.667C130.333 137.667 137.667 130.333 137.667 121.333C137.667 112.333 130.333 105 121.333 105Z" fill="#D8A942" />
                                                        <path d="M146.667 151.666C145.4 151.666 144.133 151.2 143.133 150.2L136.467 143.533C134.533 141.6 134.533 138.4 136.467 136.466C138.4 134.533 141.6 134.533 143.533 136.466L150.2 143.133C152.133 145.066 152.133 148.267 150.2 150.2C149.2 151.2 147.933 151.666 146.667 151.666Z" fill="#D8A942" />
                                                        <path d="M83.4004 46.4C102.067 46.4 120.534 49.0667 138.4 54.3334C138.467 51.6667 138.067 49.6001 140.8 49.6001C143.534 49.6001 145.6 51.6667 145.6 54.4001L148.4 61.1335C148.4 62.7335 147.6 64.2667 146.334 65.2001C145 66.1334 143.334 66.4003 141.8 65.867C122.934 59.6003 103.267 56.4 83.4004 56.4C80.6671 56.4 78.4004 54.1334 78.4004 51.4C78.4004 48.6667 80.6671 46.4 83.4004 46.4Z" fill="#D8A942" />
                                                        <path d="M49.8001 76.8C52.5335 76.8 54.8001 79.0667 54.8001 81.8C54.8001 101.667 58.0002 121.333 64.2669 140.2C65.1336 142.8 63.7336 145.667 61.1336 146.533C58.5336 147.4 55.6668 146 54.8001 143.4C48.2001 123.533 44.8001 102.8 44.8001 81.8C44.8001 79.0667 47.0668 76.8 49.8001 76.8Z" fill="#D8A942" />
                                                        <path d="M49.3866 68.32C41.92 68.32 34.9333 65.0667 30.1333 59.3601C30.08 59.2534 29.76 58.9866 29.4933 58.5599C28.9067 57.9733 28.2133 56.9066 27.6266 55.8933C25.28 52.1066 24 47.6266 24 42.9333C24 35.1466 27.4667 27.9468 33.4933 23.1469C37.9733 19.5735 43.6267 17.6 49.3333 17.6C55.52 17.6 61.2266 19.7334 65.8133 23.7334C66.4533 24.1601 67.2 24.9601 67.8933 25.7067C72.2133 30.4534 74.6133 36.5333 74.6133 42.8799C74.6133 47.5199 73.3333 52.0533 70.9333 55.9467C69.6533 58.1867 67.8933 60.2133 65.8666 61.92C61.5466 66.08 55.6266 68.32 49.3866 68.32ZM49.3866 25.6534C45.44 25.6534 41.7066 26.9865 38.56 29.4932C34.4533 32.7465 32.0533 37.7067 32.0533 42.9867C32.0533 46.1334 32.9066 49.2266 34.56 51.8933C34.9866 52.6399 35.4133 53.2268 35.8933 53.8135C36 53.9202 36.32 54.24 36.5867 54.6667C39.52 58.1334 44.32 60.3734 49.3866 60.3734C53.6 60.3734 57.6533 58.8268 60.7467 56.0534C62.1333 54.8801 63.3067 53.4934 64.16 52C65.8667 49.28 66.72 46.1867 66.72 43.0401C66.72 38.7201 65.0667 34.5066 62.08 31.2533C61.7067 30.8266 61.2799 30.4001 60.8533 30.0801C57.4933 27.0935 53.6 25.6534 49.3866 25.6534Z" fill="#D8A942" />
                                                        <path d="M57.3315 46.8789H41.3848C39.1981 46.8789 37.3848 45.0656 37.3848 42.8789C37.3848 40.6922 39.1981 38.8789 41.3848 38.8789H57.3315C59.5181 38.8789 61.3315 40.6922 61.3315 42.8789C61.3315 45.0656 59.5715 46.8789 57.3315 46.8789Z" fill="#D8A942" />
                                                        <path d="M49.3848 55.0388C47.1981 55.0388 45.3848 53.2254 45.3848 51.0388V35.0922C45.3848 32.9055 47.1981 31.0922 49.3848 31.0922C51.5714 31.0922 53.3848 32.9055 53.3848 35.0922V51.0388C53.3848 53.2788 51.5714 55.0388 49.3848 55.0388Z" fill="#D8A942" />
                                                        <path d="M69.5995 14.4C116.8 8.00001 145.6 43.2 146.666 80M79.9993 146.5C19.7207 144.963 4 83 17.5988 56" stroke="#D8A942" stroke-width="10" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Ajoutez votre trajet ou cherchez un colis</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>ajouter les détails de votre trajet
                                                        ou faire un offre à un colis </p>
                                                </div>
                                                <div className={`col-12  ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M112.666 126.732C110.6 126.732 108.533 126.132 106.733 124.932L100.333 120.732C98.533 119.532 97.6664 117.265 98.2664 115.199C98.733 113.665 98.933 111.865 98.933 109.865V82.7315C98.933 71.8649 92.133 65.0651 81.2664 65.0651H35.9996C35.1996 65.0651 34.4663 65.1319 33.733 65.1986C32.333 65.2652 30.9997 64.7985 29.933 63.8652C28.8664 62.9318 28.333 61.5986 28.333 60.1986V41.7318C28.333 22.1318 42.0663 8.39844 61.6663 8.39844H118.333C137.933 8.39844 151.666 22.1318 151.666 41.7318V75.7316C151.666 85.3983 148.4 93.9315 142.4 99.7982C137.6 104.665 130.933 107.798 123.333 108.732V116.132C123.333 120.132 121.133 123.732 117.666 125.599C116.066 126.332 114.333 126.732 112.666 126.732ZM108.666 114.198L113 116.665C113.4 116.465 113.4 116.132 113.4 116.065V103.998C113.4 101.265 115.666 98.9982 118.4 98.9982C125.4 98.9982 131.333 96.7985 135.4 92.6652C139.6 88.5319 141.733 82.6649 141.733 75.6649V41.665C141.733 27.465 132.6 18.3317 118.4 18.3317H61.733C47.533 18.3317 38.3996 27.465 38.3996 41.665V54.9984H81.333C97.5997 54.9984 109 66.3985 109 82.6652V109.798C108.933 111.332 108.866 112.798 108.666 114.198Z" fill="#D8A942" />
                                                        <path d="M40.4664 151.667C38.9997 151.667 37.4664 151.334 36.0664 150.6C32.933 148.934 30.9996 145.733 30.9996 142.133V137.067C25.133 136.133 19.933 133.666 16.0664 129.8C10.9997 124.733 8.33301 117.8 8.33301 109.8V82.6668C8.33301 67.6002 18.1996 56.5335 32.8663 55.1335C33.933 55.0668 34.933 55 35.9996 55H81.2664C97.533 55 108.933 66.4002 108.933 82.6668V109.8C108.933 112.733 108.6 115.467 107.866 117.933C104.866 129.933 94.6664 137.467 81.2664 137.467H64.6663L45.7996 150C44.1996 151.133 42.3331 151.667 40.4664 151.667ZM35.9996 65C35.1996 65 34.4663 65.0668 33.733 65.1335C24.133 66.0001 18.333 72.6002 18.333 82.6668V109.8C18.333 115.133 19.9997 119.6 23.133 122.733C26.1997 125.8 30.6663 127.467 35.9996 127.467C38.733 127.467 40.9996 129.733 40.9996 132.467V141.2L60.333 128.333C61.133 127.8 62.133 127.467 63.133 127.467H81.2664C90.0664 127.467 96.2663 123.067 98.1996 115.333C98.6663 113.667 98.933 111.8 98.933 109.8V82.6668C98.933 71.8002 92.133 65 81.2664 65H35.9996Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Négociez le prix</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Négociez le prix pour un colis
                                                        ou
                                                        Négociez le prix d'un colis</p>
                                                </div> <div className={`col-12  ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                            <div className='row'>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M126.668 151.668C117.335 151.668 108.601 147.602 102.601 140.468C102.535 140.335 102.135 140.002 101.801 139.468C101.068 138.735 100.201 137.402 99.4679 136.135C96.5345 131.402 94.9346 125.802 94.9346 119.935C94.9346 110.202 99.2679 101.202 106.801 95.2018C112.401 90.7352 119.468 88.2682 126.601 88.2682C134.335 88.2682 141.468 90.9351 147.201 95.9351C148.001 96.4684 148.935 97.4684 149.801 98.4017C155.201 104.335 158.201 111.935 158.201 119.868C158.201 125.668 156.601 131.335 153.601 136.202C152.001 139.002 149.801 141.535 147.268 143.668C141.868 148.868 134.468 151.668 126.668 151.668ZM126.668 98.335C121.735 98.335 117.068 100.001 113.135 103.135C108.001 107.201 105.001 113.402 105.001 120.002C105.001 123.935 106.068 127.802 108.135 131.135C108.668 132.068 109.201 132.802 109.801 133.535C109.935 133.669 110.335 134.068 110.668 134.602C114.335 138.935 120.335 141.735 126.668 141.735C131.935 141.735 137.001 139.802 140.868 136.335C142.601 134.868 144.068 133.135 145.135 131.268C147.268 127.868 148.335 124.002 148.335 120.068C148.335 114.668 146.268 109.402 142.535 105.335C142.068 104.802 141.534 104.268 141.001 103.868C136.801 100.135 131.935 98.335 126.668 98.335Z" fill="#D8A942" />
                                                        <path d="M136.6 124.867H116.667C113.934 124.867 111.667 122.6 111.667 119.867C111.667 117.133 113.934 114.867 116.667 114.867H136.6C139.334 114.867 141.6 117.133 141.6 119.867C141.6 122.6 139.4 124.867 136.6 124.867Z" fill="#D8A942" />
                                                        <path d="M126.667 135.067C123.934 135.067 121.667 132.8 121.667 130.067V110.133C121.667 107.4 123.934 105.133 126.667 105.133C129.4 105.133 131.667 107.4 131.667 110.133V130.067C131.667 132.867 129.4 135.067 126.667 135.067Z" fill="#D8A942" />
                                                        <path d="M79.9992 88.6658C79.1326 88.6658 78.2659 88.4659 77.4659 87.9993L18.5992 53.9327C16.1992 52.5327 15.3993 49.4658 16.7993 47.0658C18.1993 44.6658 21.2659 43.8657 23.5992 45.2657L79.9326 77.8658L135.933 45.4659C138.333 44.0659 141.399 44.9327 142.733 47.266C144.133 49.666 143.266 52.7325 140.933 54.1325L82.4659 87.9993C81.7325 88.3993 80.8659 88.6658 79.9992 88.6658Z" fill="#D8A942" />
                                                        <path d="M80 149.067C77.2667 149.067 75 146.8 75 144.067V83.6003C75 80.8669 77.2667 78.6003 80 78.6003C82.7333 78.6003 85 80.8669 85 83.6003V144.067C85 146.8 82.7333 149.067 80 149.067Z" fill="#D8A942" />
                                                        <path d="M79.9995 151.666C74.1328 151.666 68.2662 150.399 63.7328 147.799L28.1329 128.066C18.4662 122.733 10.8662 109.866 10.8662 98.7995V61.066C10.8662 49.9993 18.4662 37.1996 28.1329 31.7996L63.7328 12.0661C72.7995 6.93279 87.0661 6.93279 96.1994 12.0661L131.8 31.7996C141.466 37.1329 149.066 49.9993 149.066 61.066V98.7995C149.066 99.4662 149.066 99.9993 148.933 100.666C148.599 102.399 147.333 103.866 145.666 104.399C144 104.999 142.133 104.6 140.733 103.466C133.066 96.7996 121.2 96.5329 113.133 103C108 107.066 104.999 113.266 104.999 119.866C104.999 123.799 106.066 127.666 108.133 131C108.666 131.933 109.2 132.666 109.8 133.4C110.799 134.533 111.199 136.066 110.933 137.533C110.666 138.999 109.733 140.266 108.399 141L96.1994 147.733C91.6661 150.399 85.8662 151.666 79.9995 151.666ZM79.9995 18.3328C75.8662 18.3328 71.6662 19.1995 68.6662 20.8662L33.0662 40.5997C26.5996 44.133 20.9995 53.7326 20.9995 61.066V98.7995C20.9995 106.133 26.6662 115.733 33.0662 119.266L68.6662 138.999C74.7329 142.399 85.3329 142.399 91.3995 138.999L98.8662 134.866C96.3995 130.399 95.0661 125.199 95.0661 119.866C95.0661 110.133 99.3995 101.133 106.933 95.1329C115.999 87.8663 128.999 86.3326 139.133 90.666V60.9329C139.133 53.5996 133.466 43.9995 127.066 40.4662L91.4662 20.7327C88.3328 19.1994 84.1328 18.3328 79.9995 18.3328Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Ajoutez votre colis</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Saisissez les données de votre colis ainsi que les adresses de départ et de destination</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.6647 152.067C76.0647 152.067 71.7314 149.733 68.6647 145.667L58.6647 132.333C58.4647 132.067 57.6647 131.667 57.2647 131.667H53.998C26.198 131.667 8.99805 124.133 8.99805 86.6666V53.3333C8.99805 23.8666 24.5314 8.33331 53.998 8.33331H93.998C96.7314 8.33331 98.998 10.6 98.998 13.3333C98.998 16.0666 96.7314 18.3333 93.998 18.3333H53.998C30.1314 18.3333 18.998 29.4666 18.998 53.3333V86.6666C18.998 116.8 29.3314 121.667 53.998 121.667H57.3314C60.7314 121.667 64.598 123.6 66.6647 126.333L76.6647 139.667C78.998 142.733 82.3314 142.733 84.6647 139.667L94.6647 126.333C96.8647 123.4 100.331 121.667 103.998 121.667H107.331C131.198 121.667 142.331 110.533 142.331 86.6666V66.6666C142.331 63.9333 144.598 61.6666 147.331 61.6666C150.065 61.6666 152.331 63.9333 152.331 66.6666V86.6666C152.331 116.133 136.798 131.667 107.331 131.667H103.998C103.465 131.667 102.998 131.933 102.665 132.333L92.6647 145.667C89.598 149.733 85.2647 152.067 80.6647 152.067Z" fill="#D8A942" />
                                                        <path d="M80.6647 80C76.9314 80 73.998 77 73.998 73.3334C73.998 69.6667 76.998 66.6667 80.6647 66.6667C84.3314 66.6667 87.3314 69.6667 87.3314 73.3334C87.3314 77 84.398 80 80.6647 80Z" fill="#D8A942" />
                                                        <path d="M107.332 80C103.598 80 100.665 77 100.665 73.3334C100.665 69.6667 103.665 66.6667 107.332 66.6667C110.998 66.6667 113.998 69.6667 113.998 73.3334C113.998 77 111.065 80 107.332 80Z" fill="#D8A942" />
                                                        <path d="M53.9987 80C50.2654 80 47.332 77 47.332 73.3334C47.332 69.6667 50.332 66.6667 53.9987 66.6667C57.6654 66.6667 60.6654 69.6667 60.6654 73.3334C60.6654 77 57.732 80 53.9987 80Z" fill="#D8A942" />
                                                        <path d="M130.665 51.6666C118.731 51.6666 108.998 41.9333 108.998 30C108.998 18.0666 118.731 8.33331 130.665 8.33331C142.598 8.33331 152.331 18.0666 152.331 30C152.331 41.9333 142.598 51.6666 130.665 51.6666ZM130.665 18.3333C124.265 18.3333 118.998 23.6 118.998 30C118.998 36.4 124.265 41.6666 130.665 41.6666C137.065 41.6666 142.331 36.4 142.331 30C142.331 23.6 137.065 18.3333 130.665 18.3333Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Acceptez une offre</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Consultez les offres reçues et acceptez une offre</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="70" height="70" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div style={{ backgroundColor: "#D8A842" }} className={`text-light mb-0 ${classes.ccmccc} d-none d-lg-block my-5`}>{/************************************** */}
                        <div className={`d-flex align-items-start ${classes.ccmcc} `}>
                            <div className={classes.imgContainer} data-bs-toggle="collapse" data-bs-target="#ccmcCollapse" aria-expanded="false" aria-controls="collapseWidthExample">
                                <h2 className={`text-light text-center p-4 position-absolute z-2 mb-0 position-absolute top-50 end-0 translate-middle-y ${classes.collapseHeading}`}>
                                    {t('hiw')}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="57" viewBox="0 0 49 57" className={`ms-2 ${classes.arrowIcon} `}>
                                        <g id="Polygone_2" data-name="Polygone 2" transform="translate(49) rotate(90)" fill="none">
                                            <path d="M28.5,0,57,49H0Z" stroke="none" />
                                            <path d="M 28.5 5.966888427734375 L 5.215435028076172 46 L 51.78456497192383 46 L 28.5 5.966888427734375 M 28.5 0 L 57 49 L 0 49 L 28.5 0 Z" stroke="none" fill="#fff" />
                                        </g>
                                    </svg>
                                </h2>
                                <img src={ccmc} className={classes.ccmcImg} alt="..." />
                                <div className={classes.filter}></div>
                            </div>
                            <div className={`collapse collapse-horizontal`} id="ccmcCollapse" style={{ backgroundColor: "white" }} >
                                <div className={`d-flex flex-column  flex-lg-row align-items-center container-md h-100 p-0  ${classes.ccmccCollapse}`} >
                                    <div className="col-12 col-lg-3 nav me-3 align-self-stretch d-flex flex-column justify-content-center align-items-stretch" id="v-pills-tab" aria-orientation="vertical">
                                        <div className={`${classes.ccbutton1} ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#addPackage" aria-controls="v-pills-home" aria-selected="true" style={{ color: "white" }}>
                                                {t('hiw1')}
                                            </a>
                                        </div>
                                        <div className={`${classes.ccbutton2} ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#demandeCarrier" role="tab" aria-controls="v-pills-profile" aria-selected="false" style={{ color: "white" }}>
                                                {t('hiw2')}
                                            </a>
                                        </div>
                                        <div className={`${classes.ccbutton3}  ${classes.ccbutton} col-12 d-flex`}>
                                            <a className="h-100  btn d-flex align-items-center justify-content-center justify-content-lg-start border-0 rounded-0 fw-bold" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#deliverPackage" role="tab" aria-controls="v-pills-messages" aria-selected="false" style={{ color: "white" }}>
                                                {t('hiw3')}
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`tab-content p-0 col-12 col-md-8 col-lg-9 h-100`} id="v-pills-tabContent" >
                                        <div className="tab-pane show active" id="addPackage" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                            <div className='row p-0 m-0 '>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.3346 151.667C40.8013 151.667 8.66797 119.533 8.66797 80C8.66797 40.4667 40.8013 8.33337 80.3346 8.33337C119.868 8.33337 152.001 40.4667 152.001 80C152.001 82.7334 149.735 85 147.001 85C144.268 85 142.001 82.7334 142.001 80C142.001 46 114.335 18.3334 80.3346 18.3334C46.3346 18.3334 18.668 46 18.668 80C18.668 114 46.3346 141.667 80.3346 141.667C83.068 141.667 85.3346 143.933 85.3346 146.667C85.3346 149.4 83.068 151.667 80.3346 151.667Z" fill="#D8A942" />
                                                        <path d="M60.3366 145H53.6699C50.9366 145 48.6699 142.733 48.6699 140C48.6699 137.267 50.8032 135.067 53.5365 135C43.0699 99.2667 43.0699 60.7333 53.5365 25C50.8699 24.9333 48.6699 22.7333 48.6699 20C48.6699 17.2667 50.9366 15 53.6699 15H60.3366C61.9366 15 63.4699 15.8 64.4032 17.0667C65.3366 18.4 65.6033 20.0666 65.0699 21.5999C52.5366 59.2666 52.5366 100.8 65.0699 138.467C65.6033 140 65.3366 141.667 64.4032 143C63.4699 144.333 61.9366 145 60.3366 145Z" fill="#D8A942" />
                                                        <path d="M110.068 85.0001C107.335 85.0001 105.068 82.7335 105.068 80.0001C105.068 60.1335 101.868 40.4667 95.6013 21.6001C94.7346 19.0001 96.1346 16.1333 98.7346 15.2666C101.335 14.4 104.201 15.8002 105.068 18.4002C111.668 38.2669 115.068 59.0001 115.068 80.0001C115.068 82.7335 112.801 85.0001 110.068 85.0001Z" fill="#D8A942" />
                                                        <path d="M80.335 114.733C61.6683 114.733 43.2016 112.067 25.335 106.8C25.2683 109.467 23.0683 111.667 20.335 111.667C17.6016 111.667 15.335 109.4 15.335 106.667V100C15.335 98.4 16.1349 96.8668 17.4016 95.9334C18.7349 95.0001 20.4017 94.7332 21.935 95.2665C40.8017 101.533 60.4683 104.733 80.335 104.733C83.0683 104.733 85.335 107 85.335 109.733C85.335 112.467 83.0683 114.733 80.335 114.733Z" fill="#D8A942" />
                                                        <path d="M140.336 65C139.803 65 139.269 64.9335 138.736 64.7335C101.069 52.2002 59.5358 52.2002 21.8691 64.7335C19.2691 65.6002 16.4025 64.1999 15.5358 61.5999C14.6692 58.9999 16.0692 56.1332 18.6692 55.2665C58.4025 41.9999 102.136 41.9999 141.803 55.2665C144.403 56.1332 145.803 58.9999 144.936 61.5999C144.403 63.6666 142.403 65 140.336 65Z" fill="#D8A942" />
                                                        <path d="M121.668 147.667C107.135 147.667 95.335 135.867 95.335 121.333C95.335 106.8 107.135 95 121.668 95C136.202 95 148.002 106.8 148.002 121.333C148.002 135.867 136.202 147.667 121.668 147.667ZM121.668 105C112.668 105 105.335 112.333 105.335 121.333C105.335 130.333 112.668 137.667 121.668 137.667C130.668 137.667 138.002 130.333 138.002 121.333C138.002 112.333 130.668 105 121.668 105Z" fill="#D8A942" />
                                                        <path d="M147.002 151.666C145.735 151.666 144.468 151.2 143.468 150.2L136.802 143.533C134.868 141.6 134.868 138.4 136.802 136.466C138.735 134.533 141.935 134.533 143.868 136.466L150.535 143.133C152.468 145.066 152.468 148.267 150.535 150.2C149.535 151.2 148.268 151.666 147.002 151.666Z" fill="#D8A942" />
                                                    </svg>
                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Cherchez un trajet</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Cherchez un trajet avec les adresses de départ et de destination désirées</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 160 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.0003 58.8454C77.2669 58.8454 75.0003 56.5588 75.0003 53.8015V25.6229L70.2003 30.4651C68.2669 32.4154 65.0669 32.4154 63.1336 30.4651C61.2003 28.5148 61.2003 25.2867 63.1336 23.3364L76.4669 9.886C77.8669 8.47371 80.0669 8.00294 81.9336 8.80997C83.8003 9.54974 85.0003 11.4328 85.0003 13.4503V53.8015C85.0003 56.5588 82.7336 58.8454 80.0003 58.8454Z" fill="#D8A942" />
                                                        <path d="M93.3333 31.9447C92.0666 31.9447 90.7999 31.4739 89.7999 30.4651L76.4666 17.0148C74.5333 15.0645 74.5333 11.8364 76.4666 9.88607C78.3999 7.93576 81.5999 7.93576 83.5333 9.88607L96.8666 23.3364C98.7999 25.2868 98.7999 28.5148 96.8666 30.4651C95.8666 31.4739 94.5999 31.9447 93.3333 31.9447Z" fill="#D8A942" />
                                                        <path d="M106.667 152.998H53.3333C15 152.998 15 132.486 15 114.328V107.603C15 92.6058 15 75.6583 46.6667 75.6583C54.6 75.6583 57.5333 77.6086 61.6667 80.7022C61.8667 80.904 62.1333 81.0385 62.3333 81.3075L69.1333 88.5707C74.8667 94.6906 85.2667 94.6906 91 88.5707L97.8 81.3075C98 81.1057 98.2 80.904 98.4667 80.7022C102.6 77.5414 105.533 75.6583 113.467 75.6583C145.133 75.6583 145.133 92.6058 145.133 107.603V114.328C145 140.018 132.133 152.998 106.667 152.998ZM46.6667 85.7461C25 85.7461 25 92.6058 25 107.603V114.328C25 132.755 25 142.91 53.3333 142.91H106.667C126.533 142.91 135 134.369 135 114.328V107.603C135 92.6058 135 85.7461 113.333 85.7461C108.533 85.7461 107.533 86.3514 104.667 88.5034L98.2 95.4304C93.4 100.542 86.9333 103.366 80 103.366C73.0667 103.366 66.6 100.542 61.8 95.4304L55.3333 88.5034C52.4667 86.3514 51.4667 85.7461 46.6667 85.7461Z" fill="#D8A942" />
                                                        <path d="M33.333 85.7462C30.5997 85.7462 28.333 83.4596 28.333 80.7023V67.2519C28.333 54.205 28.333 37.9973 52.8663 35.6435C55.533 35.3073 58.0663 37.3921 58.333 40.2166C58.5997 42.974 56.5997 45.4623 53.7997 45.7313C38.333 47.1436 38.333 53.4653 38.333 67.2519V80.7023C38.333 83.4596 36.0663 85.7462 33.333 85.7462Z" fill="#D8A942" />
                                                        <path d="M126.667 85.7462C123.934 85.7462 121.667 83.4596 121.667 80.7023V67.2519C121.667 53.4653 121.667 47.1436 106.2 45.6641C103.467 45.3951 101.467 42.9067 101.734 40.1494C102 37.3921 104.4 35.3073 107.2 35.6435C131.734 37.9974 131.734 54.2051 131.734 67.2519V80.7023C131.667 83.4596 129.4 85.7462 126.667 85.7462Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Envoyez une demande</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Envoyez une demande au particulier avec les prix et les adresses désirées</p>
                                                </div> <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="demandeCarrier" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                            <div className='row p-0 m-0'>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M109.733 85.0002C107 85.0002 104.733 82.7335 104.733 80.0002C104.733 60.1335 101.533 40.4668 95.2663 21.6001C94.3996 19.0001 95.7996 16.1334 98.3996 15.2667C101 14.4 103.866 15.8003 104.733 18.4003C111.333 38.2669 114.733 59.0002 114.733 80.0002C114.733 82.7335 112.466 85.0002 109.733 85.0002Z" fill="#D8A942" />
                                                        <path d="M80 114.733C61.3333 114.733 42.8667 112.067 25 106.8C24.9333 109.467 22.7333 111.667 20 111.667C17.2667 111.667 15 109.4 15 106.667V99.9999C15 98.3999 15.8 96.8667 17.0667 95.9334C18.4 95 20.0667 94.7331 21.6 95.2664C40.4667 101.533 60.1333 104.733 80 104.733C82.7333 104.733 85 107 85 109.733C85 112.467 82.7333 114.733 80 114.733Z" fill="#D8A942" />
                                                        <path d="M121.333 147.667C106.8 147.667 95 135.867 95 121.333C95 106.8 106.8 95 121.333 95C135.867 95 147.667 106.8 147.667 121.333C147.667 135.867 135.867 147.667 121.333 147.667ZM121.333 105C112.333 105 105 112.333 105 121.333C105 130.333 112.333 137.667 121.333 137.667C130.333 137.667 137.667 130.333 137.667 121.333C137.667 112.333 130.333 105 121.333 105Z" fill="#D8A942" />
                                                        <path d="M146.667 151.666C145.4 151.666 144.133 151.2 143.133 150.2L136.467 143.533C134.533 141.6 134.533 138.4 136.467 136.466C138.4 134.533 141.6 134.533 143.533 136.466L150.2 143.133C152.133 145.066 152.133 148.267 150.2 150.2C149.2 151.2 147.933 151.666 146.667 151.666Z" fill="#D8A942" />
                                                        <path d="M83.4004 46.4C102.067 46.4 120.534 49.0667 138.4 54.3334C138.467 51.6667 138.067 49.6001 140.8 49.6001C143.534 49.6001 145.6 51.6667 145.6 54.4001L148.4 61.1335C148.4 62.7335 147.6 64.2667 146.334 65.2001C145 66.1334 143.334 66.4003 141.8 65.867C122.934 59.6003 103.267 56.4 83.4004 56.4C80.6671 56.4 78.4004 54.1334 78.4004 51.4C78.4004 48.6667 80.6671 46.4 83.4004 46.4Z" fill="#D8A942" />
                                                        <path d="M49.8001 76.8C52.5335 76.8 54.8001 79.0667 54.8001 81.8C54.8001 101.667 58.0002 121.333 64.2669 140.2C65.1336 142.8 63.7336 145.667 61.1336 146.533C58.5336 147.4 55.6668 146 54.8001 143.4C48.2001 123.533 44.8001 102.8 44.8001 81.8C44.8001 79.0667 47.0668 76.8 49.8001 76.8Z" fill="#D8A942" />
                                                        <path d="M49.3866 68.32C41.92 68.32 34.9333 65.0667 30.1333 59.3601C30.08 59.2534 29.76 58.9866 29.4933 58.5599C28.9067 57.9733 28.2133 56.9066 27.6266 55.8933C25.28 52.1066 24 47.6266 24 42.9333C24 35.1466 27.4667 27.9468 33.4933 23.1469C37.9733 19.5735 43.6267 17.6 49.3333 17.6C55.52 17.6 61.2266 19.7334 65.8133 23.7334C66.4533 24.1601 67.2 24.9601 67.8933 25.7067C72.2133 30.4534 74.6133 36.5333 74.6133 42.8799C74.6133 47.5199 73.3333 52.0533 70.9333 55.9467C69.6533 58.1867 67.8933 60.2133 65.8666 61.92C61.5466 66.08 55.6266 68.32 49.3866 68.32ZM49.3866 25.6534C45.44 25.6534 41.7066 26.9865 38.56 29.4932C34.4533 32.7465 32.0533 37.7067 32.0533 42.9867C32.0533 46.1334 32.9066 49.2266 34.56 51.8933C34.9866 52.6399 35.4133 53.2268 35.8933 53.8135C36 53.9202 36.32 54.24 36.5867 54.6667C39.52 58.1334 44.32 60.3734 49.3866 60.3734C53.6 60.3734 57.6533 58.8268 60.7467 56.0534C62.1333 54.8801 63.3067 53.4934 64.16 52C65.8667 49.28 66.72 46.1867 66.72 43.0401C66.72 38.7201 65.0667 34.5066 62.08 31.2533C61.7067 30.8266 61.2799 30.4001 60.8533 30.0801C57.4933 27.0935 53.6 25.6534 49.3866 25.6534Z" fill="#D8A942" />
                                                        <path d="M57.3315 46.8789H41.3848C39.1981 46.8789 37.3848 45.0656 37.3848 42.8789C37.3848 40.6922 39.1981 38.8789 41.3848 38.8789H57.3315C59.5181 38.8789 61.3315 40.6922 61.3315 42.8789C61.3315 45.0656 59.5715 46.8789 57.3315 46.8789Z" fill="#D8A942" />
                                                        <path d="M49.3848 55.0388C47.1981 55.0388 45.3848 53.2254 45.3848 51.0388V35.0922C45.3848 32.9055 47.1981 31.0922 49.3848 31.0922C51.5714 31.0922 53.3848 32.9055 53.3848 35.0922V51.0388C53.3848 53.2788 51.5714 55.0388 49.3848 55.0388Z" fill="#D8A942" />
                                                        <path d="M69.5995 14.4C116.8 8.00001 145.6 43.2 146.666 80M79.9993 146.5C19.7207 144.963 4 83 17.5988 56" stroke="#D8A942" stroke-width="10" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Ajoutez votre trajet ou cherchez un colis</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>ajouter les détails de votre trajet
                                                        ou faire un offre à un colis </p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M112.666 126.732C110.6 126.732 108.533 126.132 106.733 124.932L100.333 120.732C98.533 119.532 97.6664 117.265 98.2664 115.199C98.733 113.665 98.933 111.865 98.933 109.865V82.7315C98.933 71.8649 92.133 65.0651 81.2664 65.0651H35.9996C35.1996 65.0651 34.4663 65.1319 33.733 65.1986C32.333 65.2652 30.9997 64.7985 29.933 63.8652C28.8664 62.9318 28.333 61.5986 28.333 60.1986V41.7318C28.333 22.1318 42.0663 8.39844 61.6663 8.39844H118.333C137.933 8.39844 151.666 22.1318 151.666 41.7318V75.7316C151.666 85.3983 148.4 93.9315 142.4 99.7982C137.6 104.665 130.933 107.798 123.333 108.732V116.132C123.333 120.132 121.133 123.732 117.666 125.599C116.066 126.332 114.333 126.732 112.666 126.732ZM108.666 114.198L113 116.665C113.4 116.465 113.4 116.132 113.4 116.065V103.998C113.4 101.265 115.666 98.9982 118.4 98.9982C125.4 98.9982 131.333 96.7985 135.4 92.6652C139.6 88.5319 141.733 82.6649 141.733 75.6649V41.665C141.733 27.465 132.6 18.3317 118.4 18.3317H61.733C47.533 18.3317 38.3996 27.465 38.3996 41.665V54.9984H81.333C97.5997 54.9984 109 66.3985 109 82.6652V109.798C108.933 111.332 108.866 112.798 108.666 114.198Z" fill="#D8A942" />
                                                        <path d="M40.4664 151.667C38.9997 151.667 37.4664 151.334 36.0664 150.6C32.933 148.934 30.9996 145.733 30.9996 142.133V137.067C25.133 136.133 19.933 133.666 16.0664 129.8C10.9997 124.733 8.33301 117.8 8.33301 109.8V82.6668C8.33301 67.6002 18.1996 56.5335 32.8663 55.1335C33.933 55.0668 34.933 55 35.9996 55H81.2664C97.533 55 108.933 66.4002 108.933 82.6668V109.8C108.933 112.733 108.6 115.467 107.866 117.933C104.866 129.933 94.6664 137.467 81.2664 137.467H64.6663L45.7996 150C44.1996 151.133 42.3331 151.667 40.4664 151.667ZM35.9996 65C35.1996 65 34.4663 65.0668 33.733 65.1335C24.133 66.0001 18.333 72.6002 18.333 82.6668V109.8C18.333 115.133 19.9997 119.6 23.133 122.733C26.1997 125.8 30.6663 127.467 35.9996 127.467C38.733 127.467 40.9996 129.733 40.9996 132.467V141.2L60.333 128.333C61.133 127.8 62.133 127.467 63.133 127.467H81.2664C90.0664 127.467 96.2663 123.067 98.1996 115.333C98.6663 113.667 98.933 111.8 98.933 109.8V82.6668C98.933 71.8002 92.133 65 81.2664 65H35.9996Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Négociez le prix</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Négociez le prix pour un colis
                                                        ou
                                                        Négociez le prix d'un colis</p>
                                                </div> <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="deliverPackage" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                            <div className='row p-0 m-0'>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M126.668 151.668C117.335 151.668 108.601 147.602 102.601 140.468C102.535 140.335 102.135 140.002 101.801 139.468C101.068 138.735 100.201 137.402 99.4679 136.135C96.5345 131.402 94.9346 125.802 94.9346 119.935C94.9346 110.202 99.2679 101.202 106.801 95.2018C112.401 90.7352 119.468 88.2682 126.601 88.2682C134.335 88.2682 141.468 90.9351 147.201 95.9351C148.001 96.4684 148.935 97.4684 149.801 98.4017C155.201 104.335 158.201 111.935 158.201 119.868C158.201 125.668 156.601 131.335 153.601 136.202C152.001 139.002 149.801 141.535 147.268 143.668C141.868 148.868 134.468 151.668 126.668 151.668ZM126.668 98.335C121.735 98.335 117.068 100.001 113.135 103.135C108.001 107.201 105.001 113.402 105.001 120.002C105.001 123.935 106.068 127.802 108.135 131.135C108.668 132.068 109.201 132.802 109.801 133.535C109.935 133.669 110.335 134.068 110.668 134.602C114.335 138.935 120.335 141.735 126.668 141.735C131.935 141.735 137.001 139.802 140.868 136.335C142.601 134.868 144.068 133.135 145.135 131.268C147.268 127.868 148.335 124.002 148.335 120.068C148.335 114.668 146.268 109.402 142.535 105.335C142.068 104.802 141.534 104.268 141.001 103.868C136.801 100.135 131.935 98.335 126.668 98.335Z" fill="#D8A942" />
                                                        <path d="M136.6 124.867H116.667C113.934 124.867 111.667 122.6 111.667 119.867C111.667 117.133 113.934 114.867 116.667 114.867H136.6C139.334 114.867 141.6 117.133 141.6 119.867C141.6 122.6 139.4 124.867 136.6 124.867Z" fill="#D8A942" />
                                                        <path d="M126.667 135.067C123.934 135.067 121.667 132.8 121.667 130.067V110.133C121.667 107.4 123.934 105.133 126.667 105.133C129.4 105.133 131.667 107.4 131.667 110.133V130.067C131.667 132.867 129.4 135.067 126.667 135.067Z" fill="#D8A942" />
                                                        <path d="M79.9992 88.6658C79.1326 88.6658 78.2659 88.4659 77.4659 87.9993L18.5992 53.9327C16.1992 52.5327 15.3993 49.4658 16.7993 47.0658C18.1993 44.6658 21.2659 43.8657 23.5992 45.2657L79.9326 77.8658L135.933 45.4659C138.333 44.0659 141.399 44.9327 142.733 47.266C144.133 49.666 143.266 52.7325 140.933 54.1325L82.4659 87.9993C81.7325 88.3993 80.8659 88.6658 79.9992 88.6658Z" fill="#D8A942" />
                                                        <path d="M80 149.067C77.2667 149.067 75 146.8 75 144.067V83.6003C75 80.8669 77.2667 78.6003 80 78.6003C82.7333 78.6003 85 80.8669 85 83.6003V144.067C85 146.8 82.7333 149.067 80 149.067Z" fill="#D8A942" />
                                                        <path d="M79.9995 151.666C74.1328 151.666 68.2662 150.399 63.7328 147.799L28.1329 128.066C18.4662 122.733 10.8662 109.866 10.8662 98.7995V61.066C10.8662 49.9993 18.4662 37.1996 28.1329 31.7996L63.7328 12.0661C72.7995 6.93279 87.0661 6.93279 96.1994 12.0661L131.8 31.7996C141.466 37.1329 149.066 49.9993 149.066 61.066V98.7995C149.066 99.4662 149.066 99.9993 148.933 100.666C148.599 102.399 147.333 103.866 145.666 104.399C144 104.999 142.133 104.6 140.733 103.466C133.066 96.7996 121.2 96.5329 113.133 103C108 107.066 104.999 113.266 104.999 119.866C104.999 123.799 106.066 127.666 108.133 131C108.666 131.933 109.2 132.666 109.8 133.4C110.799 134.533 111.199 136.066 110.933 137.533C110.666 138.999 109.733 140.266 108.399 141L96.1994 147.733C91.6661 150.399 85.8662 151.666 79.9995 151.666ZM79.9995 18.3328C75.8662 18.3328 71.6662 19.1995 68.6662 20.8662L33.0662 40.5997C26.5996 44.133 20.9995 53.7326 20.9995 61.066V98.7995C20.9995 106.133 26.6662 115.733 33.0662 119.266L68.6662 138.999C74.7329 142.399 85.3329 142.399 91.3995 138.999L98.8662 134.866C96.3995 130.399 95.0661 125.199 95.0661 119.866C95.0661 110.133 99.3995 101.133 106.933 95.1329C115.999 87.8663 128.999 86.3326 139.133 90.666V60.9329C139.133 53.5996 133.466 43.9995 127.066 40.4662L91.4662 20.7327C88.3328 19.1994 84.1328 18.3328 79.9995 18.3328Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Ajoutez votre colis</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Saisissez les données de votre colis ainsi que les adresses de départ et de destination</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M80.6647 152.067C76.0647 152.067 71.7314 149.733 68.6647 145.667L58.6647 132.333C58.4647 132.067 57.6647 131.667 57.2647 131.667H53.998C26.198 131.667 8.99805 124.133 8.99805 86.6666V53.3333C8.99805 23.8666 24.5314 8.33331 53.998 8.33331H93.998C96.7314 8.33331 98.998 10.6 98.998 13.3333C98.998 16.0666 96.7314 18.3333 93.998 18.3333H53.998C30.1314 18.3333 18.998 29.4666 18.998 53.3333V86.6666C18.998 116.8 29.3314 121.667 53.998 121.667H57.3314C60.7314 121.667 64.598 123.6 66.6647 126.333L76.6647 139.667C78.998 142.733 82.3314 142.733 84.6647 139.667L94.6647 126.333C96.8647 123.4 100.331 121.667 103.998 121.667H107.331C131.198 121.667 142.331 110.533 142.331 86.6666V66.6666C142.331 63.9333 144.598 61.6666 147.331 61.6666C150.065 61.6666 152.331 63.9333 152.331 66.6666V86.6666C152.331 116.133 136.798 131.667 107.331 131.667H103.998C103.465 131.667 102.998 131.933 102.665 132.333L92.6647 145.667C89.598 149.733 85.2647 152.067 80.6647 152.067Z" fill="#D8A942" />
                                                        <path d="M80.6647 80C76.9314 80 73.998 77 73.998 73.3334C73.998 69.6667 76.998 66.6667 80.6647 66.6667C84.3314 66.6667 87.3314 69.6667 87.3314 73.3334C87.3314 77 84.398 80 80.6647 80Z" fill="#D8A942" />
                                                        <path d="M107.332 80C103.598 80 100.665 77 100.665 73.3334C100.665 69.6667 103.665 66.6667 107.332 66.6667C110.998 66.6667 113.998 69.6667 113.998 73.3334C113.998 77 111.065 80 107.332 80Z" fill="#D8A942" />
                                                        <path d="M53.9987 80C50.2654 80 47.332 77 47.332 73.3334C47.332 69.6667 50.332 66.6667 53.9987 66.6667C57.6654 66.6667 60.6654 69.6667 60.6654 73.3334C60.6654 77 57.732 80 53.9987 80Z" fill="#D8A942" />
                                                        <path d="M130.665 51.6666C118.731 51.6666 108.998 41.9333 108.998 30C108.998 18.0666 118.731 8.33331 130.665 8.33331C142.598 8.33331 152.331 18.0666 152.331 30C152.331 41.9333 142.598 51.6666 130.665 51.6666ZM130.665 18.3333C124.265 18.3333 118.998 23.6 118.998 30C118.998 36.4 124.265 41.6666 130.665 41.6666C137.065 41.6666 142.331 36.4 142.331 30C142.331 23.6 137.065 18.3333 130.665 18.3333Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Acceptez une offre</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Consultez les offres reçues et acceptez une offre</p>
                                                </div>
                                                <div className={`col-12 col-lg-4 ${classes.ccmc}`}>
                                                    <svg width="100" height="100" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M87.6195 98.3333H14.2861C11.5528 98.3333 9.28613 96.0666 9.28613 93.3333V50.7999C9.28613 48.6666 10.6194 46.7998 12.5527 46.0664C14.5527 45.3331 16.7528 45.9333 18.0862 47.5333C22.1528 52.4 28.4862 55.1332 34.7528 54.9332C40.0862 54.7999 45.0193 52.8001 48.7527 49.2667C50.486 47.8001 51.886 46.0666 52.9527 44.1333C55.0194 40.5999 56.0195 36.7332 55.9528 32.7998C55.8195 26.6665 53.153 21.0665 48.6196 16.9999C47.0196 15.5999 46.4861 13.3998 47.2195 11.4664C47.9528 9.5331 49.8196 8.19983 51.8862 8.19983H100.953C103.686 8.19983 105.953 10.4665 105.953 13.1998V79.8665C105.953 90.1332 97.7528 98.3333 87.6195 98.3333ZM19.2861 88.3333H87.6195C92.2195 88.3333 95.9528 84.6 95.9528 80V18.3333H62.2195C64.5528 22.6666 65.8195 27.5334 65.9528 32.6001C66.0861 38.4001 64.6194 44.1333 61.686 49.1333C60.086 51.9999 57.8861 54.7334 55.4861 56.7334C50.1527 61.8 42.8194 64.8 35.0194 65C29.4194 65.2 24.0195 63.8 19.3529 61.2666V88.3333H19.2861Z" fill="#D8A942" />
                                                        <path d="M127.619 138.333H120.953C118.219 138.333 115.953 136.067 115.953 133.333C115.953 128.733 112.219 125 107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 136.067 97.0195 138.333 94.2861 138.333H67.6195C64.8861 138.333 62.6195 136.067 62.6195 133.333C62.6195 128.733 58.8861 125 54.2861 125C49.6861 125 45.9528 128.733 45.9528 133.333C45.9528 136.067 43.6861 138.333 40.9528 138.333H34.2861C20.4861 138.333 9.28613 127.133 9.28613 113.333V93.3334C9.28613 90.6 11.5528 88.3334 14.2861 88.3334H87.6195C92.2195 88.3334 95.9528 84.6 95.9528 80V33.3334C95.9528 30.6 98.2195 28.3334 100.953 28.3334H113.22C119.82 28.3334 125.886 31.8668 129.153 37.6001L140.553 57.5334C141.419 59.0667 141.419 61.0001 140.553 62.5334C139.686 64.0667 138.019 65 136.219 65H127.619C126.686 65 125.953 65.7334 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3334 127.619 88.3334H147.619C150.353 88.3334 152.619 90.6 152.619 93.3334V113.333C152.619 127.133 141.419 138.333 127.619 138.333ZM125.286 128.333H127.619C135.886 128.333 142.619 121.6 142.619 113.333V98.3334H127.619C121.219 98.3334 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.153 55 127.619 55L120.486 42.5334C119.019 39.9334 116.22 38.3334 113.22 38.3334H105.953V80C105.953 90.1334 97.7528 98.3334 87.6195 98.3334H19.2861V113.333C19.2861 121.6 26.0195 128.333 34.2861 128.333H36.6193C38.8193 120.667 45.8861 115 54.2861 115C62.6861 115 69.753 120.667 71.953 128.333H90.0194C92.2194 120.667 99.2862 115 107.686 115C116.086 115 123.086 120.667 125.286 128.333Z" fill="#D8A942" />
                                                        <path d="M54.2865 151.667C44.1531 151.667 35.9531 143.467 35.9531 133.333C35.9531 123.2 44.1531 115 54.2865 115C64.4198 115 72.6198 123.2 72.6198 133.333C72.6198 143.467 64.4198 151.667 54.2865 151.667ZM54.2865 125C49.6865 125 45.9531 128.733 45.9531 133.333C45.9531 137.933 49.6865 141.667 54.2865 141.667C58.8865 141.667 62.6198 137.933 62.6198 133.333C62.6198 128.733 58.8865 125 54.2865 125Z" fill="#D8A942" />
                                                        <path d="M107.619 151.667C97.4861 151.667 89.2861 143.467 89.2861 133.333C89.2861 123.2 97.4861 115 107.619 115C117.753 115 125.953 123.2 125.953 133.333C125.953 143.467 117.753 151.667 107.619 151.667ZM107.619 125C103.019 125 99.2861 128.733 99.2861 133.333C99.2861 137.933 103.019 141.667 107.619 141.667C112.219 141.667 115.953 137.933 115.953 133.333C115.953 128.733 112.219 125 107.619 125Z" fill="#D8A942" />
                                                        <path d="M147.62 98.3333H127.62C121.22 98.3333 115.953 93.0667 115.953 86.6667V66.6667C115.953 60.2667 121.22 55 127.62 55H136.22C138.02 55 139.686 55.9334 140.553 57.5334L151.953 77.5334C152.353 78.2667 152.62 79.1333 152.62 80V93.3333C152.62 96.0667 150.353 98.3333 147.62 98.3333ZM127.62 65C126.686 65 125.953 65.7333 125.953 66.6667V86.6667C125.953 87.6 126.686 88.3333 127.62 88.3333H142.62V81.3334L133.286 65H127.62Z" fill="#D8A942" />
                                                        <path d="M34.2202 65C25.0202 65 16.3535 60.9998 10.4868 53.9998C9.55351 52.9998 8.55362 51.5998 7.68695 50.2665C4.55362 45.5331 2.82029 39.9332 2.68696 34.0665C2.42029 24.3332 6.5537 15.1998 14.0204 8.9998C19.687 4.33313 26.4867 1.79996 33.6867 1.66663C41.5534 1.73329 49.4204 4.33331 55.287 9.59998C61.9537 15.4666 65.8203 23.6667 66.0203 32.6001C66.1536 38.4001 64.6869 44.1333 61.7535 49.1333C60.1535 51.9999 57.9536 54.7334 55.5536 56.7334C50.2202 61.8 42.8869 64.8 35.0869 65C34.7535 65 34.4868 65 34.2202 65ZM34.2202 11.6666C34.0868 11.6666 33.9535 11.6666 33.8202 11.6666C28.9535 11.7333 24.2867 13.5334 20.3534 16.7334C15.2867 20.9334 12.4869 27.2 12.6202 33.8C12.7536 37.8 13.887 41.6001 16.0203 44.7334C16.6203 45.6668 17.2201 46.5331 17.9535 47.3331C22.2201 52.3998 28.5536 55.0666 34.7536 54.9332C40.0869 54.7999 45.0201 52.8001 48.7534 49.2667C50.4868 47.8001 51.8868 46.0666 52.9535 44.1333C55.0201 40.5999 56.0202 36.7332 55.9536 32.7998C55.8202 26.6665 53.1537 21.0665 48.6204 16.9999C44.6204 13.5999 39.5535 11.6666 34.2202 11.6666Z" fill="#D8A942" />
                                                        <path d="M30.6186 44.9978C29.352 44.9978 28.1518 44.531 27.1519 43.5977L20.4185 37.198C18.4185 35.2646 18.3521 32.1313 20.2854 30.1313C22.2187 28.1313 25.352 28.0645 27.352 29.9978L30.6186 33.131L41.0854 22.9979C43.0854 21.0646 46.2187 21.131 48.152 23.131C50.0853 25.131 50.0185 28.2647 48.0185 30.198L34.085 43.6644C33.085 44.5311 31.8186 44.9978 30.6186 44.9978Z" fill="#D8A942" />
                                                    </svg>

                                                    <p style={{ color: "#10305c", fontSize: "21px", marginBottom: "0" }}>Les colis sont arrivés!</p>
                                                    <p style={{ color: "#10305C", fontSize: "14px" }}>Les colis arrivent à l’adresse désirée ou à un point relais que vous avez choisi</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.adventages} mt-5 ps-md-4`}>{/**************************************************************** */}
                        <div className="d-flex flex-column flex-md-row align-items-md-end">
                            <div className='ms-3 d-md-none'>
                                <p className={`${classes.ourAdventage} fs-3 m-0`}  >Nos</p>
                                <p className={`${classes.ourAdventage1} fs-3 m-0`} >Adventages</p>
                            </div>

                            <p className={`${classes.ourAdventage} fs-3 ms-4 d-none d-md-block`}  >Nos</p>
                            <div className="ps-5 ps-md-0">
                                <div className={`${classes.thirdContainer} row m-0 text-center w-100 p-4 justify-content-center order-3 order-md-2`}>
                                    <div className='col-12 col-md-3 m-4  p-4'>
                                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>{t('np')}</p>
                                        <p style={{ fontSize: "16px" }}>{t('npp')}</p>
                                    </div>
                                    <div className='col-12 col-md-3 m-4  p-4'>
                                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>{t('24s')}</p>
                                        <p style={{ fontSize: "16px" }}>{t('24sp')}</p>

                                    </div>
                                    <div className='col-12 col-md-3 m-4  p-4'>
                                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>{t('etu')}</p>
                                        <p style={{ fontSize: "16px" }}>{t('etup')}</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <p className={`${classes.ourAdventage1} fs-3 ms-4 d-none d-md-block`} >Adventages</p>

                    </div>
                </div>

                <div className={`${classes.forthContainer} d-flex flex-column justify-content-center mt-4 text-center w-100`}>
                    <p className='fs-2' style={{ color: "#e7a03c" }}>{t('slt')}</p>
                    <div className=' row row-cols-1 row-cols-lg-4 g-4 justify-content-center w-100 mx-auto' style={{ "--bs-bg-opacity": ".5" }}>
                        {ttrajets.map((trajet) => (
                            <div className="col d-flex justify-content-center">
                                <div role="button" className="card bg-transparent border border-0 text-center " onClick={() => { navigate('/ajouterColis', { state: { trajet: trajet } }) }}>
                                    <img src={fetchImage(trajet.transporteur.profilePic)} className="card-img-top align-self-center rounded-circle" style={{ height: "160px", width: "160px", objectFit: "cover" }} alt="..." />
                                    <div className="card-body ">
                                        <h5 className="fw-light">
                                            {trajet.transporteur.prenom}
                                        </h5>
                                        <p className='m-0 fw-light card-text'>{trajet.from_address.name} <b>{t('to')}</b>  {trajet.to_address.name} </p>
                                    </div>
                                </div>

                            </div>

                        )

                        )}
                    </div >
                    <a className='btn col-12 col-md-6 text-light align-self-center fw-semibold my-4' style={{ backgroundColor: "#d8a942" }} onClick={() => { navigate("/voirTrajets") }} >{t('sltb')}</a>
                </div>


                <div className={`${classes.aboutUs} p-4 row position-relative w-100`} style={{ minHeight: '100vh' }}>
                    <div className={`col-lg-6 py-4 px-0 ${classes.aboutUs1}`}>
                        <div className={`${classes.aboutUs11} w-100 d-flex align-items-center `}>
                            <div className="col-12 col-lg-8 offset-lg-4 px-4">
                                <div className="d-flex align-items-start my-4">
                                    <div className={`rounded-circle p-2 ${classes.aboutUsSvg}`}>
                                        <img
                                            className="d-none d-lg-block"
                                            src={svg1}
                                            width={5600}
                                            height={56}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        />
                                        <img
                                            className="d-lg-none"
                                            src={svg1}
                                            width={30}
                                            height={30}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        /> </div>
                                    <div className="ms-3">
                                        <p style={{ fontSize: "19px", fontWeight: "bold", color: "#D6992E", margin: 0 }}>
                                            Qui sommes-nous?
                                        </p>
                                        <p style={{ color: "white" }}>Découvrez notre histoire, nos valeurs et notre vision.</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start my-4">
                                    <div className={`rounded-circle p-2 ${classes.aboutUsSvg}`}>
                                        <img
                                            className="d-none d-lg-block"
                                            src={svg2}
                                            width={56}
                                            height={56}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        />
                                        <img
                                            className="d-lg-none"
                                            src={svg2}
                                            width={30}
                                            height={30}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        /></div>
                                    <div className="ms-3">
                                        <p style={{ fontSize: "19px", fontWeight: "bold", color: "#D6992E", margin: 0 }}>
                                            Pourquoi nous choisir ?
                                        </p>
                                        <p style={{ color: "white" }}>
                                            Découvrez les avantages d'expédier avec nous, de la transparence aux tarifs compétitifs.
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start my-4">
                                    <div className={`rounded-circle p-2 ${classes.aboutUsSvg}`}>
                                        <img
                                            className="d-none d-lg-block"
                                            src={svg3}
                                            width={56}
                                            height={56}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        />
                                        <img
                                            className="d-lg-none"
                                            src={svg3}
                                            width={30}
                                            height={30}
                                            style={{ objectFit: "contain" }}
                                            alt="About Us Icon"
                                        />  </div>
                                    <div className="ms-3">
                                        <p style={{ fontSize: "19px", fontWeight: "bold", color: "#D6992E", margin: 0 }}>
                                            Services
                                        </p>
                                        <p style={{ color: "white" }}>
                                            Découvrez les services que nous offrons, de l'envoi de colis à la gestion des formalités
                                            douanières.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-6  py-4 ${classes.aboutUs2}`}>
                        <div className={`col-12 col-lg-8 d-flex align-items-top justify-content-center ${classes.aboutUs21}`}>
                            <div className="border border-3 border-light text-light p-5">
                                <p className="fw-bold fs-3">Actualités & notifications</p>
                                <p className="fw-bold fs-5 m-0">Dernières nouvelles</p>
                                <p style={{ color: "#B2B2B2" }}>
                                    Restez informé avec les dernières nouvelles et mises à jour de notre entreprise.
                                </p>
                                <p className="fw-bold fs-5 m-0">Notifications</p>
                                <p style={{ color: "#B2B2B2" }}>
                                    Soyez averti des changements importants, des offres exclusives et des nouvelles destinations
                                    disponibles.
                                </p>
                            </div>
                            <div className="position-relative">
                                <button className={`btn btn-light rounded-0 w-auto ${classes.aboutUsRotated}`}>Dernières</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.secondContainer} rounded-0 p-4 position-relative`} >
                    <div className={classes.secondContainerBg}></div>
                    <p className='fs-2 m-4 text-center fw-bold' style={{ color: "#0E4458" }}>{t('slp')}</p>
                    <div className=' row row-cols-1 row-cols-lg-2 g-4 p-md-4 pt-0 m-md-4 ' style={{ "--bs-bg-opacity": ".5" }}>
                        {trajets.map((trajet) => (
                            trajet.colis.map((coli) => (
                                <div className="col d-flex justify-content-center ">
                                    <div className="position-relative w-100" style={{ maxWidth: "550PX" }}>
                                        <div className={classes.cardShadow}></div>
                                        <div className={`${classes.card} card mb-3 shadow border border-0 overflow-auto w-100 rounded-0`} >
                                            <div className="row g-0">
                                                <div className="d-none col-2 col-md-3 d-sm-flex align-items-center p-2" onClick={() => { navigate(`/voirOffre`, { state: trajet }) }}>
                                                    <img src={fetchImage(coli.photos[0])} className="img-fluid rounded-start" style={{ maxHeight: "160px", maxWidth: "100%" }} alt="..." />
                                                </div>
                                                <div className="col-8 col-sm-6" onClick={() => { navigate(`/voirOffre`, { state: trajet }) }}>
                                                    <div className="card-body ">
                                                        <p className="card-title text-capitalize fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{coli.title}</p>
                                                        <div className='d-flex'>
                                                            <div className='d-flex flex-column justify-content-center' style={{ width: "20px" }}>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                                                    <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                                                </svg>
                                                                <svg className='align-self-center' width="2" height="50" viewBox="0 0 2 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1 0C1.55228 0 2 0.621827 2 1.38889V48.6111C2 49.3782 1.55228 50 1 50C0.447715 50 0 49.3782 0 48.6111V1.38889C0 0.621827 0.447715 0 1 0Z" fill="black" fillOpacity="0.4" />
                                                                </svg>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                                                    <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                                                </svg>
                                                            </div>
                                                            <div className=' d-flex flex-column align-items-start text-start' style={{ height: "90px" }}>
                                                                <p className='m-0 fw-medium'>{trajet.from_address.name} le {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                                                    month: 'numeric',
                                                                    day: 'numeric',
                                                                })}</p>
                                                                <p className='m-0 fw-medium mt-auto'>{trajet.to_address.name} le {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                                                    month: 'numeric',
                                                                    day: 'numeric',
                                                                })}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-md-3">
                                                    <div className="card-body position-relative">
                                                        <button className='btn bg-transparent position-absolute top-0 end-0 p-2 w-auto' onClick={() => { navigate(`/report`, { state: trajet }) }}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z" fill="black" fillOpacity="0.88" />
                                                                <path d="M12 17.9996C11.94 17.9996 11.87 17.9896 11.8 17.9796C11.74 17.9696 11.68 17.9496 11.62 17.9196C11.56 17.8996 11.5 17.8696 11.44 17.8296C11.39 17.7896 11.34 17.7496 11.29 17.7096C11.11 17.5196 11 17.2596 11 16.9996C11 16.7396 11.11 16.4796 11.29 16.2896C11.34 16.2496 11.39 16.2096 11.44 16.1696C11.5 16.1296 11.56 16.0996 11.62 16.0796C11.68 16.0496 11.74 16.0296 11.8 16.0196C11.93 15.9896 12.07 15.9896 12.19 16.0196C12.26 16.0296 12.32 16.0496 12.38 16.0796C12.44 16.0996 12.5 16.1296 12.56 16.1696C12.61 16.2096 12.66 16.2496 12.71 16.2896C12.89 16.4796 13 16.7396 13 16.9996C13 17.2596 12.89 17.5196 12.71 17.7096C12.66 17.7496 12.61 17.7896 12.56 17.8296C12.5 17.8696 12.44 17.8996 12.38 17.9196C12.32 17.9496 12.26 17.9696 12.19 17.9796C12.13 17.9896 12.06 17.9996 12 17.9996Z" fill="black" fillOpacity="0.88" />
                                                                <path d="M18.06 22.1601H5.94001C3.99001 22.1601 2.50001 21.4501 1.74001 20.1701C0.990006 18.8901 1.09001 17.2401 2.04001 15.5301L8.10001 4.63009C9.10001 2.83009 10.48 1.84009 12 1.84009C13.52 1.84009 14.9 2.83009 15.9 4.63009L21.96 15.5401C22.91 17.2501 23.02 18.8901 22.26 20.1801C21.5 21.4501 20.01 22.1601 18.06 22.1601ZM12 3.34009C11.06 3.34009 10.14 4.06009 9.41001 5.36009L3.36001 16.2701C2.68001 17.4901 2.57001 18.6101 3.04001 19.4201C3.51001 20.2301 4.55001 20.6701 5.95001 20.6701H18.07C19.47 20.6701 20.5 20.2301 20.98 19.4201C21.46 18.6101 21.34 17.5001 20.66 16.2701L14.59 5.36009C13.86 4.06009 12.94 3.34009 12 3.34009Z" fill="black" fillOpacity="0.88" />
                                                            </svg>

                                                        </button>

                                                        <p className="fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{trajet.prix} €</p>
                                                        <div className='mt-auto border-start border-2 border-secondary-subtle ps-1'>
                                                            <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>{t('qteSM')} : {coli.qte}</p>
                                                            <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Dim : {coli.length} x {coli.width} x {coli.height} cm</p>
                                                            <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>{t("weight")}: {weightList[coli.weight - 1]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            ))
                        )
                        )}
                    </div >
                    <div className="text-center">
                        <a className='btn   btn-lg  fw-semibold mb-4' onClick={() => { navigate("/voirColis") }} >{t('slpb')}</a>

                    </div>

                </div>
            </div >
        </>)
}
export default Home