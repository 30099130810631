import { DirectionsRenderer, GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import axios from "axios";
import React, { useState, useEffect } from "react";
import TrajetCard from "../cards/trajetCard/trajetCard";
import { useMap } from "../../mapContext"
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

const token = window.localStorage.getItem("_auth");
axios.defaults.headers.common["Authorization"] = `${token}`;

const centerPoint = {
    lat: 47.5162,
    lng: 14.5501, // Replace with your desired center coordinates
};

function Map({ data, style, trajet }) {
    const { isLoaded } = useMap()
    var mapContainerStyle = {}
    const [windowData, setWindowData] = useState({});
    const [center, setCenter] = useState(centerPoint);
    const [zoom, setZoom] = useState(4.5);
    const [trajets, setTrajets] = useState(data ?? []);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [relais, setRelais] = useState([])
    const [directions, setDirections] = useState(null);

    if (style === "small") {
        mapContainerStyle = {
            position: 'relative',
            borderRadius: '8px',
            border: '2px solid #d8a942',
            width: '100%',
            paddingBottom: '100%', // This makes the height equal to the width

        };
    } else if (style === "directions") {
        mapContainerStyle = {
            position: 'relative',
            borderRadius: '32px',
            border: '2px solid #d8a942',
            width: '100%',
            height: "75vh",

        };
    }
    else {
        mapContainerStyle = {
            position: "fixed",
            top: "0px",
            width: "100%",
            height: "100%",
            border: "none"
        };
    }
    useEffect(() => {
        if (isLoaded) {
            const fetchData = async () => {
                try {
                    if (!data || data.length === 0) {
                        console.log('map fetch data')
                        const response = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/samples`);
                        setTrajets(response.data);
                    }
                    else {
                        setTrajets(data);
                        console.log("parent data loaded")
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            if (!trajet)
                fetchData();
            else {
                console.log("Fetching directions")
                const fromLatLng = {
                    lat: trajet.from_address.location.lat,
                    lng: trajet.from_address.location.lng
                };
                const toLatLng = {
                    lat: trajet.to_address.location.lat,
                    lng: trajet.to_address.location.lng
                };

                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(
                    {
                        origin: fromLatLng,
                        destination: toLatLng,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                        } else {
                            console.error(`Error fetching directions ${result}`);
                        }
                    }
                );
            }
        }
    }, [data, isLoaded]);
    const fetchRelais = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/relais`);
            console.log(response.data);
            setRelais(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchRelais()
    }, [])
    const handleMarkerFClick = (place) => {
        console.log(place);
        setZoom(16);
        setCenter(place.from_address.location);
        setWindowData({ trajet: place, colis: [] });
        if (!data || data.length === 0) {
            fetchColisAndOffres(place);
        }
        else {
            setWindowData(place)
        }
    };

    const fetchColisAndOffres = async (place) => {
        try {
            const trajetId = place._id
            const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${trajetId}`);
            place.colis = colisResponse.data;
            setWindowData(place);
        } catch (error) {
            console.error("Error fetching colis and offres data:", error);
        }
    };

    const options = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        scrollwheel: true // Enable zooming with mouse scroll

    };
    const ColiIcon =
        `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.9585 18.125C40.7919 8.47913 32.3752 4.16663 25.0002 4.16663C25.0002 4.16663 25.0002 4.16663 24.9794 4.16663C17.6252 4.16663 9.22938 8.47913 7.04188 18.1041C4.58355 28.8541 11.1669 37.9583 17.1252 43.7083C19.3335 45.8333 22.1669 46.8958 25.0002 46.8958C27.8335 46.8958 30.6669 45.8333 32.8544 43.7083C38.8127 37.9583 45.396 28.875 42.9585 18.125ZM30.7294 24.4791H19.271C18.4169 24.4791 17.7085 23.7708 17.7085 22.9166C17.7085 22.0625 18.4169 21.3541 19.271 21.3541H30.7294C31.5835 21.3541 32.2919 22.0625 32.2919 22.9166C32.2919 23.7708 31.5835 24.4791 30.7294 24.4791Z" fill="#d8a942"/>
<rect x="16.6665" y="20.8334" width="16.6667" height="6.25" fill="#d8a942"/>
<path d="M24.9997 23.1112C24.8553 23.1112 24.7108 23.0779 24.5775 23.0001L14.7664 17.3223C14.3775 17.089 14.233 16.5779 14.4664 16.1779C14.6997 15.7779 15.2108 15.6445 15.6108 15.8779L24.9997 21.3112L34.333 15.9112C34.733 15.6779 35.2442 15.8223 35.4775 16.2112C35.7108 16.6112 35.5664 17.1223 35.1775 17.3557L25.433 23.0001C25.2886 23.0668 25.1442 23.1112 24.9997 23.1112Z" fill="white"/>
<path d="M24.9998 33.1778C24.5443 33.1778 24.1665 32.8 24.1665 32.3445V22.2667C24.1665 21.8111 24.5443 21.4333 24.9998 21.4333C25.4554 21.4333 25.8332 21.8111 25.8332 22.2667V32.3445C25.8332 32.8 25.4554 33.1778 24.9998 33.1778Z" fill="white"/>
<path d="M24.9999 33.6112C24.0221 33.6112 23.0554 33.4001 22.2888 32.9778L16.3554 29.6778C14.7443 28.789 13.4888 26.6445 13.4888 24.8001V18.5223C13.4888 16.6778 14.7443 14.5445 16.3554 13.6445L22.2888 10.3556C23.811 9.51118 26.1888 9.51118 27.711 10.3556L33.6443 13.6556C35.2554 14.5445 36.511 16.689 36.511 18.5334V24.8112C36.511 26.6556 35.2554 28.789 33.6443 29.689L27.711 32.9778C26.9443 33.4001 25.9777 33.6112 24.9999 33.6112ZM24.9999 11.389C24.2999 11.389 23.611 11.5334 23.0999 11.8112L17.1665 15.1112C16.0999 15.7001 15.1554 17.3001 15.1554 18.5223V24.8001C15.1554 26.0223 16.0999 27.6223 17.1665 28.2223L23.0999 31.5223C24.111 32.089 25.8888 32.089 26.8999 31.5223L32.8332 28.2223C33.8999 27.6223 34.8443 26.0334 34.8443 24.8001V18.5223C34.8443 17.3001 33.8999 15.7001 32.8332 15.1001L26.8999 11.8001C26.3888 11.5334 25.6999 11.389 24.9999 11.389Z" fill="white"/>
</svg>`

    const RelaiIcon = `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="16" y="20" width="16.6667" height="6.25" fill="#d8a942"/>
<path d="M42.9585 18.125C40.7919 8.47913 32.3752 4.16663 25.0002 4.16663C25.0002 4.16663 25.0002 4.16663 24.9794 4.16663C17.6252 4.16663 9.22938 8.47913 7.04188 18.1041C4.58355 28.8541 11.1669 37.9583 17.1252 43.7083C19.3335 45.8333 22.1669 46.8958 25.0002 46.8958C27.8335 46.8958 30.6669 45.8333 32.8544 43.7083C38.8127 37.9583 45.396 28.875 42.9585 18.125ZM30.7294 24.4791H19.271C18.4169 24.4791 17.7085 23.7708 17.7085 22.9166C17.7085 22.0625 18.4169 21.3541 19.271 21.3541H30.7294C31.5835 21.3541 32.2919 22.0625 32.2919 22.9166C32.2919 23.7708 31.5835 24.4791 30.7294 24.4791Z" fill="#d8a942"/>
<path d="M27.6998 31.7497H22.2998C17.3598 31.7497 15.2598 29.6397 15.2598 24.7097V20.2197C15.2598 19.8097 15.5998 19.4697 16.0098 19.4697C16.4198 19.4697 16.7598 19.8097 16.7598 20.2197V24.7097C16.7598 28.7997 18.2098 30.2497 22.2998 30.2497H27.6898C31.7798 30.2497 33.2298 28.7997 33.2298 24.7097V20.2197C33.2298 19.8097 33.5698 19.4697 33.9798 19.4697C34.3898 19.4697 34.7298 19.8097 34.7298 20.2197V24.7097C34.7398 29.6397 32.6298 31.7497 27.6998 31.7497Z" fill="white"/>
<path d="M25 21.75C23.9 21.75 22.9 21.32 22.19 20.53C21.48 19.74 21.15 18.71 21.26 17.61L21.93 10.93C21.97 10.55 22.29 10.25 22.68 10.25H27.35C27.74 10.25 28.06 10.54 28.1 10.93L28.77 17.61C28.88 18.71 28.55 19.74 27.84 20.53C27.1 21.32 26.1 21.75 25 21.75ZM23.35 11.75L22.75 17.76C22.68 18.43 22.88 19.06 23.3 19.52C24.15 20.46 25.85 20.46 26.7 19.52C27.12 19.05 27.32 18.42 27.25 17.76L26.65 11.75H23.35Z" fill="white"/>
<path d="M31.31 21.75C29.28 21.75 27.47 20.11 27.26 18.09L26.56 11.08C26.54 10.87 26.61 10.66 26.75 10.5C26.89 10.34 27.09 10.25 27.31 10.25H30.36C33.3 10.25 34.67 11.48 35.08 14.5L35.36 17.28C35.48 18.46 35.12 19.58 34.35 20.43C33.58 21.28 32.5 21.75 31.31 21.75ZM28.14 11.75L28.76 17.94C28.89 19.19 30.05 20.25 31.31 20.25C32.07 20.25 32.75 19.96 33.24 19.43C33.72 18.9 33.94 18.19 33.87 17.43L33.59 14.68C33.28 12.42 32.55 11.75 30.36 11.75H28.14Z" fill="white"/>
<path d="M18.64 21.75C17.45 21.75 16.37 21.28 15.6 20.43C14.83 19.58 14.47 18.46 14.59 17.28L14.86 14.53C15.28 11.48 16.65 10.25 19.59 10.25H22.64C22.85 10.25 23.05 10.34 23.2 10.5C23.35 10.66 23.41 10.87 23.39 11.08L22.69 18.09C22.48 20.11 20.67 21.75 18.64 21.75ZM19.59 11.75C17.4 11.75 16.67 12.41 16.35 14.7L16.08 17.43C16 18.19 16.23 18.9 16.71 19.43C17.19 19.96 17.87 20.25 18.64 20.25C19.9 20.25 21.07 19.19 21.19 17.94L21.81 11.75H19.59Z" fill="white"/>
<path d="M27.5 31.75H22.5C22.09 31.75 21.75 31.41 21.75 31V28.5C21.75 26.4 22.9 25.25 25 25.25C27.1 25.25 28.25 26.4 28.25 28.5V31C28.25 31.41 27.91 31.75 27.5 31.75ZM23.25 30.25H26.75V28.5C26.75 27.24 26.26 26.75 25 26.75C23.74 26.75 23.25 27.24 23.25 28.5V30.25Z" fill="white"/>
</svg>
`

    function svgToDataURL(svg) {
        // Encode the SVG as a data URL
        const encodedSvg = encodeURIComponent(svg);
        const dataUrl = `data:image/svg+xml,${encodedSvg}`;

        return dataUrl;
    }
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            options={options}
        >
            {directions && (
                <DirectionsRenderer
                    directions={directions}
                />
            )}
            {trajets.map((place, index, array) => (
                place.from_address.location && (
                    <MarkerF
                        key={place._id}
                        position={{
                            lat: place.from_address.location.lat + 0.0001 * Math.cos(2 * Math.PI * index / array.length),
                            lng: place.from_address.location.lng + 0.0001 * Math.sin(2 * Math.PI * index / array.length),
                        }}
                        onClick={() => {
                            handleMarkerFClick(place)
                            setSelectedPlace(place)
                        }}
                        icon={{
                            url: svgToDataURL(ColiIcon), // use the SVG data URL
                            scaledSize: new window.google.maps.Size(50, 50), // size of the icon
                        }}                    >
                        {selectedPlace && selectedPlace._id === place._id && (
                            <InfoWindowF
                                options={{ minWidth: 400 }}
                                position={selectedPlace.from_address.location}
                                onCloseClick={() => {
                                    setSelectedPlace(null);
                                }}

                            >
                                <div className="container" style={{ height: 'auto' }}>
                                    <TrajetCard trajet={windowData} />

                                </div>
                            </InfoWindowF>
                        )}

                    </MarkerF >
                )
            ))}
            {relais.map((relai) => (
                <MarkerF
                    key={relai._id}
                    position={relai.location}
                    icon={{
                        url: svgToDataURL(RelaiIcon), // use the SVG data URL
                        scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    onClick={() => {
                        setCenter(relai.location)
                        setZoom(16)
                    }}
                >
                </MarkerF>))
            }
        </GoogleMap >
    ) : (<></>);
}
export function RelaiMapSelection({ style, setRelai, nextModalRef, currentModal, location }) {
    var mapContainerStyle = {}
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [relais, setRelais] = useState([])
    const [center, setCenter] = useState(centerPoint);
    const [zoom, setZoom] = useState(14); // Default zoom level
    if (style === "small") {
        mapContainerStyle = {
            borderRadius: '30%',
            border: '8px solid #d8a942',
            width: '100%',
            paddingBottom: '100%', // This makes the height equal to the width
            height: 0,

        };
    }
    else {
        mapContainerStyle = {
            width: "100%",
            height: "100%",
        };
    }
    const fetchRelais = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/relais`);
            setRelais(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        if (location) {
            setCenter(location)
            setZoom(18)
        }
        else {
            setCenter(centerPoint)
        }
        fetchRelais()
    }, [location])
    const handleMarkerFClick = (relai) => {
        console.log(relai);
        setZoom(18);
        setCenter(relai.location);

    };
    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true
    };
    const RelaiIcon = `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="16" y="20" width="16.6667" height="6.25" fill="#d8a942"/>
<path d="M42.9585 18.125C40.7919 8.47913 32.3752 4.16663 25.0002 4.16663C25.0002 4.16663 25.0002 4.16663 24.9794 4.16663C17.6252 4.16663 9.22938 8.47913 7.04188 18.1041C4.58355 28.8541 11.1669 37.9583 17.1252 43.7083C19.3335 45.8333 22.1669 46.8958 25.0002 46.8958C27.8335 46.8958 30.6669 45.8333 32.8544 43.7083C38.8127 37.9583 45.396 28.875 42.9585 18.125ZM30.7294 24.4791H19.271C18.4169 24.4791 17.7085 23.7708 17.7085 22.9166C17.7085 22.0625 18.4169 21.3541 19.271 21.3541H30.7294C31.5835 21.3541 32.2919 22.0625 32.2919 22.9166C32.2919 23.7708 31.5835 24.4791 30.7294 24.4791Z" fill="#d8a942"/>
<path d="M27.6998 31.7497H22.2998C17.3598 31.7497 15.2598 29.6397 15.2598 24.7097V20.2197C15.2598 19.8097 15.5998 19.4697 16.0098 19.4697C16.4198 19.4697 16.7598 19.8097 16.7598 20.2197V24.7097C16.7598 28.7997 18.2098 30.2497 22.2998 30.2497H27.6898C31.7798 30.2497 33.2298 28.7997 33.2298 24.7097V20.2197C33.2298 19.8097 33.5698 19.4697 33.9798 19.4697C34.3898 19.4697 34.7298 19.8097 34.7298 20.2197V24.7097C34.7398 29.6397 32.6298 31.7497 27.6998 31.7497Z" fill="white"/>
<path d="M25 21.75C23.9 21.75 22.9 21.32 22.19 20.53C21.48 19.74 21.15 18.71 21.26 17.61L21.93 10.93C21.97 10.55 22.29 10.25 22.68 10.25H27.35C27.74 10.25 28.06 10.54 28.1 10.93L28.77 17.61C28.88 18.71 28.55 19.74 27.84 20.53C27.1 21.32 26.1 21.75 25 21.75ZM23.35 11.75L22.75 17.76C22.68 18.43 22.88 19.06 23.3 19.52C24.15 20.46 25.85 20.46 26.7 19.52C27.12 19.05 27.32 18.42 27.25 17.76L26.65 11.75H23.35Z" fill="white"/>
<path d="M31.31 21.75C29.28 21.75 27.47 20.11 27.26 18.09L26.56 11.08C26.54 10.87 26.61 10.66 26.75 10.5C26.89 10.34 27.09 10.25 27.31 10.25H30.36C33.3 10.25 34.67 11.48 35.08 14.5L35.36 17.28C35.48 18.46 35.12 19.58 34.35 20.43C33.58 21.28 32.5 21.75 31.31 21.75ZM28.14 11.75L28.76 17.94C28.89 19.19 30.05 20.25 31.31 20.25C32.07 20.25 32.75 19.96 33.24 19.43C33.72 18.9 33.94 18.19 33.87 17.43L33.59 14.68C33.28 12.42 32.55 11.75 30.36 11.75H28.14Z" fill="white"/>
<path d="M18.64 21.75C17.45 21.75 16.37 21.28 15.6 20.43C14.83 19.58 14.47 18.46 14.59 17.28L14.86 14.53C15.28 11.48 16.65 10.25 19.59 10.25H22.64C22.85 10.25 23.05 10.34 23.2 10.5C23.35 10.66 23.41 10.87 23.39 11.08L22.69 18.09C22.48 20.11 20.67 21.75 18.64 21.75ZM19.59 11.75C17.4 11.75 16.67 12.41 16.35 14.7L16.08 17.43C16 18.19 16.23 18.9 16.71 19.43C17.19 19.96 17.87 20.25 18.64 20.25C19.9 20.25 21.07 19.19 21.19 17.94L21.81 11.75H19.59Z" fill="white"/>
<path d="M27.5 31.75H22.5C22.09 31.75 21.75 31.41 21.75 31V28.5C21.75 26.4 22.9 25.25 25 25.25C27.1 25.25 28.25 26.4 28.25 28.5V31C28.25 31.41 27.91 31.75 27.5 31.75ZM23.25 30.25H26.75V28.5C26.75 27.24 26.26 26.75 25 26.75C23.74 26.75 23.25 27.24 23.25 28.5V30.25Z" fill="white"/>
</svg>
`
    function svgToDataURL(svg) {
        // Encode the SVG as a data URL
        const encodedSvg = encodeURIComponent(svg);
        const dataUrl = `data:image/svg+xml,${encodedSvg}`;

        return dataUrl;
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    }
    const handleClose = () => {
        setCenter(location)
        if (nextModalRef) {
            openModal(nextModalRef)
            closeModal(currentModal)
        }
        else {
            closeModal(currentModal)
        }


    }
    const { isLoaded } = useMap()

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            options={options}
        >

            {relais.map((relai) => (
                <MarkerF
                    key={relai._id}
                    position={relai.location}
                    icon={{
                        url: svgToDataURL(RelaiIcon), // use the SVG data URL
                        scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    onClick={() => {
                        setCenter(relai.location)
                        setZoom(18)
                        handleMarkerFClick(relai)
                        setSelectedPlace(relai)
                    }}
                >
                    {selectedPlace && selectedPlace._id === relai._id && (
                        <InfoWindowF
                            position={selectedPlace.location}
                            onCloseClick={() => {
                                setSelectedPlace(null);
                            }}
                        >
                            <div className="container" style={{ height: 'auto' }}>
                                <div>
                                    <h4>{relai.name}</h4>
                                    <p>Adresse : {relai.address}</p>
                                    <button className="btn btn-primary btn-small col-12"
                                        onClick={() => {
                                            setRelai(relai._id)
                                            setSelectedPlace(null);
                                            handleClose()
                                        }}>Sélectionner</button>
                                </div>
                            </div>
                        </InfoWindowF>
                    )}

                </MarkerF>))
            }
        </GoogleMap >
    ) : (<></>);
}

export default Map;
