import { useEffect, useRef, useState } from "react";
import classes from "./ajouter-colis.module.css"
import axios from "axios";
import Mapinput from "../../components/map/input";
import { RelaiMapSelection } from "../../components/map/map";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from "react-i18next";

function AjouterTrajetForm({ trajet, setTrajet, offre, setOffre, goDateValid, arriveDateValid }) {
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const { t } = useTranslation()
    const modalRef3 = useRef(null)
    const [offreRelai1, setOffreRelai1] = useState(null)
    const [offreRelai2, setOffreRelai2] = useState(null)
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    }
    const handlePlaceSelection = (place, addressType) => {
        setTrajet({ ...trajet, [addressType]: place });
        console.log(place)
    };
    const handleRelaiSelection1 = async (place) => {
        console.log("Setting relai1");
        setOffre({ ...offre, relai1: place })
        let n1 = await getRelaiName(place)
        setOffreRelai1(n1)
    };
    const handleRelaiSelection2 = async (place) => {
        console.log("Setting relai2");
        setOffre({ ...offre, relai2: place })
        let n1 = await getRelaiName(place)
        setOffreRelai2(n1)
    };
    useEffect(() => {
        if (trajet.transporteur) {
            fetchRelais()
            openModal(modalRef1)
        }
    }, [])
    const weightList = t('weightList', { returnObjects: true });
    const [relai1, setRelai1] = useState(false);
    const [relai2, setRelai2] = useState(false);
    const getRelaiName = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data.name
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (err) {
            console.log(err)
        }
    }
    const fetchRelais = async () => {
        const n1 = await getRelaiName(trajet.relai1)
        const n2 = await getRelaiName(trajet.relai2)
        console.log(n1, n2)
        setTrajet({ ...trajet, relai1: n1, relai2: n2 })
    }
    const [error1, setError1] = useState()
    const [error2, setError2] = useState()
    const [error12, setError12] = useState()
    const [error22, setError22] = useState()
    const validateOffre = () => {
        let error1, error2, error12, error22 = false
        if (relai1 || !offre.mam1) {
            if (relai1 && !offre.relai1) {
                console.log("Error 12")
                error12 = true
            }
            else if (!offre.mam1 && !relai1) {
                console.log("Error 1")
                error1 = true
            }

        }
        if (relai2 || !offre.mam2) {
            if (relai2 && !offre.relai2) {
                console.log("Error 22")
                error22 = true
            }
            else if (!offre.mam2 && !relai2) {
                console.log("Error 2")
                error2 = true

            }

        }
        if (!error1 && !error2 && !error12 && !error22)

            closeModal(modalRef1)

        console.log(offre)
        setError1(error1)
        setError2(error2)
        setError12(error12)
        setError22(error22)
    }
    const checkDate = (e) => {
        console.log(e.target.value)
    }

    const isValidDate = (dateString) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(dateString);
    };
    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };
    return (
        <div className={`${classes.FromBox} `}>
            <div>
                <div className="row">
                    <label>{t("departure")}</label>
                    <Mapinput firstValue={trajet?.from_address?.name || ""} onChange={place => handlePlaceSelection(place, 'from_address')} placeholder={t('adressInput')} disabled={trajet.transporteur} />
                </div>
                <div className="row mt-2">
                    <label>{t("arrival")}</label>
                    <Mapinput firstValue={trajet?.to_address?.name || ""} onChange={place => handlePlaceSelection(place, 'to_address')} placeholder={t('adressInput')} disabled={trajet.transporteur} />
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>{t("departureDate")}</label>
                        <input
                            min={formatDate(new Date())}

                            disabled={trajet.transporteur}
                            className={`form-control ${goDateValid === true ? 'is-valid' : goDateValid === false ? 'is-invalid' : ""}`} type="date" required
                            value={trajet.go_time ? new Date(trajet.go_time).toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                                const dateValue = e.target.value;
                                if (isValidDate(dateValue)) {
                                    checkDate(e);
                                    setTrajet({ ...trajet, go_time: dateValue });
                                } else {
                                    console.error("Invalid date format:", dateValue);
                                }
                            }}
                        />
                        <div className="invalid-feedback">
                            {trajet.go_time ? "Veuillez saisir une date valide" : "Veuillez saisir une date"}
                        </div>
                    </div>
                    <div className="col">
                        <label>{t("arrivalDate")}</label>
                        <input
                            disabled={trajet.transporteur}
                            value={trajet.arrive_time ? new Date(trajet.arrive_time).toISOString().split('T')[0] : ''}
                            className={`form-control ${arriveDateValid === true ? 'is-valid' : arriveDateValid === false ? 'is-invalid' : ""}`} type="date" required
                            min={formatDate(trajet.go_time)}
                            onChange={(e) => {
                                const dateValue = e.target.value;
                                if (isValidDate(dateValue)) {
                                    checkDate(e);
                                    setTrajet({ ...trajet, arrive_time: dateValue });
                                } else {
                                    console.error("Invalid date format:", dateValue);
                                }
                            }}
                        />
                        <div className="invalid-feedback">
                            {trajet.arrive_time ? "Veuillez saisir une date valide" : "Veuillez saisir une date"}
                        </div>
                    </div>
                </div>

                {trajet.transporteur && <div className="row mt-4 p-2">
                    <button type="button" onClick={() => {
                        openModal(modalRef1)
                    }} className="btn btn-primary">
                        {t('ctd')}
                    </button>
                </div>
                }


                <div className="row mt-4">
                    <div className="col-1 me-4">
                        <p>{t('att')}</p>
                    </div>
                    <div className="col">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 8V13"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.9945 16H12.0035"
                                stroke="#0080FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <p className={`${classes.attention}`}>
                        {t('attText')}
                    </p>
                </div>
            </div>
            {trajet.transporteur &&
                <>
                    <div className="modal fade modal-xl" ref={modalRef1} aria-hidden="true" aria-labelledby="modalToggleLabel" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                                <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                    <h1 className="modal-title fs-5 text-light" id="modalToggleLabel">{t('makeOffer')}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="card rounded-4">
                                                <div className="card-body">
                                                    <h5 className="card-title">{t('offreDetails')}</h5>
                                                    <h6 className="fw-bold">{t('start')}</h6>
                                                    <p>
                                                        {t('startH2H')} {trajet && trajet.mam1 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('startRelai')} <b>{(trajet && trajet.relai1) ? (trajet.relai1) : (t('no'))}</b>
                                                    </p>
                                                    <h6 className="fw-bold">{t('end')}</h6>
                                                    <p>
                                                        {t('endH2H')} {trajet && trajet.mam2 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('endRelai')} <b>{(trajet && trajet.relai2) ? (trajet.relai2) : (t('no'))}</b>
                                                    </p>
                                                    <h6 className="fw-bold">{t('constraints')} :</h6>
                                                    <p>
                                                        {t('maxDim')} : {trajet && trajet.max_length && trajet.max_width && trajet.max_height ? (trajet.max_length + " x " + trajet.max_width + " x " + trajet.max_height + " cm") : (t('notSpecified'))}
                                                    </p>
                                                    <p>
                                                        {t('maxWeight')} : {trajet && trajet.max_weight ? (weightList[trajet.max_weight - 1]) : (t('notSpecified'))}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col">
                                            <div className="card rounded-4 mt-4 mt-lg-0">
                                                <div className="card-body">
                                                    <h5 className="card-title" >{t('yourOffre')}</h5>
                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('start')}</h6>
                                                        <div className="d-flex">
                                                            <p className="me-4 text-nowrap">{t('startH2H')}</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1" // Separate name for this group
                                                                    id="r1"
                                                                    value='oui'
                                                                    checked={offre.mam1}
                                                                    onChange={(event) => {

                                                                        setOffre({ ...offre, mam1: true, relai1: undefined });
                                                                        setRelai1(false);

                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r1">{t('yes')}</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1" // Separate name for this group
                                                                    id="r2"
                                                                    value="non"
                                                                    checked={!offre.mam1}
                                                                    onChange={(event) => {

                                                                        setOffre({ ...offre, mam1: false });
                                                                        setRelai1(true)

                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r2">{t('no')}</label>
                                                            </div>
                                                        </div>


                                                        {/**************************************************************************************** */}
                                                        < div className="d-flex flex-wrap align-items-start ">
                                                            <p className="me-4">{t('startRelai')}</p>
                                                            {!offre.relai1 && <>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai1" // Separate name for this group
                                                                        id="r3"
                                                                        checked={relai1}
                                                                        required
                                                                        onChange={(event) => {
                                                                            setRelai1(true);
                                                                            setOffre({ ...offre, mam1: false }); // Ensure mam1 is false when "relai" is selected

                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r3">{t('yes')}</label>
                                                                </div>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai1"
                                                                        id="r4"
                                                                        value="non"
                                                                        checked={relai1 != null && relai1 === false}
                                                                        onChange={(event) => {
                                                                            setRelai1(false);
                                                                            setOffre({ ...offre, relai1: undefined, mam1: true });

                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r4">{t('no')}</label>
                                                                </div>
                                                            </>}

                                                            {offreRelai1 && <div className="d-flex mb-2">
                                                                <p className="me-2 mb-0">{offreRelai1}</p>
                                                                <div className="d-flex">
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {
                                                                        closeModal(modalRef1)
                                                                        openModal(modalRef2)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50032 17.6896C7.89032 17.6896 7.33032 17.4696 6.92032 17.0696C6.43032 16.5796 6.22032 15.8696 6.33032 15.1196L6.76032 12.1096C6.84032 11.5296 7.22032 10.7796 7.63032 10.3696L15.5104 2.48957C17.5004 0.49957 19.5204 0.49957 21.5104 2.48957C22.6004 3.57957 23.0904 4.68957 22.9904 5.79957C22.9004 6.69957 22.4204 7.57957 21.5104 8.47957L13.6304 16.3596C13.2204 16.7696 12.4704 17.1496 11.8904 17.2296L8.88032 17.6596C8.75032 17.6896 8.62032 17.6896 8.50032 17.6896ZM16.5704 3.54957L8.69032 11.4296C8.50032 11.6196 8.28032 12.0596 8.24032 12.3196L7.81032 15.3296C7.77032 15.6196 7.83032 15.8596 7.98032 16.0096C8.13032 16.1596 8.37032 16.2196 8.66032 16.1796L11.6704 15.7496C11.9304 15.7096 12.3804 15.4896 12.5604 15.2996L20.4404 7.41957C21.0904 6.76957 21.4304 6.18957 21.4804 5.64957C21.5404 4.99957 21.2004 4.30957 20.4404 3.53957C18.8404 1.93957 17.7404 2.38957 16.5704 3.54957Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M19.8505 9.83027C19.7805 9.83027 19.7105 9.82027 19.6505 9.80027C17.0205 9.06027 14.9305 6.97027 14.1905 4.34027C14.0805 3.94027 14.3105 3.53027 14.7105 3.41027C15.1105 3.30027 15.5205 3.53027 15.6305 3.93027C16.2305 6.06027 17.9205 7.75027 20.0505 8.35027C20.4505 8.46027 20.6805 8.88027 20.5705 9.28027C20.4805 9.62027 20.1805 9.83027 19.8505 9.83027Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={(event) => {

                                                                        setRelai1(false);
                                                                        setOffre({ ...offre, relai1: undefined, mam1: true });
                                                                        setOffreRelai1(null)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                        {/******************************************************************************************************************************** */}
                                                        {(relai1 === true && !offreRelai1) && (
                                                            <button
                                                                className="btn btn-outline-primary col-12 mb-4"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    closeModal(modalRef1)
                                                                    openModal(modalRef2)
                                                                }}>
                                                                {t('selectRelai')}
                                                            </button>
                                                        )}
                                                        {error1 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une option
                                                        </div>}
                                                        {error12 && <div className="alert alert-danger">
                                                            {t('selectRelaisError')}
                                                        </div>}
                                                    </fieldset>

                                                    <fieldset className="">
                                                        <h6 className="fw-bold">{t('end')}</h6>
                                                        <div className="d-flex">
                                                            <p className="me-4">{t('endH2H')}</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2" // Separate name for this group
                                                                    id="r5"
                                                                    value='oui'
                                                                    checked={offre.mam2}
                                                                    onChange={(event) => {
                                                                        //  handleInputChange(event);
                                                                        setOffre({ ...offre, mam2: true, relai2: undefined });
                                                                        setRelai2(false);
                                                                        // Ensure relai2 is false when "main à main" is selected
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r5">{t('yes')}</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2"
                                                                    id="r6"
                                                                    checked={!offre.mam2}
                                                                    value="non"
                                                                    onChange={(event) => {
                                                                        // handleInputChange(event);
                                                                        setRelai2(true);
                                                                        setOffre({ ...offre, mam2: false });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r6">{t('no')}</label>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <p className="me-4">{t('endRelai')}</p>
                                                            {!offre.relai2 && <>  <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="relai2" // Separate name for this group
                                                                    id="r7"
                                                                    value='oui'
                                                                    checked={relai2}
                                                                    onChange={(event) => {
                                                                        // handleInputChange(event);
                                                                        setRelai2(true);
                                                                        setOffre({ ...offre, mam2: false }); // Ensure mam2 is false when "relai" is selected
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r7">{t('yes')}</label>
                                                            </div>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai2"
                                                                        id="r8"
                                                                        value="non"
                                                                        checked={!relai2}
                                                                        onChange={(event) => {
                                                                            // handleInputChange(event);
                                                                            setRelai2(false);
                                                                            setOffre({ ...offre, relai2: undefined, mam2: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r8">{t('no')}</label>
                                                                </div></>}


                                                            {/******************************** */}
                                                            {offreRelai2 && < div className="d-flex mb-2">
                                                                <p className="me-2 mb-0">{offreRelai2}</p>
                                                                <div className="d-flex">
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {
                                                                        closeModal(modalRef1)
                                                                        openModal(modalRef3)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50032 17.6896C7.89032 17.6896 7.33032 17.4696 6.92032 17.0696C6.43032 16.5796 6.22032 15.8696 6.33032 15.1196L6.76032 12.1096C6.84032 11.5296 7.22032 10.7796 7.63032 10.3696L15.5104 2.48957C17.5004 0.49957 19.5204 0.49957 21.5104 2.48957C22.6004 3.57957 23.0904 4.68957 22.9904 5.79957C22.9004 6.69957 22.4204 7.57957 21.5104 8.47957L13.6304 16.3596C13.2204 16.7696 12.4704 17.1496 11.8904 17.2296L8.88032 17.6596C8.75032 17.6896 8.62032 17.6896 8.50032 17.6896ZM16.5704 3.54957L8.69032 11.4296C8.50032 11.6196 8.28032 12.0596 8.24032 12.3196L7.81032 15.3296C7.77032 15.6196 7.83032 15.8596 7.98032 16.0096C8.13032 16.1596 8.37032 16.2196 8.66032 16.1796L11.6704 15.7496C11.9304 15.7096 12.3804 15.4896 12.5604 15.2996L20.4404 7.41957C21.0904 6.76957 21.4304 6.18957 21.4804 5.64957C21.5404 4.99957 21.2004 4.30957 20.4404 3.53957C18.8404 1.93957 17.7404 2.38957 16.5704 3.54957Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M19.8505 9.83027C19.7805 9.83027 19.7105 9.82027 19.6505 9.80027C17.0205 9.06027 14.9305 6.97027 14.1905 4.34027C14.0805 3.94027 14.3105 3.53027 14.7105 3.41027C15.1105 3.30027 15.5205 3.53027 15.6305 3.93027C16.2305 6.06027 17.9205 7.75027 20.0505 8.35027C20.4505 8.46027 20.6805 8.88027 20.5705 9.28027C20.4805 9.62027 20.1805 9.83027 19.8505 9.83027Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={(event) => {

                                                                        setRelai2(false);
                                                                        setOffre({ ...offre, relai2: undefined, mam1: true });
                                                                        setOffreRelai2(null)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                        {/******************* */}
                                                        {(relai2 === true && !offreRelai2) && (
                                                            <button className="btn btn-outline-primary col-12"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    closeModal(modalRef1)
                                                                    openModal(modalRef3)
                                                                }}>{t('selectRelai')}</button>
                                                        )}
                                                        {error2 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une option
                                                        </div>}
                                                        {error22 && <div className="alert alert-danger">
                                                            {t('selectRelaisError')}
                                                        </div>}
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={() => {
                                        closeModal(modalRef1)
                                    }}>{t("cancel")}</button>
                                    <button type="button" className="btn btn-primary" onClick={() => validateOffre()}>{t('validate')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" ref={modalRef2} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            closeModal(modalRef2)
                                            openModal(modalRef1)
                                        }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection location={trajet.transporteur ? trajet?.from_address?.location : null} setRelai={handleRelaiSelection1} currentModal={modalRef2} nextModalRef={modalRef1} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary"
                                        onClick={() => {
                                            closeModal(modalRef2)
                                            openModal(modalRef1)
                                        }}>{t("cancel")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" ref={modalRef3} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                                    <button type="button" className="btn-close" onClick={() => {
                                        closeModal(modalRef3)
                                        openModal(modalRef1)
                                    }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection location={trajet.transporteur ? trajet?.to_address?.location : null} setRelai={handleRelaiSelection2} nextModalRef={modalRef1} currentModal={modalRef3} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => { closeModal(modalRef3) }}>{t("cancel")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

        </div>
    )
}
export default AjouterTrajetForm