import React, { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap/dist/js/bootstrap.min";

import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import { useLocation } from "../../../locationContext";
import style from "../style.module.css"
import { useTranslation } from "react-i18next";
import CameraComponent from "../../../components/camera/camera";

function MesLivraisons({ openChat }) {
    const { t } = useTranslation()
    const [verificationFile, setVerificationFile] = useState(null)
    const { isSharing, setIsSharing, stopSharingLocation } = useLocation();
    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);
    const columns = t('livraisonCols', { returnObjects: true })
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()
    const modalRef = useRef(null)
    const modalRef1 = useRef(null)
    const [code, setCode] = useState("")
    const [offre, setOffre] = useState(null)
    const [isMobile, setIsMobile] = useState(false);

    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/trajets/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData1 = async (page) => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/trajets/livre?page=${page}`).then(async (trajetResponse) => {
                const trajetData = trajetResponse.data;
                console.log("fetched trajets", trajetData)
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        const offreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/offres/livre?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        TrajetItem.offre = offreResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null; // Returning null to indicate failure
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log("trajets ", filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            }).catch((error) => {
                console.error("Error fetching data:", error);
                setTrajets([]);
            });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async (page) => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/livre?page=${page}`).then(async (trajetOffreResponse) => {
                const trajetOffreData = trajetOffreResponse.data;
                const promises = trajetOffreData.map(async (TrajetOffreItem) => {
                    try {
                        const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${TrajetOffreItem.ttrajet}`);
                        const trajet = trajetResponse.data;
                        trajet.colis = [];
                        for (let coli of TrajetOffreItem.colis) {
                            await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                                .then((res) => {
                                    trajet.colis.push(res.data)
                                })
                        }
                        trajet.offre = TrajetOffreItem;
                        return trajet;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null; // Returning null to indicate failure
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTtrajets(filteredTrajetsData);
            }).catch((error) => {
                console.error("Error fetching data:", error);
                setTtrajets([]);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const openModal = (modalRef) => {
        const modalElement = modalRef.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };

    const closeModal = () => {
        const modalElement = modalRef.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    useEffect(() => {
        fetchCount1();
        fetchCount2();
        fetchData1(page1);
        fetchData2(page2);
    }, []);
    useEffect(() => {
        if (!verifyStoping()) {
            setIsSharing(true)
        }
    }, [trajets, ttrajets]);


    const Supprimer1 = (index) => {
        try {
            const id = trajets[index].offre._id;
            axios.delete(`${process.env.REACT_APP_SERVER}/offres/${id}`);
            fetchData1(page1);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const Supprimer2 = (index) => {//cbn
        try {
            const id = ttrajets[index].offre._id;
            axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/tansporteur/${id}`);
            fetchData2(page2);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const startDeliver1 = async (index) => {
        try {
            const id = trajets[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/offres/deliver/${id}`)
                .then((response) => {
                    fetchData1(page1)
                })
            if (!isSharing)
                setIsSharing(true)
        }
        catch (error) {
            console.error("Error updating data:", error);
        }
    }
    const startDeliver2 = async (index) => {
        try {
            const id = ttrajets[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/deliver/${id}`)
                .then((response) => {
                    fetchData2(page2)
                })
            if (!isSharing)
                setIsSharing(true)
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }
    const verifyStoping = () => {
        let v = true;

        for (let i = 0; i < trajets.length; i++) {
            if (trajets[i].etat === "en cours") {
                v = false;
                return v;
            }
        }
        if (!v)
            return v
        for (let i = 0; i < ttrajets.length; i++) {
            if (ttrajets[i].offre.etat === "en cours") {
                v = false;
                return v;
            }
        }
        return v;
    };
    const endDeliver = async (index) => {
        try {
            if (offre && code && verificationFile) {
                let formData = new FormData();
                formData.append('offre', offre);
                formData.append('code', code);
                formData.append('file', verificationFile);

                await axios.put(`${process.env.REACT_APP_SERVER}/receiveCode/verify`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then((response) => {
                    closeModal()
                    fetchData1(page1)
                    fetchData2(page2)
                })
                    .catch((error) => {
                        console.error("Error updating data:", error);
                    })
                if (verifyStoping())
                    stopSharingLocation()
            }
        }
        catch (error) {
            console.error("Error updating data:", error);
        }
    }
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            setIsMobile(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);
    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link w-50 active" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">{t('packages')}</h5> </button>
                    <button className="nav-link w-50" id="nav-trajet-tab" data-bs-toggle="tab" data-bs-target="#nav-trajet" type="button" role="tab" aria-controls="nav-trajet" aria-selected="false"><h5 className="text-start fw-lighter m-0">{t('myTrips')}</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('myPackagesDelivers')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {trajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  >
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "accepté" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => Supprimer1(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => Supprimer1(index)}>{t("cancel")}</button>*/
                                                                        }
                                                                    </>
                                                                ) : row.offre.etat === "payé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success rounded-circle w-auto" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => startDeliver1(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M14.22 21.6303C13.04 21.6303 11.37 20.8003 10.05 16.8303L9.33 14.6703L7.17 13.9503C3.21 12.6303 2.38 10.9603 2.38 9.78031C2.38 8.61031 3.21 6.93032 7.17 5.60032L15.66 2.77032C17.78 2.06032 19.55 2.27032 20.64 3.35032C21.73 4.43032 21.94 6.21032 21.23 8.33032L18.4 16.8203C17.07 20.8003 15.4 21.6303 14.22 21.6303ZM7.64 7.03032C4.86 7.96032 3.87 9.06032 3.87 9.78031C3.87 10.5003 4.86 11.6003 7.64 12.5203L10.16 13.3603C10.38 13.4303 10.56 13.6103 10.63 13.8303L11.47 16.3503C12.39 19.1303 13.5 20.1203 14.22 20.1203C14.94 20.1203 16.04 19.1303 16.97 16.3503L19.8 7.86032C20.31 6.32032 20.22 5.06032 19.57 4.41032C18.92 3.76032 17.66 3.68032 16.13 4.19032L7.64 7.03032Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M10.11 14.4C9.91999 14.4 9.72999 14.33 9.57999 14.18C9.28999 13.89 9.28999 13.41 9.57999 13.12L13.16 9.53C13.45 9.24 13.93 9.24 14.22 9.53C14.51 9.82 14.51 10.3 14.22 10.59L10.64 14.18C10.5 14.33 10.3 14.4 10.11 14.4Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>

                                                                        </div>
                                                                        {/*     <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(row.offre._id, "TrajetOffre")}>Discuter</button>

                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => startDeliver1(index)}>Démarer</button>
                                                                  */ }
                                                                    </>
                                                                ) : row.offre.etat === "en cours" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success rounded-circle w-auto" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-danger rounded-circle w-auto " onClick={() => {
                                                                                if (isMobile) {
                                                                                    openModal(modalRef)
                                                                                    setOffre(row.offre._id)
                                                                                }
                                                                                else {
                                                                                    openModal(modalRef1)
                                                                                }
                                                                            }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M11.97 22.75C6.04997 22.75 1.21997 17.93 1.21997 12C1.21997 6.07 6.04997 1.25 11.97 1.25C17.89 1.25 22.72 6.07 22.72 12C22.72 17.93 17.9 22.75 11.97 22.75ZM11.97 2.75C6.86997 2.75 2.71997 6.9 2.71997 12C2.71997 17.1 6.86997 21.25 11.97 21.25C17.07 21.25 21.22 17.1 21.22 12C21.22 6.9 17.07 2.75 11.97 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M13.27 16.98H10.73C8.20002 16.98 7.02002 15.8 7.02002 13.27V10.73C7.02002 8.20002 8.20002 7.02002 10.73 7.02002H13.27C15.8 7.02002 16.98 8.20002 16.98 10.73V13.27C16.98 15.8 15.8 16.98 13.27 16.98ZM10.73 8.52002C9.04002 8.52002 8.52002 9.04002 8.52002 10.73V13.27C8.52002 14.96 9.04002 15.48 10.73 15.48H13.27C14.96 15.48 15.48 14.96 15.48 13.27V10.73C15.48 9.04002 14.96 8.52002 13.27 8.52002H10.73Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>

                                                                        </div>
                                                                        {/*  <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(index, "TrajetOffre")}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => {
                                                                            openModal()
                                                                            setOffre(row.offre._id)
                                                                        }}
                                                                        >Terminer</button>*/}
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-outline-secondary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>

                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>
                </div >
                <div className="tab-pane fade" id="nav-trajet" role="tabpanel" aria-labelledby="nav-trajet-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('myTripsDelivers')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {ttrajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "accepté" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => Supprimer2(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*                                                                        
                                                                        <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => Supprimer2(index)}>{t("cancel")}</button>

                                                                        */}
                                                                    </>
                                                                ) : row.offre.etat === "payé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success rounded-circle w-auto" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TtrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => startDeliver2(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M14.22 21.6303C13.04 21.6303 11.37 20.8003 10.05 16.8303L9.33 14.6703L7.17 13.9503C3.21 12.6303 2.38 10.9603 2.38 9.78031C2.38 8.61031 3.21 6.93032 7.17 5.60032L15.66 2.77032C17.78 2.06032 19.55 2.27032 20.64 3.35032C21.73 4.43032 21.94 6.21032 21.23 8.33032L18.4 16.8203C17.07 20.8003 15.4 21.6303 14.22 21.6303ZM7.64 7.03032C4.86 7.96032 3.87 9.06032 3.87 9.78031C3.87 10.5003 4.86 11.6003 7.64 12.5203L10.16 13.3603C10.38 13.4303 10.56 13.6103 10.63 13.8303L11.47 16.3503C12.39 19.1303 13.5 20.1203 14.22 20.1203C14.94 20.1203 16.04 19.1303 16.97 16.3503L19.8 7.86032C20.31 6.32032 20.22 5.06032 19.57 4.41032C18.92 3.76032 17.66 3.68032 16.13 4.19032L7.64 7.03032Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M10.11 14.4C9.91999 14.4 9.72999 14.33 9.57999 14.18C9.28999 13.89 9.28999 13.41 9.57999 13.12L13.16 9.53C13.45 9.24 13.93 9.24 14.22 9.53C14.51 9.82 14.51 10.3 14.22 10.59L10.64 14.18C10.5 14.33 10.3 14.4 10.11 14.4Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>

                                                                        </div>{/*
                                                                        <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(row.offre._id, "TtrajetOffre")}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => startDeliver2(index)}>Démarer</button>*/}
                                                                    </>
                                                                ) : row.offre.etat === "en cours" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success rounded-circle w-auto" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TtrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-danger rounded-circle w-auto " onClick={() => {
                                                                                if (isMobile) {
                                                                                    openModal(modalRef)
                                                                                    setOffre(row.offre._id)
                                                                                }
                                                                                else {
                                                                                    openModal(modalRef1)
                                                                                }
                                                                            }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M11.97 22.75C6.04997 22.75 1.21997 17.93 1.21997 12C1.21997 6.07 6.04997 1.25 11.97 1.25C17.89 1.25 22.72 6.07 22.72 12C22.72 17.93 17.9 22.75 11.97 22.75ZM11.97 2.75C6.86997 2.75 2.71997 6.9 2.71997 12C2.71997 17.1 6.86997 21.25 11.97 21.25C17.07 21.25 21.22 17.1 21.22 12C21.22 6.9 17.07 2.75 11.97 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M13.27 16.98H10.73C8.20002 16.98 7.02002 15.8 7.02002 13.27V10.73C7.02002 8.20002 8.20002 7.02002 10.73 7.02002H13.27C15.8 7.02002 16.98 8.20002 16.98 10.73V13.27C16.98 15.8 15.8 16.98 13.27 16.98ZM10.73 8.52002C9.04002 8.52002 8.52002 9.04002 8.52002 10.73V13.27C8.52002 14.96 9.04002 15.48 10.73 15.48H13.27C14.96 15.48 15.48 14.96 15.48 13.27V10.73C15.48 9.04002 14.96 8.52002 13.27 8.52002H10.73Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>

                                                                        </div>
                                                                        {/*<button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(row.offre._id, "TtrajetOffre")}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => {
                                                                            openModal()
                                                                            setOffre(row.offre._id)
                                                                        }}>Terminer</button>*/}
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-outline-secondary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.acceptedOffre === "offre" ? row.offre?.offre?.prix : row.offre?.counterOffre?.prix
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />

                    </Card>
                </div>
            </div >
            <div ref={modalRef} className="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('endDeliver')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t('endDeliverText')}
                            <div className="row">
                                <div className="col-12 ">
                                    <CameraComponent handleChange={setVerificationFile} />
                                </div>
                                <div className="col-12 ">
                                    <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder={t('confirmCodeInputPlaceholder')}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setCode(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" data-bs-dismiss="modal">
                                {t("cancel")}
                            </button>
                            <button type="button" className="btn btn-primary w-auto" onClick={endDeliver}>{t('endDeliverB')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={modalRef1} className="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">

                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('endDeliver')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert">
                                {t('useMobile')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" data-bs-dismiss="modal">
                                {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Row >
    )
}

export default MesLivraisons;
