import React, { useRef, useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styles from './connection.module.css'; // Import the CSS module
import { Modal } from "bootstrap/dist/js/bootstrap.min";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../notificationContext";
import { useToast } from "../../toastContext";

const Connection = ({ SetIsAuthenticated }) => {
    const { t } = useTranslation()
    console.log("rendering Connection")
    const modalRef = useRef(null);
    const modalRef2 = useRef(null);
    const location = useLocation()
    console.log(location)
    const navigate = useNavigate()
    const signIn = useSignIn()
    const path = location.state?.from || ""
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errors, setErrors] = useState({ email: false, password: false });
    const [emailExist, setEmailExist] = useState(true)
    const [emailError2, setEmailError2] = useState(false);
    const { fetchNotifications } = useNotifications()
    const [formData, setFormData] = useState({ email: '', password: '' })
    const [email, setEmail] = useState('')
    const { addToast } = useToast()
    const resetPassword = async () => {
        setEmailExist(true)
        setEmailError2(false)
        if (email === "") {
            setEmailExist(false)
            return
        }
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resetPassword`, { email }).then((res) => {
            closeModal(modalRef2);
            addToast("E-mail envoyé avec succès", "Un e-mail de réinitialisation a été envoyé à votre adresse e-mail", 'success')
        }).catch((err) => {
            setEmailError2(true)
            addToast("Erreur", "Aucun compte trouvé avec cette adresse e-mail. Veuillez vérifier votre e-mail", 'danger')
            console.log(err)
        })
    }
    const onSubmit = async (e) => {
        try {
            e.preventDefault()
            setEmailError(false);
            setPasswordError(false);
            const emailValid = formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            const newErrors = {
                email: !emailValid,
                password: !formData.password,
            };
            setErrors(newErrors);

            if (!newErrors.email && !newErrors.password) {
                await axios.post(`${process.env.REACT_APP_SERVER}/users/login`, formData)
                    .then((res) => {
                        if (res.status === 200) {
                            let obj = {}
                            if (signIn({
                                auth: {
                                    token: res.data.token,
                                    type: 'Bearer'
                                },
                                userState: { userRole: res.data.userRole },
                                refresh: res.data.refreshToken
                            })) { // Only if you are using refreshToken feature
                                obj.auth = true
                                obj.role = res.data.userRole
                                console.log(res.data)
                                SetIsAuthenticated(obj)
                                fetchNotifications()
                                console.log(res.data.userRole)
                                if (path && path !== "")
                                    navigate(path, { state: location.state })
                                else if (res.data.userRole === "admin" || res.data.userRole === "superadmin")
                                    navigate("/Dashboard")

                                else navigate("/")
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.error === 1) {
                            setEmailError(true);
                        } else if (error.response.data.error === 2) {
                            openModal(modalRef)
                        } else if (error.response.data.error === 3) {
                            setPasswordError(true);
                        }
                    })
            }
        }
        catch (error) {
            console.error(error)
        }
    }
    const openModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = new Modal(modalElement);
            modalInstance.show();
        }
    };

    const closeModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };
    const resend = async () => {
        console.log('resend')
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resendCode`, formData)
    }
    const [visible, setVisible] = useState(false)
    return (
        <div className="bg-gradient-primary">
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">{t('login')}</h4>
                                            </div>
                                            <form className="user form needs-validation" onSubmit={onSubmit} noValidate>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${errors.email || emailError ? 'is-invalid' : ''}`}
                                                        type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder={t('emailInputPlaceholder')}
                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                        required name="email" />
                                                    {!emailError && <div className={`invalid-feedback`}>
                                                        {formData.email ? t('emailError') : "Veuillez saisir l'adresse e-mail"}
                                                    </div>}
                                                    {emailError && <div className={`invalid-feedback`}>
                                                        {t('emailIncorrect')}
                                                    </div>}
                                                </div>
                                                <div className="mb-3">
                                                    <div className="input-group">
                                                        <div className="input-group-text">
                                                            {!visible && <svg onClick={(e) => {
                                                                e.preventDefault()
                                                                setVisible(true)
                                                            }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="black" fill-opacity="0.88" />
                                                            </svg>}
                                                            {visible && <svg onClick={(e) => {
                                                                e.preventDefault()
                                                                setVisible(false)
                                                            }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.47001 15.2799C9.28001 15.2799 9.09001 15.2099 8.94001 15.0599C8.12001 14.2399 7.67001 13.1499 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C13.15 7.66992 14.24 8.11992 15.06 8.93992C15.2 9.07992 15.28 9.26992 15.28 9.46992C15.28 9.66992 15.2 9.85992 15.06 9.99992L10 15.0599C9.85001 15.2099 9.66001 15.2799 9.47001 15.2799ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 12.4999 9.30001 12.9799 9.54001 13.3999L13.4 9.53992C12.98 9.29992 12.5 9.16992 12 9.16992Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M5.6 18.51C5.43 18.51 5.25 18.45 5.11 18.33C4.04 17.42 3.08 16.3 2.26 15C1.2 13.35 1.2 10.66 2.26 8.99998C4.7 5.17998 8.25 2.97998 12 2.97998C14.2 2.97998 16.37 3.73998 18.27 5.16998C18.6 5.41998 18.67 5.88998 18.42 6.21998C18.17 6.54998 17.7 6.61998 17.37 6.36998C15.73 5.12998 13.87 4.47998 12 4.47998C8.77 4.47998 5.68 6.41998 3.52 9.80998C2.77 10.98 2.77 13.02 3.52 14.19C4.27 15.36 5.13 16.37 6.08 17.19C6.39 17.46 6.43 17.93 6.16 18.25C6.02 18.42 5.81 18.51 5.6 18.51Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M12 21.02C10.67 21.02 9.37 20.75 8.12 20.22C7.74 20.06 7.56 19.62 7.72 19.24C7.88 18.86 8.32 18.68 8.7 18.84C9.76 19.29 10.87 19.52 11.99 19.52C15.22 19.52 18.31 17.58 20.47 14.19C21.22 13.02 21.22 10.98 20.47 9.81C20.16 9.32 19.82 8.85 19.46 8.41C19.2 8.09 19.25 7.62 19.57 7.35C19.89 7.09 20.36 7.13 20.63 7.46C21.02 7.94 21.4 8.46 21.74 9C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M12.69 16.2699C12.34 16.2699 12.02 16.0199 11.95 15.6599C11.87 15.2499 12.14 14.8599 12.55 14.7899C13.65 14.5899 14.57 13.6699 14.77 12.5699C14.85 12.1599 15.24 11.8999 15.65 11.9699C16.06 12.0499 16.33 12.4399 16.25 12.8499C15.93 14.5799 14.55 15.9499 12.83 16.2699C12.78 16.2599 12.74 16.2699 12.69 16.2699Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M2 22.7502C1.81 22.7502 1.62 22.6802 1.47 22.5302C1.18 22.2402 1.18 21.7602 1.47 21.4702L8.94 14.0002C9.23 13.7102 9.71 13.7102 10 14.0002C10.29 14.2902 10.29 14.7702 10 15.0602L2.53 22.5302C2.38 22.6802 2.19 22.7502 2 22.7502Z" fill="black" fill-opacity="0.88" />
                                                                <path d="M14.53 10.2199C14.34 10.2199 14.15 10.1499 14 9.99994C13.71 9.70994 13.71 9.22994 14 8.93994L21.47 1.46994C21.76 1.17994 22.24 1.17994 22.53 1.46994C22.82 1.75994 22.82 2.23994 22.53 2.52994L15.06 9.99994C14.91 10.1499 14.72 10.2199 14.53 10.2199Z" fill="black" fill-opacity="0.88" />
                                                            </svg>}

                                                        </div>
                                                        <input
                                                            className={`form-control form-control-user ${errors.password || passwordError ? 'is-invalid' : ''} rounded-end`}
                                                            type={visible ? "text" : "password"} id="exampleInputPassword" placeholder={t('passwordInputPlaceholder')}
                                                            required onChange={(e) => setFormData({ ...formData, password: e.target.value })} name="password" />

                                                        <div className="invalid-feedback">
                                                            {formData.password ? "Veuillez saisir un mot de passe valide" : "Veuillez saisir un mot de passe"}
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className="btn btn-primary d-block btn-user w-100 mb-2" type="submit">Se connecter</button>
                                                <div className="text-center"><a className={`small ${styles.pointer}`} onClick={() => { openModal(modalRef2) }}>{t('forgetPassword')}</a></div>
                                                <hr />
                                            </form>
                                            <div className="text-center"><p>Vous n'avez pas un compte ?<a className="small" onClick={() => { navigate("/inscription") }}  >{t('createAccount')}</a> </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" ref={modalRef} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> {t('verifyEmail')}</h5>
                            <button type="button" className="btn-close" onClick={() => { closeModal(modalRef) }} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {t('verifyEmailText')}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={resend}> {t('resend')}</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => { closeModal(modalRef) }}> {t('emailReceived')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalId2" ref={modalRef2} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t('resetPassword')}</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef2)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t('resetPasswordText')}
                            <input
                                type="email"
                                className={`form-control ${!emailExist || emailError2 ? "is-invalid" : ""}`}
                                placeholder={t('emailInputPlaceholder')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="invalid-feedback">
                                {emailError2 ? "Aucun compte trouvé avec cette adresse e-mail. Veuillez vérifier votre e-mail" : "Veuillez saisir votre adresse e-mail"}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={() => closeModal(modalRef2)}>{t('cancel')}</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                resetPassword();
                            }}>{t('send')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Connection;