import React, { useState, useEffect, useRef } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { Relaisinput } from "../../../components/map/input";
import PaginationComponent from "../../../components/paginations/pagination";
import { useTranslation } from "react-i18next";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

function Relais() {
    const modalRef = useRef(null)

    const { t } = useTranslation()
    const [relais, setRelais] = useState([]);
    const [addresse, setAddresse] = useState({})
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [relaisId, setRelaisId] = useState()
    const columns = t('relaiCols', { returnObjects: true });
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/relais/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide()
    }
    const fetchData = async (page) => {
        try {
            console.log("fetching data")
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/relais?page=${page}`);
            const trajetData = trajetResponse.data;
            console.log(trajetData)


            const filteredrelaisData = trajetData.filter(item => item !== null);
            console.log(filteredrelaisData)
            setRelais(filteredrelaisData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchCount();
        fetchData();
    }, []);
    const handlePlaceSelection = (place) => {
        setAddresse(place);
        console.log(place)
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(addresse)
        try {
            await axios.post(`${process.env.REACT_APP_SERVER}/relais`, addresse).then((response) => {
                if (response.status === 201) {
                    fetchData()
                    console.log("Relais ajouté avec succès")
                }
            }).catch((error) => {
                console.log("Error: " + error)

            })

        } catch (error) {
            console.log(error);
        }
    }
    const handleDelete = async () => {
        try {
            console.log(`Deleting relais ${relaisId}`);
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/relais/${relaisId}`);
            if (response.status === 200) {
                fetchData()
                closeModal(modalRef)

                console.log("Relais supprimé avec succès")
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    {t('addRelais')}
                </button>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <div className="w-75">
                                    <Relaisinput onChange={place => handlePlaceSelection(place)} placeholder={"Saisir une addresse"} />
                                </div>
                                <button type="submit" className="btn btn-outline-primary w-25 text-nowrap">{t('add')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Card className="Card" >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">{t('relaiList')}</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {relais.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "operations" ? (


                                                        <button className="btn btn-outline-danger btn-sm col-12"
                                                            onClick={() => {
                                                                setRelaisId(row._id)
                                                                openModal(modalRef)
                                                            }}>{t('delete')}</button>


                                                    ) : column[1] === "date_creation" ? (
                                                        new Date(row[column[1]]).toLocaleDateString('fr-FR', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: 'numeric'
                                                        })
                                                    ) : (
                                                        row[column[1]] || t('notFound')
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

                </CardBody>
            </Card>
            <div className="modal fade" ref={modalRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true"            >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('deleteRelais')}
                            </h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef)} aria-label="Close"                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert" >
                                {t('deleteRelaisText')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto" onClick={() => closeModal(modalRef)} >
                                {t("cancel")}
                            </button>
                            <button type="button" className="btn btn-outline-danger w-auto" onClick={handleDelete}>
                                {t('delete')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}

export default Relais;
