import React from 'react';
import { useTranslation } from 'react-i18next';

const SuccessPayment = () => {
    const { t } = useTranslation()


    return (

        <div className="container" >
            <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                <div className="row">
                    <div className="col-6 mx-auto my-4">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#198754" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="#198754" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                    </div>
                </div>

                <div className="card-body text-center">
                    <h4 className="text-success mt-3">{t('transactionSuccess')}</h4>
                    <p className="mt-3">
                        {t('transactionSuccessText')}</p>
                </div>
            </div>
        </div>
    );
};

export default SuccessPayment;