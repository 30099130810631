import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import axios from "axios";
import fetchImage from "../../components/fetchImg";
import { Helmet } from "react-helmet-async";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, MailruIcon, MailruShareButton, PinterestIcon, PinterestShareButton } from "react-share";

function VoirBlog() {
    const { id } = useParams();
    const [blog, setBlog] = useState({ content: "", card: { title: "", description: "", image: "" } });

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data");
                let url = `${process.env.REACT_APP_SERVER}/blogs/${id}`;
                const blogResponse = await axios.get(url);
                const blogData = blogResponse.data;
                console.log(blogData);
                setBlog(blogData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [id]);
    return (
        <>
            <title>{blog.card.title}</title>
            <meta property="og:title" content={blog.card.title} />
            <meta property="og:description" content={blog.card.description} />
            <meta property="og:image" content={fetchImage(blog.card.image)} />

            <div className="row">
                {/* <LinkedinShareButton url={`colispo.com/blog/${blog?._id}`} title={blog?.card.title} summary={blog?.card.description} source={fetchImage(blog?.card.image)}>
                    <LinkedinIcon size={40} round />
                </LinkedinShareButton>
                <FacebookShareButton url={`colispo.com/blog/${blog?._id}`} title={blog?.card.title} summary={blog?.card.description} source={fetchImage(blog?.card.image)} hashtag="#colispo">
                    <FacebookIcon size={40} round />
                </FacebookShareButton>
                <PinterestShareButton url={`colispo.com/blog/${blog?._id}`} title={blog?.card.title} media={fetchImage(blog?.card.description)}>
                    <PinterestIcon size={40} round />
                </PinterestShareButton>*/}
                <div className="col-10 col-md-8 container ql-editor">
                    <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
            </div>
        </>

    );
}
export default VoirBlog;