import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ToastContext = createContext();
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);


    const addToast = (title, message, type = "primary") => {
        const id = uuidv4(); // Generates a universally unique identifier
        setToasts((prevToasts) => [...prevToasts, { id, title, message, type }]);
    };
    useEffect(() => {
        if (toasts.length === 0) return;

        const timers = toasts.map((toast) =>
            setTimeout(() => {
                setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id));
            }, 3000)
        );

        return () => timers.forEach((timer) => clearTimeout(timer));
    }, [toasts]);
    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            {/* Toast Container */}
            <div
                className="toast-container position-fixed top-0 end-0 p-3 d-flex align-items-end flex-column justify-content-end"
                style={{ zIndex: 1070, height: "100vh", overflowY: "auto" }}
            >
                {toasts.map((toast) => (
                    <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true" key={toast.id}>
                        <div className={`toast-header text-bg-${toast.type}`}>
                            <strong className="me-auto">{toast.title}</strong>

                            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                        <div className="toast-body">
                            {toast.message}  </div>
                    </div>
                ))}
            </div>
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);
