import React, { useRef, useState } from "react"
import ColisCard from "../../components/cards/colis/coli"
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Map from "../../components/map/map";
import classes from "./voir-_offre.module.css"
import dashed_line from "../../assets/dashed_line.svg"
import car from "../../assets/car.svg"
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from "react-i18next";

function VoirOffre() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const formRef = useRef(null);

    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    console.log("Voir Offre")
    const location = useLocation();
    console.log(location.state)
    const trajet = location.state;
    const [offre, setOffre] = useState({ trajet: trajet, prix: trajet.prix })
    // Deserialize the string back into an object
    // Now you have access to the trajet parameter
    console.log(trajet.colis);
    const openModalSuccess = () => {
        const modalElement = modalRef1.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    }
    const openModalError = () => {
        const modalElement = modalRef2.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    }

    const handleOffreSubmit = async (e) => {
        e.preventDefault();
        const form = formRef.current;
        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            return;
        }
        try {
            console.log("submitting offre");
            await axios.post(`${process.env.REACT_APP_SERVER}/offres`, offre).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    console.log("Offre envoyée");
                    openModalSuccess();
                }
            }).catch((err) => {
                console.log(err);
                openModalError();

            });
        } catch (err) {
            console.log(err);
            openModalError();
        }
    };
    return (
        <div className="container w-100 py-4" style={{ backgroundColor: "#F8F8F7" }}>
            <div className="row mt-3 justify-content-around" >
                <div className="col-12 col-lg-6 row ">
                    <p style={{ fontSize: "22px", fontWeight: "bold", color: "#1f406d" }}>{t('tripDetails')}</p>
                    <div className="card">
                        <div className="card-body row m-0 px-0 px-md-5">
                            <div className="col-3">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>{t("departure")}</p>
                                <p className="fw-semibold">{trajet.from_address.name},  {trajet.from_address.address_components[trajet.from_address.address_components.length - 1].long_name}</p>
                            </div>
                            <div className="col-6 text-center align-self-center" >
                                <img className="" src={car} alt="..." />

                                <img className={`${classes.img}`} src={dashed_line} alt="..." />
                            </div>
                            <div className="col-3">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>{t("arrival")}</p>
                                <p className="fw-semibold">{trajet.to_address.name}, {trajet.to_address.address_components[trajet.to_address.address_components.length - 1].long_name}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>{t('departureDate')} </p>
                                <p className="ms-3 fw-normal"> {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>{t('arrivalDate')} </p>
                                <p className="ms-3 fw-normal"> {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>{t('postedPrice')} </p>
                                <p className="ms-3 fw-normal"> {trajet.prix} €</p>
                            </div>
                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 d-flex align-items-center ">
                                <form ref={formRef} className="form needs-validation" noValidate onSubmit={handleOffreSubmit}>
                                    <div className="input-group">
                                        <label className="input-group-text">Proposer un prix</label>
                                        <input type="number"
                                            required
                                            min={1}
                                            step={0.1}
                                            value={offre.prix}
                                            onChange={(e) => setOffre({ ...offre, prix: e.target.value })}
                                            className="form-control"
                                            placeholder="" />

                                        <button type="submit" className="btn text-nowrap w-auto rounded-end" style={{ color: "white", backgroundColor: "#FFCA40" }}>Envoyer l'offre</button>
                                        <div className="invalid-feedback">
                                            {offre.prix ? "veuillez saisir un prix valide" : "Veuillez saisir le prix"}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </div>
                    <p className="mt-4" style={{ fontSize: "22px", fontWeight: "bold", color: "#1f406d" }}>{t('packageslist')}</p>

                    <div className="col-12 row m-0">
                        < ColisCard colis={trajet.colis} />
                    </div>
                </div >
                <div className="col-12 col-lg-4">
                    <Map trajet={trajet} style={"directions"} />
                </div>
            </div >
            <div className="modal fade" ref={modalRef1} tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="mdalTitleId" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('ocs')}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => navigate("/")}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-success" role="alert">
                                {t('offreCreatedText')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" ref={modalRef2} tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="mdalTitleId" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('occ')}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => navigate("/")}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert">
                                {t('occText')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default VoirOffre